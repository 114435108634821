import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexCentroCusto from "./IndexCentroCusto";
import FormCentroCusto from "./FormCentroCusto";

export default function Index() {
  return (
    <Switch>
      <Route path="/orcamento/centro-custo" exact={true} component={IndexCentroCusto} />
      <Route path="/orcamento/centro-custo/incluir" exact={true} component={FormCentroCusto} />
      <Route path="/orcamento/centro-custo/editar/:id" exact={true} component={FormCentroCusto} />
    </Switch>
  );
}
