import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, all, takeLatest } from "redux-saga/effects";
import * as routerHelpers from "../../router/RouterHelpers";
import BaseCrud from "../../crud/base.crud";
import { redirectLogout } from "../../../_metronic";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Logouted: "[Logouted] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Action",
  EmpresaLoaded: "[Load Empresa] Action",
  RefreshToken: "[Refresh Token] Action",
};

const initialAuthState = {
  user: undefined,
  empresa: undefined,
  authToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "demo3-auth", whitelist: ["user", "empresa", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;
        return { authToken, user: undefined };
      }

      case actionTypes.RefreshToken: {
        const { authToken } = action.payload;
        return { ...state, authToken };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logouted: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.EmpresaLoaded: {
        const { empresa } = action.payload;

        return { ...state, empresa };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  refreshToken: (authToken) => ({
    type: actionTypes.RefreshToken,
    payload: { authToken },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  logouted: () => ({ type: actionTypes.Logouted }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  fulfillEmpresa: (empresa) => ({
    type: actionTypes.EmpresaLoaded,
    payload: { empresa },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    yield put(actions.logouted());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested({
    payload,
  }) {
    try {
      const userResponse = yield BaseCrud.get("user").catch((e) => {
        const { data, status } = e.response;

        if (status === 403) {
          alert(
            "Seu usuário não possui permissão de acesso para este sistema, por favor, verifique com o administrador."
          );
        } else {
          alert(data.content.map((e) => e.message.join("\r\n")).join("\r\n"));
        }

        put(actions.logout());
        redirectLogout();
      });

      const empresaResponse = yield BaseCrud.get("empresa");

      yield all([
        put(actions.fulfillUser(userResponse.data)),
        put(actions.fulfillEmpresa(empresaResponse.data)),
      ]);
    } catch (e) {
      yield put(actions.fulfillUser(null));
    }
  });
}
