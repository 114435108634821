import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { Row, Col, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PERFIL } from "../../../enums/perfilEnum";

export default function GridFuncionario({ funcionarios }) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem de Funcionários" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive="sm">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>CPF</th>
                      <th>Email</th>
                      <th>Centro de Custo</th>
                      <th>Perfil</th>
                      <th>Situação</th>
                      <th className="text-center">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!!funcionarios &&
                      funcionarios.length > 0 &&
                      funcionarios.map((funcionario) => (
                        <tr key={funcionario.Id}>
                          <td className="align-middle">{funcionario.Nome}</td>
                          <td className="align-middle">{funcionario.Cpf}</td>
                          <td className="align-middle">{funcionario.Email}</td>
                          <td className="align-middle">
                            {(!!funcionario &&
                              funcionario.FuncionarioCentroCusto.length > 0 && (
                                <Badge variant="success">Possui</Badge>
                              )) || <Badge variant="warning">Não possui</Badge>}
                          </td>
                          <td className="align-middle">
                            {(!!funcionario &&
                              !!parseInt(funcionario.UltimoPerfil, 10) &&
                              PERFIL.find((p) => {
                                return (
                                  p.id ===
                                  parseInt(funcionario.UltimoPerfil, 10)
                                );
                              }).valor) ||
                              "--"}
                          </td>
                          <td className="align-middle">
                            {funcionario.Situacao ? (
                              <Badge variant="success">Ativo</Badge>
                            ) : (
                              <Badge variant="danger">Inativo</Badge>
                            )}
                          </td>
                          <td className="align-middle text-center">
                            <Link
                              to={`/orcamento/funcionario/editar/${funcionario.Id}`}
                              className="btn btn-clean btn-md btn-icon"
                              title="Editar"
                            >
                              <i className="la la-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
