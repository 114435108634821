import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class PlanoContaOrcamentarioCrud extends BaseCrud {
  static async getById(endpoint, id, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/${endpoint}/${id}`, {
      spinner,
    });
  }
}

export default PlanoContaOrcamentarioCrud;
