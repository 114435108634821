import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexCompetencia from "./IndexCompetencia";
import FormCompetencia from "./FormCompetencia";
import FormCompetenciaDefault from "./FormCompetenciaDefaut";

export default function Index() {
  return (
    <Switch>
      <Route
        path="/orcamento/competencia"
        exact={true}
        component={IndexCompetencia}
      />
      <Route
        path="/orcamento/competencia/incluir"
        exact={true}
        component={FormCompetencia}
      />
      <Route
        path="/orcamento/competencia/editar/:id"
        exact={true}
        component={FormCompetencia}
      />
      <Route
        path="/orcamento/competencia/selecionar"
        exact={true}
        component={FormCompetenciaDefault}
      />
    </Switch>
  );
}
