import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class ProjetoCrud extends BaseCrud {
  static async getById(endpoint, id, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/${endpoint}/${id}`, {
      spinner,
    });
  }

  static async GetByCentroCusto(centroCustoid, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/Projeto/GetByCentroCusto/${centroCustoid}`, { spinner });
  }
}

export default ProjetoCrud;