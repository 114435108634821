import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";

const DashboardReceitas = ({ unidadeOrcamentaria }) => {
  const receitaPrevista =
    !!unidadeOrcamentaria?.valorReceita && unidadeOrcamentaria.valorReceita;
  const receitaOrcada =
    !!unidadeOrcamentaria?.valorReceitaOrcado &&
    unidadeOrcamentaria.valorReceitaOrcado;
  const saldo = !!receitaPrevista && receitaPrevista - (receitaOrcada || 0);
  const percentual =
    !!receitaPrevista &&
    !!receitaOrcada &&
    (receitaOrcada / receitaPrevista) * 100;
  const nome =
    (unidadeOrcamentaria?.nome &&
      unidadeOrcamentaria.codigo + " - " + unidadeOrcamentaria.nome) ||
    "--";

  return (
    <>
      <Portlet fluidHeight={true}>
        <PortletHeader title={"Previsão Receitas Unidade: " + nome} />
        <PortletBody>
          <div className="kt-widget12">
            <div className="kt-widget12__content">
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">
                    Valor previsto para Unidade
                  </span>
                  <span className="kt-widget12__value kt-font-brand">
                    {!!receitaPrevista
                      ? "R$ " +
                        receitaPrevista.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "--"}
                  </span>
                </div>
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">SALDO</span>
                  <span className="kt-widget12__value kt-font-success">
                    {saldo
                      ? "R$ " +
                        saldo.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "--"}
                  </span>
                </div>
              </div>
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">Total já Orçado</span>
                  <span className="kt-widget12__value kt-font-danger">
                    {receitaOrcada
                      ? "R$ " +
                        receitaOrcada.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "--"}
                  </span>
                </div>
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">% Previsto x Orçado</span>
                  <div className="kt-widget12__progress">
                    <div className="progress kt-progress--sm">
                      <div
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuenow={100}
                        aria-valuemax={100}
                        style={{
                          width: `${percentual ? percentual : 0}%`,
                        }}
                        className="progress-bar bg-success"
                      />
                    </div>
                    <span className="kt-widget12__stat">
                      {percentual
                        ? percentual.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + "%"
                        : "--"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default DashboardReceitas;
