export const ACEITA_VALOR_SIM = true;
export const ACEITA_VALOR_NAO = false;

export const TEXTO_ACEITA_VALOR_SIM = "Sim";
export const TEXTO_ACEITA_VALOR_NAO = "Não";

export const ACEITA_VALORES = [
  { id: ACEITA_VALOR_SIM, valor: TEXTO_ACEITA_VALOR_SIM },
  { id: ACEITA_VALOR_NAO, valor: TEXTO_ACEITA_VALOR_NAO },
];
