import React from "react";
import UserProfile from "../../../app/partials/layout/UserProfile";
import FilterTop from "../../../app/partials/layout/FilterTop";

export default class Topbar extends React.Component {
  render() {
    return (
      <>
        <div className="kt-header__topbar">
          <FilterTop />
          <UserProfile showBadge={true} />
        </div>
      </>
    );
  }
}
