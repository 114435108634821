import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//components
import MyNotice from "../../../partials/layout/MyNotice";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter
} from "../../../partials/content/Portlet";

//services
import JasperCrud from "../../../crud/jasper.crud";

//Helpers
import { UserProfile } from "../../../helpers/helper";

function IndexRelatorios(props) {
  const [relConsolidadores, setRelConsolidadores] = useState([]);
  const [relProTrabalho, setRelProTrabalho] = useState([]);

  const { userProfile, empresa } = useSelector(({ auth }) => {
    return {
      userProfile: !!auth.user && auth.user.ultimoPerfil,
      empresa: !!auth.empresa && auth.empresa.Nome
    };
  });
  const isAdministrator = UserProfile.isAdministrador(userProfile);

  useEffect(() => {
    if (empresa === "SENAC-GO") {
      // Consolidadores
      getRelatorioByFolderName(
        "/GESTAO_ORCAMENTARIA/Reports/Senac/Modelo_A"
      ).then(response => {
        setRelConsolidadores(response.data.resourceLookup);
      });

      // Programa de Trabalho
      getRelatorioByFolderName(
        "/GESTAO_ORCAMENTARIA/Reports/Senac/Programa_de_Trabalho"
      ).then(response => {
        setRelProTrabalho(response.data.resourceLookup);
      });
    } else {
      // Consolidadores
      getRelatorioByFolderName(
        "/GESTAO_ORCAMENTARIA/Reports/Sesc/Modelo_A"
      ).then(response => {
        setRelConsolidadores(response.data.resourceLookup);
      });

      // Programa de Trabalho
      getRelatorioByFolderName(
        "/GESTAO_ORCAMENTARIA/Reports/Sesc/Programa_de_Trabalho"
      ).then(response => {
        setRelProTrabalho(response.data.resourceLookup);
      });
    }
  }, []);
  const getRelatorioByFolderName = function (folder) {
    return JasperCrud.getByFolderName(folder);
  };

  return (
    <>
      <MyNotice title="Relatórios Gerenciais" />
      <Row>
        {relConsolidadores.length > 0 && (
          <Col md={6}>
            <Portlet fluidHeight={true}>
              <PortletHeader title={"Consolidadores"} />
              <Form>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <Row className="mt-3">
                        {relConsolidadores.map((rel, i) => {
                          if (isAdministrator ||
                            (!isAdministrator && !rel.label.toUpperCase().includes("CONFERÊNCIA") && !rel.label.toUpperCase().includes("QUADRO SÍNTESE") && !rel.label.toUpperCase().includes("TETOS") && !rel.label.toUpperCase().includes("QUADRO INDICADORES") && !rel.label.toUpperCase().includes("CONTÁBIL ORÇAMENTÁRIO")) 
                          ) {
                            let isReceita = rel.label
                              .toUpperCase()
                              .includes("RECEITAS");
                            return (
                              <Col md={6} key={i}>
                                <Card>
                                  <Card.Header
                                    as="h5"
                                    style={{
                                      backgroundColor: isReceita
                                        ? "#effceb"
                                        : "#fcebeb"
                                    }}
                                  >
                                    <i
                                      className={
                                        isReceita
                                          ? "la la-plus-circle"
                                          : "la la-minus-circle"
                                      }
                                      style={{
                                        fontSize: 14,
                                        padding: 6
                                      }}
                                    />
                                    {rel.label}
                                  </Card.Header>
                                  <Card.Body>
                                    <Card.Text>
                                      Emitir Relatório {rel.label}
                                    </Card.Text>
                                    <Link
                                      to={`/relatorios/visualizar/${rel.label}?uri=${rel.uri}`}
                                      className="btn btn-primary"
                                    >
                                      Ver Relatório
                                    </Link>
                                  </Card.Body>
                                </Card>
                              </Col>
                            );
                          }
                        })
                        }
                      </Row>
                    </div>
                  </div>
                </PortletBody>
              </Form>
            </Portlet>
          </Col>
        )}

        {relProTrabalho.length > 0 && (
          <Col md={6}>
            <Portlet fluidHeight={true}>
              <PortletHeader title={"Programa de Trabalho"} />
              <Form>
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <Row className="mt-3">
                        {relProTrabalho.map((rel, i) => (
                          <Col md={6} key={i}>
                            <Card style={{ marginBottom: 15 }}>
                              <Card.Header as="h5">{rel.label}</Card.Header>
                              <Card.Body>
                                <Card.Text>
                                  Emitir Relatório {rel.label}
                                </Card.Text>
                                <Link
                                  to={`/relatorios/visualizar/${rel.label}?uri=${rel.uri}`}
                                  className="btn btn-primary"
                                >
                                  Ver Relatório
                                </Link>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </PortletBody>
              </Form>
            </Portlet>
          </Col>
        )}
      </Row>
    </>
  );
}

export default IndexRelatorios;
