import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

//enums
import { TIPOS_COMPETENCIA } from "../../../enums/competenciaEnum";
import { SITUACOES_COMPETENCIA } from "../../../enums/competenciaEnum";

export default function FilterCompetencia({ handleFilter }) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader
            title="Filtros"
            toolbar={
              <PortletHeaderToolbar>
                <Link
                  to="/orcamento/competencia/incluir"
                  variant="primary"
                  className="btn btn-primary"
                >
                  Novo
                </Link>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <Formik
              enableReinitialize={true}
              onSubmit={handleFilter}
              initialValues={{ Ano: "" }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <div className="kt-widget12">
                  <div className="kt-widget12__content">
                    <Form>
                      <Row>
                        <Col md={3}>
                          <Form.Label>Competência</Form.Label>
                          <Form.Control
                            name="Ano"
                            type="text"
                            values={values.Ano}
                            placeholder="Pesquise pela competência"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Label>Tipo</Form.Label>
                          <Form.Control
                            as="select"
                            name="Tipo"
                            onChange={handleChange}
                            value={values.Tipo}
                          >
                            <option key={0} value="">
                              Selecione
                            </option>
                            {TIPOS_COMPETENCIA.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.valor}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col md={3}>
                          <Form.Label>Situação</Form.Label>
                          <Form.Control
                            as="select"
                            name="Situacao"
                            type="text"
                            values={values.Situacao}
                            placeholder="Situação"
                            onChange={handleChange}
                          >
                            <option key={0} value="">
                              Selecione
                            </option>
                            {SITUACOES_COMPETENCIA.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.valor}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="pt-5">
                          <div>
                            <Button
                              onClick={(ev) => {
                                resetForm();
                              }}
                              type="reset"
                              variant="secondary"
                            >
                              Limpar
                            </Button>
                            <Button
                              type="submit"
                              variant="primary"
                              className="ml-2"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleSubmit(values);
                              }}
                            >
                              Pesquisar
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
