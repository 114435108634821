import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";

import { Row, Col, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function GridCentroCusto({ centrocustos }) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem Centro de Custo (Modelo A)" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Competência</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Unid. Orçamentária</th>
                      <th>Situação</th>
                      <th style={{ width: 10 }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!!centrocustos &&
                      centrocustos.length > 0 &&
                      centrocustos.map((centroCusto) => (
                        <tr key={centroCusto.Id}>
                          <td>{centroCusto.Competencia.Ano}</td>
                          <td>{centroCusto.Codigo}</td>
                          <td>{centroCusto.Nome}</td>
                          <td>{centroCusto.UnidadeOrcamentaria.Nome}</td>
                          <td>
                            {centroCusto.Situacao ? (
                              <Badge variant="success">Ativo</Badge>
                            ) : (
                              <Badge variant="danger">Inativo</Badge>
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/orcamento/centro-custo/editar/${centroCusto.Id}`}
                              className="btn btn-clean btn-md btn-icon"
                              title="Editar"
                            >
                              <i className="la la-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
