import React, { useState, useEffect, useRef } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import MaskedInput from "react-text-mask";
import MyNotice from "../../../partials/layout/MyNotice";
import { Link } from "react-router-dom";

export default function FilterFuncionario({ handleFilter }) {
  return (
    <>
      <MyNotice title="Cadastro de Funcionários" />
      <Row>
        <Col md={12}>
          <Portlet fluidHeight={true}>
            <Formik
              enableReinitialize={true}
              onSubmit={handleFilter}
              initialValues={{
                Nome: "",
                Cpf: "",
                Email: "",
              }}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                resetForm,
              }) => (
                <>
                  <PortletHeader
                    title="Filtros"
                    toolbar={
                      <PortletHeaderToolbar>
                        <Link
                          to="/orcamento/funcionario/novo"
                          variant="primary"
                          className="btn btn-primary"
                        >
                          Novo
                        </Link>
                      </PortletHeaderToolbar>
                    }
                  />
                  <Form>
                    <PortletBody>
                      <div className="kt-widget12">
                        <div className="kt-widget12__content">
                          <Row>
                            <Col md={4}>
                              <Form.Label>Nome</Form.Label>
                              <Form.Control
                                name="Nome"
                                type="text"
                                values={values.Nome}
                                placeholder="Pesquise pelo nome"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <Form.Label>CPF</Form.Label>
                              <MaskedInput
                                name="Cpf"
                                type="text"
                                values={values.Cpf}
                                className="form-control"
                                mask={[
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ".",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ".",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                ]}
                                placeholder="Pesquise pelo Cpf"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                name="Email"
                                type="text"
                                values={values.Email}
                                placeholder="Pesquise pelo Email"
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </PortletBody>
                    <PortletFooter>
                      <Button
                        onClick={() => {
                          resetForm();
                          handleSubmit();
                        }}
                        type="reset"
                        variant="secondary"
                      >
                        Limpar
                      </Button>
                      <Button
                        type="button"
                        variant="primary"
                        className="ml-2"
                        onClick={handleSubmit}
                        style={{ marginLeft: 5 }}
                      >
                        Pesquisar
                      </Button>
                    </PortletFooter>
                  </Form>
                </>
              )}
            </Formik>
          </Portlet>
        </Col>
      </Row>
    </>
  );
}
