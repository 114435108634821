import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { menuUserProfileEnumInterface } from "../../utils/utils";

export default class MenuList extends React.Component {
  render() {
    const { currentUrl, menuConfig, layoutConfig, userProfile } = this.props;

    return menuConfig.aside.items.map((child, index) => {
      return (
        <React.Fragment key={`menuList${index}`}>
          {(!child.allowedProfiles ||
            menuUserProfileEnumInterface.isAllowed(
              userProfile,
              child.allowedProfiles
            )) &&
            child.section && <MenuSection item={child} />}

          {(!child.allowedProfiles ||
            menuUserProfileEnumInterface.isAllowed(
              userProfile,
              child.allowedProfiles
            )) &&
            child.separator && <MenuItemSeparator item={child} />}

          {(!child.allowedProfiles ||
            menuUserProfileEnumInterface.isAllowed(
              userProfile,
              child.allowedProfiles
            )) &&
            child.title && (
              <MenuItem
                item={child}
                currentUrl={currentUrl}
                layoutConfig={layoutConfig}
                userProfile={userProfile}
              />
            )}
        </React.Fragment>
      );
    });
  }
}
