import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";

import { Row, Col, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getDescriptionTipoProjeto } from "../../../enums/projetoEnum";

export default function GridProjeto({ projetos }) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem de Projetos" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive="sm">
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Situação</th>
                      <th className="text-center">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!!projetos &&
                      projetos.length > 0 &&
                      projetos.map((projeto) => (
                        <tr key={projeto.Id}>
                          <td>{projeto.Nome}</td>
                          <td>{getDescriptionTipoProjeto(projeto.Tipo)}</td>
                          <td>
                            {projeto.Situacao ? (
                              <Badge variant="success">Ativo</Badge>
                            ) : (
                              <Badge variant="danger">Inativo</Badge>
                            )}
                          </td>
                          <td className="align-middle text-center">
                            <Link
                              to={`/orcamento/projeto/editar/${projeto.Id}`}
                              className="btn btn-clean btn-md btn-icon"
                              title="Editar"
                            >
                              <i className="la la-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
