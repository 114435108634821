import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TIPOS_PROJETO } from "../../../enums/projetoEnum";
import { SITUACOES } from "../../../enums/genericEnum";
import MyNotice from "../../../partials/layout/MyNotice";
import { Formik } from "formik";

export default function FilterProjeto({ handleFilter }) {
  return (
    <>
      <MyNotice title="Cadastro de Projeto" />
      <Row>
        <Col md={12}>
          <Row>
            <Portlet fluidHeight={true}>
              <PortletHeader
                title="Filtros"
                toolbar={
                  <PortletHeaderToolbar>
                    <Link
                      to="/orcamento/projeto/incluir"
                      variant="primary"
                      className="btn btn-primary"
                    >
                      Novo
                    </Link>
                  </PortletHeaderToolbar>
                }
              />
              <PortletBody>
                <div className="kt-widget12">
                  <div className="kt-widget12__content">
                    <Formik
                      enableReinitialize={true}
                      onSubmit={handleFilter}
                      initialValues={{ Nome: "" }}
                    >
                      {({
                        errors,
                        values,
                        touched,
                        handleChange,
                        handleSubmit,
                        resetForm,
                      }) => (
                        <Form>
                          <Row>
                            <Col md={4}>
                              <Form.Label>Nome</Form.Label>
                              <Form.Control
                                name="Nome"
                                type="text"
                                values={values.Nome}
                                placeholder="Pesquise pelo nome do Projeto"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4}>
                              <Form.Label>Tipo</Form.Label>
                              <Form.Control
                                as="select"
                                name="Tipo"
                                type="text"
                                values={values.Tipo}
                                placeholder="Tipo"
                                onChange={handleChange}
                              >
                                <option key={0} value="">
                                  Selecione
                                </option>
                                {TIPOS_PROJETO.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col md={4}>
                              <Form.Label>Situação</Form.Label>
                              <Form.Control
                                as="select"
                                name="Situacao"
                                type="text"
                                values={values.Situacao}
                                placeholder="Situação"
                                onChange={handleChange}
                              >
                                <option key={0} value="">
                                  Selecione
                                </option>
                                {SITUACOES.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} style={{ paddingTop: 20 }}>
                              <div>
                                <Button
                                  onClick={(ev) => {
                                    resetForm();
                                  }}
                                  type="reset"
                                  variant="secondary"
                                >
                                  Limpar
                                </Button>
                                <Button
                                  type="submit"
                                  variant="primary"
                                  className="ml-2"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    handleSubmit(values);
                                  }}
                                >
                                  Pesquisar
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </PortletBody>
            </Portlet>
          </Row>
        </Col>
      </Row>
    </>
  );
}
