import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class JasperCrud extends BaseCrud {
  static async getByFolderName(folderName, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/report/?reportFolder=${folderName}`, {
      spinner,
    });
  }

  static async getInputControlsByReportUri(reportUri, competencia, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/report/input-control/?reportUri=${reportUri}&competencia=${competencia}`, {
      spinner,
    });
  }

  static async getReport(data, spinner = true) {
    return axios.post(`${env.quadroMedidas.baseApi}/report/`, data, {
      spinner,
    });
  }

}

export default JasperCrud;
