import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button,  Accordion, Nav, Form, Card, Table, Alert, ListGroup, Badge  } from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import { Formik, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

//Enum
import { SITUACOES } from "../../../enums/genericEnum";
import { TIPOS_UNIDADES_ORCAMENTARIA } from "../../../enums/unidadeOrcamentariaEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";


import MyNotice from "../../../partials/layout/MyNotice";
import { Money } from "../../../helpers/helper";
import { CardFormTetoPlanoConta } from "../teto/CardFormTetoPlanoConta";
import {CardFormTetoEstruturaOrcamentaria} from "../teto/CardFormTetoEstruturaOrcamentaria";
import { SITUACAO_COMPETENCIA_CADASTRANDO } from "../../../enums/competenciaEnum";
import { typeOf } from "react-is";
import { toInteger, toNumber } from "lodash";

const schema = Yup.object().shape({
  CompetenciaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a competencia"
    )
    .required("Campo obrigatório"),
  Codigo: Yup.string()
    .matches(/^[0-9.]*$/i, "Código inválido, utilize apenas números e ponto.")
    .required("Campo obrigatório"),
  Nome: Yup.string().required("Campo obrigatório"),
  Sigla: Yup.string().required("Campo obrigatório"),
  Tipo: Yup.number()
    .typeError("Selecione o tipo.")
    .required("Campo obrigatório"),
});


export default function FormTetoUnidadeOrcamentaria(props) {
  
  const [unidadeOrcamentaria, setUnidadeOrcamentaria] = useState({
    CompetenciaId: "",
    Codigo: "",
    Nome: "",
    Sigla: "",
    Tipo: "",
    ValorDespesa: "",
    ValorReceita: "",
    Funpri: "",
    UsarControleGrupoTetoPlanoConta: ""
  });

  const [tetoPlanoConta, setTetoPlanoConta] = useState({});
  const [tetoPrograma, setTetoPrograma] = useState({});

  const [situacaoCompetencia, setSituacaoCompetencia] = useState({});
  

  const [
    titlesEstruturasOrcamentaria,
    setTitlesEstruturasOrcamentaria,
  ] = useState([]);
  

  const [mensagemErro, setMensagemErro] = useState(null);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);

  const [estruturaOrcamentaria, setEstruturaOrcamentaria] = useState(null);

  const [competencias, setCompetencias] = useState([]);

  const [filterCompetencia, setFilterCompetencia] = useState("");


  useEffect(() => {
    handleGetCompetencias();
  }, [filterCompetencia]);

  useEffect(() => {
    if (props.match.params.id) {
      handleGetCompetencias();
      handleGetUnidadeOrcamentaria(props.match.params.id);
    } else {
      setFilterCompetencia("&$filter=Situacao ne '4'");
    }
  }, []);

  const GetTetoContaUnidadeOrcamentaria = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/teto-conta/${id}`
  );

  const GetTetoProgramaUnidadeOrcamentaria = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/teto-estrutura-orcamentaria/${id}`
  );  

  const handleTetoContaUnidadeOrcamentaria = (uoId) => {
    if (!uoId) return;
    GetTetoContaUnidadeOrcamentaria(uoId).then((response) => {
      setTetoPlanoConta(response.data);
    });
  };

  const handleTetoProgramaContaUnidadeOrcamentaria = (uoId) => {
    if (!uoId) return;
    GetTetoProgramaUnidadeOrcamentaria(uoId).then((response) => {
      setTetoPrograma(response.data);
    });
  };

  useEffect(() => {
    handleTetoContaUnidadeOrcamentaria(props.match.params.id);
  }, []);

  useEffect(() => {
    handleTetoProgramaContaUnidadeOrcamentaria(props.match.params.id);
  }, []);


  const handleGetCompetencias = () =>
    getCompetencias().then((r) => {
      setCompetencias(r.data.value);
      unidadeOrcamentaria.CompetenciaId = r.data.value[0].Id;
    });

    

  const handleGetUnidadeOrcamentaria = (id) => {
    getUnidadeOrcamentaria(id)
      .then((uo) => {
        uo.data.ValorDespesa = Money.toPtBr(uo.data.ValorDespesa);
        uo.data.ValorReceita = Money.toPtBr(uo.data.ValorReceita);
        uo.data.Funpri = Money.toPtBr(uo.data.Funpri);
        setUnidadeOrcamentaria(uo.data);
        setcontrolarTetoGrupoPlanoConta(uo.data.UsarControleTetoPlanoConta);
        setcontrolarTetoPrograma(uo.data.UsarControleTetoEstruturaOrcamentaria);
        handleTetoContaUnidadeOrcamentaria(props.match.params.id);
        handleTetoProgramaContaUnidadeOrcamentaria(props.match.params.id);
      })
      .catch((e) => e);
  };


  const handleSubmit = function (v) {
    sanitizeForm(v);

    (!!v.Id ? editar(v) : incluir(v))
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/unidade-orcamentaria");
      })
      .catch((e) => e);
  };



  const [controlarTetoGrupoPlanoConta, setcontrolarTetoGrupoPlanoConta] = useState(false);
  const [controlarTetoPrograma, setcontrolarTetoPrograma] = useState(false);

function situacaoCompetenciaCadastrando(competenciaId) {
  let competenciaAtual = competencias.find(c => c.Id === competenciaId);

  return  competenciaAtual && competenciaAtual.Situacao == SITUACAO_COMPETENCIA_CADASTRANDO;

}

const onSwitchTetoGrupoPlanoConta = () => {

  var definicao = { 
    UnidadeOrcamentariaId: props.match.params.id, 
    UsarControleTetoGrupoPlanoConta: !controlarTetoGrupoPlanoConta  
  };

  setTetoGrupoPlanoConta(definicao).then((r) => {
    if(!controlarTetoGrupoPlanoConta){
      handleTetoContaUnidadeOrcamentaria(props.match.params.id);
      toast.success("Controle por Grupo de Plano de Conta foi ativado com sucesso");
    }else{
      toast.success("Controle por Grupo de Plano de Conta foi desativado com sucesso");
    }
    
  })
  .catch((e) => e);;
  setcontrolarTetoGrupoPlanoConta(!controlarTetoGrupoPlanoConta);
};


const onSwitchTetoPrograma = () => {

  var definicao = { 
    UnidadeOrcamentariaId: props.match.params.id, 
    UsarControleTetoEstruturaOrcamentaria: !controlarTetoPrograma  
  };

  setTetoPorPrograma(definicao).then((r) => {
    if(!controlarTetoPrograma){
      handleTetoProgramaContaUnidadeOrcamentaria(props.match.params.id);
      toast.success("Controle por Programa foi ativado com sucesso");
    }else{
      toast.success("Controle por Programa foi desativado com sucesso");
    }
    
  })
  .catch((e) => e);;

  setcontrolarTetoPrograma(!controlarTetoPrograma);
};


const handleChild = (callback)  => { 
 //callback;
  handleGetUnidadeOrcamentaria(props.match.params.id);
  
}


const  setTetoGrupoPlanoConta = async (v) => BaseCrud.post("UnidadeOrcamentaria/SetTetoGrupoPlanoConta", v);

const  setTetoPorPrograma = async (v) => BaseCrud.post("UnidadeOrcamentaria/SetTetoPrograma", v);


function CalcularTotalTeto(teto){
  var total = 0;
  for (let index = 0; index < teto.length; index++) {
   total += teto[index].valorDespesa;    
  }   
  return toNumber(total).toLocaleString("pt-br", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
 
  const getCompetencias = async () =>
    BaseCrud.get("competencia?$orderBy=Ano desc" + filterCompetencia);

  const getUnidadeOrcamentaria = async (id) =>
    BaseCrud.getById("UnidadeOrcamentaria", id);

  const editar = async (v) => BaseCrud.put("UnidadeOrcamentaria", v.Id, v);

  const incluir = async (v) => BaseCrud.post("UnidadeOrcamentaria", v);

  const sanitizeForm = (v) => {
    v.ValorDespesa = !!v.ValorDespesa ? Money.toDecimal(v.ValorDespesa) : 0;
    v.ValorReceita = !!v.ValorReceita ? Money.toDecimal(v.ValorReceita) : 0;
    v.Funpri = !!v.Funpri ? Money.toDecimal(v.Funpri) : 0;
  };

  function CalcularTotalOrcado(teto){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorLancado;    
    }   
    return total;
  }

  return (
    <>
      <MyNotice title="Ajuste de teto da Unidade Orçamentária" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={unidadeOrcamentaria}
            validationSchema={schema}
            // onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleChange, handleSubmit }) => (
              <Portlet fluidHeight={true}>
                <Form>
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">

                      <Card>
                        <br />
                      <Row>
                        <Col md={12}>
                           <h3 className="text-center">{values.Codigo + ' - ' + values.Nome}</h3>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                          <Col md={4}>
                              {values.ValorDespesa > 0 && (
                                                              <ListGroup as="ol" numbered>
                                                              {                                   
                                                                <ListGroup.Item as="li" variant="secondary">
                                                                  <div className="ms-2 me-auto">                           
                                                                  <b>Teto Geral da Unidade Orçamentária</b>
                                                                  </div>
                                                                  <Badge bg="warning" pill>
                                                                    
                                                                    <h5>{ "R$ " +
                                                                   Money.toBRL(Number(values.ValorDespesa)) }</h5>
                                                                  </Badge>
                                                                </ListGroup.Item>                            
                                                              }
                                                            </ListGroup>
                              )}
                          </Col>
                          <Col md={4}>
                            {values.ValorDespesa > 0 && controlarTetoGrupoPlanoConta && (                          
                                <ListGroup as="ol" numbered>
                                    {                                       
                                      <ListGroup.Item as="li" variant="secondary">
                                        <div className="ms-2 me-auto">                           
                                        <b>Teto Geral Por Grupo de Plano de Conta </b>
                                        </div>
                                        <Badge bg="warning" pill>                                          
                                          <h5>{ "R$ " +
                                                Money.toBRL(CalcularTotalTeto(tetoPlanoConta)) }
                                          </h5>
                                        </Badge>
                                      </ListGroup.Item>
                                
                                    }
                                </ListGroup>)}
                          </Col>
                          <Col md={4}>
                             {values.ValorDespesa > 0 && controlarTetoPrograma && (
                                                           <ListGroup as="ol" numbered>
                                                           { 
                                     
                                                             
                                                             <ListGroup.Item as="li" variant="secondary">
                                                               <div className="ms-2 me-auto">                           
                                                                <b>Teto Geral Por Estrutura Orçamentária (Programa)</b>
                                                               </div>
                                                               <Badge bg="warning" pill>
                                                                 
                                                                  <h5>{ "R$ " +
                                                                            Money.toBRL(CalcularTotalTeto(tetoPrograma))
                                                                             }</h5>
                                                               </Badge>
                                                             </ListGroup.Item>
                                                       
                                                            }
                                                         </ListGroup>
                             )}       

                          </Col>
                          </Row>
                      </Card> 
                      <br />

                      {
                        (controlarTetoGrupoPlanoConta === true && tetoPlanoConta.length > 0 &&  CalcularTotalTeto(tetoPlanoConta) !== toNumber(values.ValorDespesa).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })) || (controlarTetoPrograma === true && tetoPrograma.length > 0 && CalcularTotalTeto(tetoPrograma) !== toNumber(values.ValorDespesa).toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })) ? (                         
                         
                            <Alert variant='warning'>
                                  <b>Atenção!  <span style={{color:"red"}}>os valores dos tetos orçamentários estão divergentes</span></b>. Faça a correção para que os usuários consigam lançar.
                            </Alert>      
                      ):""}

                      {
                        
                        tetoPlanoConta && CalcularTotalOrcado(tetoPlanoConta) > CalcularTotalTeto(tetoPlanoConta) ? 
                        (
                          <Alert variant='warning'>
                                  <b>Atenção!  <span style={{color:"red"}}>Total já lançado é maior que o teto informado!</span></b>. Faça a correção para que os usuários consigam lançar.
                            </Alert>   
                        ) : ""

                      }

<Row>
              <Col md={6}>

              {!!tetoPlanoConta &&
                      tetoPlanoConta.length > 0 && (<>
                <h5 className="text-center">Teto por Grupo de Plano de Conta</h5>
                        <Table variant="info" size="sm">
                                          <thead>
                                            <tr>
                                              <th>Grupo de Plano de Conta</th>
                                              <th>Valor Lançado</th>
                                              <th>Valor Limite</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            
                                            {values.ValorDespesa > 0 && controlarTetoGrupoPlanoConta && tetoPlanoConta.map((teto) => (
                                              <tr>
                                              <td>{teto.planoContaCodigo + ' - ' + teto.planoContaNome}
                                              {teto.valorLancado > teto.valorDespesa ? <i className="flaticon-danger kt-font-danger" /> : ""}</td>
                                              <td><Badge variant= {teto.valorLancado > teto.valorDespesa ? "danger" : "info" } pill>
                                              R$    {Money.toBRL(teto.valorLancado)}
                                                                          </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>
                                              <td><Badge variant="warning" pill>
                                                                          R$ {Money.toBRL(teto.valorDespesa) }
                                                                          </Badge>
                                                                          {teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                                              </tr>    
                                              ))}
                                              <tr>
                                                <td><b>Totais</b></td>
                                                <td colSpan="1">
                                                  <h6><b>R$ {Money.toBRL(CalcularTotalOrcado(tetoPlanoConta))} </b></h6>
                                                </td>
                                                <td colSpan="1">
                                                <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoPlanoConta))}</b></h6>
                                                </td>
                                              </tr>       

                                          </tbody>
                            </Table>
                    </>)}
              </Col>
              <Col md={6}>
             
              {values.ValorDespesa > 0 && controlarTetoPrograma && !!tetoPrograma &&
                      tetoPrograma.length > 0 && (<>
 <h5 className="text-center">Teto por Estrutura Orçamentária (Programa)</h5>
<Table variant="info" size="sm">
                  <thead>
                    <tr>
                      <th>Estrutura Orçamentária (Programa)</th>
                      <th>Valor Lançado</th>
                      <th>Valor Limite</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {tetoPrograma.map((teto) => (
                      <tr>
                      <td>{teto.estruturaOrcamentariaCodigo + ' - ' + teto.estruturaOrcamentariaNome}
                      {teto.valorLancado > teto.valorDespesa ? <i className="flaticon-danger kt-font-danger" /> : "" }
                      </td>
                      <td><Badge variant={teto.valorLancado > teto.valorDespesa ? "danger" : "info"} pill>
                      R$    {Money.toBRL(teto.valorLancado)}
                                                  </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>
                      <td><Badge variant="warning" pill>
                                                  R$ {Money.toBRL(teto.valorDespesa) }
                                                  </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                      </tr>    
                      ))}
                      <tr>
                        <td><b>Totais</b></td>
                        <td colSpan="1">
                          <h6><b>R$ {Money.toBRL(CalcularTotalOrcado(tetoPrograma))} </b></h6>
                        </td>
                        <td colSpan="1">
                        <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoPrograma))}</b></h6>
                        </td>
                      </tr>       

                  </tbody>
                </Table>
                      
                    </>)}
              
              </Col>
          </Row>         



                        <div className="kt-separator kt-separator--dashed"></div>
                        <Form>
                          <Form.Switch
                            onChange={onSwitchTetoGrupoPlanoConta}
                            id="switch-grupo-plano-conta"
                            label="Controle de teto por Grupo de Plano de Conta"
                            checked={controlarTetoGrupoPlanoConta}                           
                            disabled={!situacaoCompetenciaCadastrando(values.CompetenciaId)}
                          />
                          <Form.Switch
                            onChange={onSwitchTetoPrograma}
                            id="switch-programa"
                            label="Controle de teto por Programa"
                            checked={controlarTetoPrograma}
                            disabled={!situacaoCompetenciaCadastrando(values.CompetenciaId)}
                          />
                
                        </Form>
                        <br />

                        {!situacaoCompetenciaCadastrando(values.CompetenciaId) ? (
                                                        <p>
                                                        <b>*</b> Só é possível habilitar/desabilitar com a competência CADASTRANDO.
                                          </p>   
                        ): ""}

                        {controlarTetoGrupoPlanoConta && (
                        <Card border="primary">
                        <Card.Header>
                          Teto por Grupo de Plano de Conta
                        </Card.Header>
                        <Card.Body>

                        {!!tetoPlanoConta && tetoPlanoConta.length > 0 && (

                            <Accordion>
                          {tetoPlanoConta.map((teto, idx) => (                              
                              <Card key={`teto-plano-conta-${idx}`}>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Nav.Link}
                                    variant="link"
                                    eventKey={teto.id}
                                    onClick={() => {}
                   
                                    }
                                  >
                                    <i
                                      className="la la-check-square"
                                      style={{
                                        color: "green",
                                        fontSize: 14,
                                        padding: 6,
                                      }}
                                    />
                                    {teto.planoContaCodigo + ' - ' + teto.planoContaNome}
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={teto.id}>
                                  <Card.Body>
                                  <Card key={values.id}>
                                    <Card.Body>                                        
                                    <CardFormTetoPlanoConta
                                      teto={teto}
                                      permitirEdicao={!situacaoCompetenciaCadastrando(values.CompetenciaId)}
                                      atualizarEstado={handleChild}
                                    />                           
                                    </Card.Body>
                                    </Card>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                            </Accordion>

                        )}
                        </Card.Body>
                      </Card>
                        )}

                        <br />

          {controlarTetoPrograma && (
                        <Card border="primary">
                        <Card.Header>
                          Teto por Estrutura Orçamentária (Programa)
                        </Card.Header>
                        <Card.Body>
                        {!!tetoPrograma && tetoPrograma.length > 0 && (

                            <Accordion>
                          {tetoPrograma.map((tetoProg, idx) => (                              
                              <Card key={`teto-programa-${idx}`}>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Nav.Link}
                                    variant="link"
                                    eventKey={tetoProg.id}
                                    onClick={() => {}
                   
                                    }
                                  >
                                    <i
                                      className="la la-check-square"
                                      style={{
                                        color: "green",
                                        fontSize: 14,
                                        padding: 6,
                                      }}
                                    />
                                    {tetoProg.estruturaOrcamentariaCodigo + ' - ' + tetoProg.estruturaOrcamentariaNome}
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={tetoProg.id}>
                                  <Card.Body>
                                  <Card key={values.id}>
                                    <Card.Body>                                        
                                    <CardFormTetoEstruturaOrcamentaria
                                      teto={tetoProg}
                                      permitirEdicao={!situacaoCompetenciaCadastrando(values.CompetenciaId)}
                                      atualizarEstado={handleChild}
                                    />                              
                                    </Card.Body>
                                    </Card>
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                            </Accordion>
                        )}
                        </Card.Body>
                      </Card>
                        )}

                      </div>
                    </div>
                  </PortletBody>


                  <PortletFooter className="text-right">
                    {/* <Link
                      to="/orcamento/unidade-orcamentaria"
                      variant="secondary"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button> */}
                  </PortletFooter>
                </Form>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
