import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Collapse,ListGroup, Badge, Alert, Table } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import { useSelector } from "react-redux";

import { Formik, ErrorMessage, getIn } from "formik";
import { Link } from "react-router-dom";


import DashboardDespesas from "../../../partials/layout/DashboardDespesas";
import DashboardReceitas from "../../../partials/layout/DashboardReceitas";

import MyNotice from "../../../partials/layout/MyNotice";
import { Money, UserProfile } from "../../../helpers/helper";
import BaseCrud from "../../../crud/base.crud";
import { getValorSituacaoCompetencia } from "../../../enums/competenciaEnum";
import { CardIdentificacaoOrcamentaria } from "./CardIdentificacaoOrcamentaria";
import { FormLancamentos } from "./FormLancamentos";
import { toNumber } from "lodash";

function Cadastro(props) {
  const [competencias, setCompetencias] = useState([]);
  const [competencia, setCompetencia] = useState({});
  const [centroCustoId, setCentroCustoId] = useState("");
  const [unidadeOrcamentaria, setUnidadeOrcamentaria] = useState("");
  const [projetoId, setProjetoId] = useState("");
  const [tetoPlanoConta, setTetoPlanoConta] = useState({});
  const [tetoPrograma, setTetoPrograma] = useState({});
  const [totalOrcadoDespesasCorrentes, setTotalOrcadoDespesasCorrentes] = useState("");
  const [totalOrcadoOutrasDespesas, setTotalOrcadoOutrasDespesas] = useState("");

  const [
    titlesEstruturasOrcamentaria,
    setTitlesEstruturasOrcamentaria,
  ] = useState([]);
  const { userProfile } = useSelector(({ auth }) => ({
    userProfile: !!auth.user && auth.user.ultimoPerfil,
  }));

  const [uo, setUO] = useState("");

  const isAdministrator = UserProfile.isAdministrador(userProfile);

  const [open, setOpen] = useState(false);

  async function getOrcamentoUnidadeOrcamentaria(id) {

    
    setUnidadeOrcamentaria("");
    if (!id) return;

    BaseCrud.get(`unidadeOrcamentaria/${id}`, false).then((u) => {
      setUnidadeOrcamentaria({
        codigo: u.data.Codigo,
        funpri: u.data.Funpri,
        id: u.data.Id,
        nome: u.data.Nome,
        sigla: u.data.Sigla,
        valorDespesa: u.data.ValorDespesa,
        valorDespesaOrcado: u.data.ValorDespesaOrcado,
        valorReceita: u.data.ValorReceita,
        valorReceitaOrcado: u.data.ValorReceitaOrcado
      });

      handleTetoContaUnidadeOrcamentaria(u.data.Id);
      handleTetoPrograma(u.data.Id);
      handleTotalOrcadoDespesasCorrentes(u.data.Id);
      handleTotalOrcadoOutrasDespesas(u.data.Id);
      setUO(u.data);
    });
  }

  const GetTetoContaUnidadeOrcamentaria = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/teto-conta/${id}`
  );

  const handleTetoContaUnidadeOrcamentaria = (uoId) => {
    if (!uoId) return;

    GetTetoContaUnidadeOrcamentaria(uoId).then((response) => {
      setTetoPlanoConta(response.data);
    });
  };

  const GetTetoPrograma = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/teto-estrutura-orcamentaria/${id}`
  );
  const handleTetoPrograma = (uoId) => {
    if (!uoId) return;
    GetTetoPrograma(uoId).then((response) => {
      setTetoPrograma(response.data);
    });
  };


  const GetTotalOrcadoDespesasCorrentes = async (id) =>
  BaseCrud.get(
    `Orcamento/total-lancamentos-despesas-correntes/${id}`
  );
  const handleTotalOrcadoDespesasCorrentes = (uoId) => {
    if (!uoId) return;
    GetTotalOrcadoDespesasCorrentes(uoId).then((response) => {
      console.log('valor desp. correntes')
      console.log(response.data)
      setTotalOrcadoDespesasCorrentes(response.data);
    });
  };

  const GetTotalOrcadoOutrasDespesas = async (id) =>
  BaseCrud.get(
    `Orcamento/total-lancamentos-outras-despesas/${id}`
  );
  const handleTotalOrcadoOutrasDespesas = (uoId) => {
    if (!uoId) return;
    GetTotalOrcadoOutrasDespesas(uoId).then((response) => {
      setTotalOrcadoOutrasDespesas(response.data);
    });
  };


  const getCompetencias = () => {
    let filter = isAdministrator
      ? "Situacao eq '2' or Situacao eq '3'"
      : "Situacao eq '2'";
    BaseCrud.get(
      `Competencia?$count=true&$filter=${filter}&$orderBy=Ano desc`,
      true
    ).then((response) => {
      setCompetencias(response.data.value);

      if (!isAdministrator && response.data.value.length > 0) {
        setCompetencia(response.data.value[0]);
      }
    });
  };

  function CalcularTotalTeto(teto){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorDespesa;    
    }   
    return total;
  }

  function CalcularTotalOrcado(teto){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorLancado;    
    }   
    return total;
  }

  const getEstruturaOrcamentaria = async (centroCustoId, projetoId) =>
    BaseCrud.get(
      `CentroCusto/${centroCustoId}/projeto/${projetoId}/estrutura-orcamentaria-relationship`
    );

  const handleChangeProjeto = (centroCustoId, projetoId) => {
    setTitlesEstruturasOrcamentaria([]);

    if (!projetoId || !centroCustoId) return;

    getEstruturaOrcamentaria(centroCustoId, projetoId).then((response) => {
      const titles = response.data.map((eo) => {
        const makeTitle = (item, glue = "") => {
          const auxTitle = `${item.codigo} - ${item.titulo} ${
            glue ? " / " + glue : ""
          }`;
          return !!item.pai ? makeTitle(item.pai, auxTitle) : auxTitle;
        };
        return { [eo.id]: makeTitle(eo) };
      });
      setTitlesEstruturasOrcamentaria(titles);
    });
  };

  useEffect(() => {
    handleChangeProjeto(centroCustoId, projetoId);
  }, [projetoId]);

  useEffect(() => {
    getCompetencias();
  }, []);

  return (
    <>
      {competencias.length > 0 ? (
        <>
          <MyNotice title="Lançamento das informações de Orçamento" />
          <Row>
            <Col md={6}>
              <DashboardDespesas unidadeOrcamentaria={unidadeOrcamentaria} totalOrcadoDespesasCorrentes={totalOrcadoDespesasCorrentes} totalOrcadoOutrasDespesas={totalOrcadoOutrasDespesas} />
            </Col>
            <Col md={6}>
              <DashboardReceitas unidadeOrcamentaria={unidadeOrcamentaria} />
            </Col>
          </Row>            

          <br />

          {(( !!tetoPlanoConta &&
                      tetoPlanoConta.length > 0) || (!!tetoPrograma &&
                        tetoPrograma.length > 0)) && (

        <Row>

        <Col md={12}>
        <Row>
        <Portlet fluidHeight={true}>
        <PortletBody>


          <Row>
              <Col md={6}>
              {tetoPlanoConta &&
                      tetoPlanoConta.length > 0  &&  (<>
                <h5 className="text-center">Teto por Grupo de Plano de Conta</h5>
                        <Table variant="info" size="sm">
                                          <thead>
                                            <tr>
                                              <th>Grupo de Plano de Conta</th>
                                              <th>Valor Lançado</th>
                                              <th>Valor Limite</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            
                                            {tetoPlanoConta.map((teto) => (
                                              <tr>
                                              <td>{teto.planoContaCodigo + ' - ' + teto.planoContaNome}
                                              {teto.valorLancado > teto.valorDespesa ? <i className="flaticon-danger kt-font-danger" /> :  ""}</td>
                                              <td><Badge variant= {teto.valorLancado > teto.valorDespesa ? "danger" : "info" } pill>
                                              R$    {Money.toBRL(teto.valorLancado)}
                                                                          </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>
                                              <td><Badge variant="warning" pill>
                                                                          R$ {Money.toBRL(teto.valorDespesa) }
                                                                          </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                                              </tr>    
                                              ))}
                                              <tr>
                                                <td><b>Totais</b></td>
                                                <td colSpan="1">
                                                  <h6><b>R$ {Money.toBRL(CalcularTotalOrcado(tetoPlanoConta))} </b></h6>
                                                </td>
                                                <td colSpan="1">
                                                <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoPlanoConta))}</b></h6>
                                                </td>
                                              </tr>       

                                          </tbody>
                            </Table>
                    </>)}
              </Col>
              <Col md={6}>
              {tetoPrograma &&
                      tetoPrograma.length > 0 && (<>
              <h5 className="text-center">Teto por Estrutura Orçamentária (Programa)</h5>
<Table variant="info" size="sm">
                  <thead>
                    <tr>
                      <th>Estrutura Orçamentária (Programa)</th>
                      <th>Valor Lançado</th>
                      <th>Valor Limite</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {tetoPrograma.map((teto) => (
                      <tr>
                      <td>{teto.estruturaOrcamentariaCodigo + ' - ' + teto.estruturaOrcamentariaNome}
                      {teto.valorLancado > teto.valorDespesa ? <i className="flaticon-danger kt-font-danger" /> : "" }
                      </td>
                      <td><Badge variant={teto.valorLancado > teto.valorDespesa ? "danger" : "info"} pill>
                      R$    {Money.toBRL(teto.valorLancado)}
                                                  </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>
                      <td><Badge variant="warning" pill>
                                                  R$ {Money.toBRL(teto.valorDespesa) }
                                                  </Badge>{teto.valorLancado > 0 && teto.valorLancado == teto.valorDespesa? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                      </tr>    
                      ))}
                      <tr>
                        <td><b>Totais</b></td>
                        <td colSpan="1">
                          <h6><b>R$ {Money.toBRL(CalcularTotalOrcado(tetoPrograma))} </b></h6>
                        </td>
                        <td colSpan="1">
                        <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoPrograma))}</b></h6>
                        </td>
                      </tr>       

                  </tbody>
                </Table>                    
                      
                    </>)}
              
              </Col>
          </Row>         


        </PortletBody>
              </Portlet>
          </Row>
          </Col>

        </Row>         
)}
          <Row>
            <Col md={12}>
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={
                    competencia
                      ? "Situação: " +
                        getValorSituacaoCompetencia(competencia.Situacao)
                      : "--"
                  }
                />
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <CardIdentificacaoOrcamentaria
                        isAdministrator={isAdministrator}
                        tetoPlanoConta={tetoPlanoConta}
                        totalPlanoConta={CalcularTotalTeto(tetoPlanoConta)}
                        totalOrcadoPlanoConta={CalcularTotalOrcado(tetoPlanoConta)}
                        tetoPrograma={tetoPrograma}
                        totalPrograma={CalcularTotalTeto(tetoPrograma)}
                        totalOrcadoPrograma={CalcularTotalOrcado(tetoPrograma)}
                        uo={uo}
                        competencias={competencias}
                        competencia={competencia}
                        setCompetencia={setCompetencia}
                        centroCustoId={centroCustoId}
                        setCentroCustoId={setCentroCustoId}
                        projetoId={projetoId}
                        setProjetoId={setProjetoId}
                        callbackChangeUnidade={getOrcamentoUnidadeOrcamentaria}
                      />
                      <div className="kt-separator kt-separator--dashed"></div>
                      <FormLancamentos
                        titlesEstruturasOrcamentaria={
                          titlesEstruturasOrcamentaria
                        }
                        callbackAfterSaveLancamento={() =>
                          getOrcamentoUnidadeOrcamentaria(
                            unidadeOrcamentaria?.id
                          )
                        }
                        projetoId={projetoId}
                        centroCustoId={centroCustoId}
                        competencia={competencia}
                      />
                    </div>
                  </div>
                </PortletBody>
              </Portlet>
            </Col>
          </Row>

          
        </>
      ) : (
        <MyNotice
          title="Não há nenhuma competência disponível para lançamento."
          icon="la-warning"
          font="kt-font-warning"
        />
      )}
    </>
  );
}

export default Cadastro;
