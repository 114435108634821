import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexFuncionario from "./IndexFuncionario";
import FormCadFuncionario from "./FormCadFuncionario";

export default function FuncionarioPage() {
  return (
    <Switch>
      <Route
        path="/orcamento/funcionario"
        exact={true}
        component={IndexFuncionario}
      />
      <Route
        path="/orcamento/funcionario/novo"
        exact={true}
        component={FormCadFuncionario}
      />
      <Route
        path="/orcamento/funcionario/editar/:id"
        exact={true}
        component={FormCadFuncionario}
      />
    </Switch>
  );
}
