import { PERFIL_ADMIN, PERFIL_USUARIO } from "../enums/perfilEnum";

export const Money = (function () {
  return {
    toDecimal: v => {
      return parseFloat(
        v
          .toString()
          .replace(/\./g, "")
          .replace(",", ".")
      );
    },
    toPtBr: v => {

      if(v == null || v == undefined){
        return "";
      }
      
      return (
        v
          .toString()
          .replace(/\./g, ",")
      );
    },
    toBRL: v => {

      if(v == null || v == undefined){
        return "";
      }
      
      return (
        v
          .toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
      );
      
    }
  };
})();

export const UserProfile = (function () {
  const isAdministrador = (userProfile) =>
    userProfile.toString() === PERFIL_ADMIN.toString();

  const isUsuario = (userProfile) =>
    userProfile.toString() === PERFIL_USUARIO.toString();

  return {
    isAdministrador: isAdministrador,
    isUsuario: isUsuario,
  };
})();