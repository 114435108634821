import React, { useRef, useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, ErrorMessage, getIn } from "formik";
import { Link } from "react-router-dom";

import MyNotice from "../../../partials/layout/MyNotice";

// enums
import { SITUACOES } from "../../../enums/genericEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

//services
import CentroCustoCrud from "../../../crud/centroCusto.crud";
import BaseCrud from "../../../crud/base.crud";

// Configuração formulário com as respectivas validações
const schema = Yup.object().shape({
  CompetenciaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a competencia"
    )
    .required("Campo obrigatório"),
  UnidadeOrcamentariaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a unidade orçamentária"
    )
    .required("Campo obrigatório"),
  Codigo: Yup.string()
    .matches(/^[0-9.]*$/i, "Código inválido, utilize apenas números e ponto.")
    .required("Campo obrigatório"),
  Nome: Yup.string().required("Campo obrigatório"),
  Situacao: Yup.bool()
    .typeError("Selecione a situação.")
    .required("Campo obrigatório"),
});

export default function FormCentroCusto(props, values, handleChange) {
  // Estado principal do formulario
  const [centroCusto, setCentroCusto] = useState({
    Id: "",
    Nome: "",
    Codigo: "",
    CompetenciaId: "",
    Situacao: true,
    UnidadeOrcamentariaId: "",
  });

  // Estados necessários para preenchimento do formulário
  const [unidadesOrcamentarias, setUnidadesOrcamentarias] = useState([]);
  const [competencias, setCompetencias] = useState([]);

  const getCompetencias = async () => {
    let filter = "";
    if (!props.match.params.id) {
      filter += "&$filter=Situacao eq '1'";
    }
    BaseCrud.get(`competencia?$orderBy=Ano desc${filter}`, true).then(
      (response) => {
        setCompetencias(response.data.value);
        if (response.data.value.length > 0) {
          centroCusto.CompetenciaId = response.data.value[0].Id;
        }
      }
    );
  };

  const getUnidadesOrcamentarias = async (competenciaId) =>
    BaseCrud.get(
      `UnidadeOrcamentaria?$filter=CompetenciaId Eq ${competenciaId} And Situacao Eq true`
    );

  const handleGetCompetencias = async () =>
    getCompetencias()
      .then((r) => setCompetencias(r.data.value))
      .catch((e) => {});

  const handleGetUnidadesOrcamentaria = async (competenciaId) => {
    if (!competenciaId) {
      setUnidadesOrcamentarias([]);
    } else {
      getUnidadesOrcamentarias(competenciaId)
        .then((uo) => setUnidadesOrcamentarias(uo.data.value))
        .catch((e) => {});
    }
  };

  useEffect(() => {
    if (competencias.length > 0 && !props.match.params.id) {
      handleGetUnidadesOrcamentaria(competencias[0].Id);
    }
  }, [competencias]);

  // Chamadas Iniciais da pagina
  useEffect(() => {
    // Busca as competencias
    handleGetCompetencias();

    //Busca o centro de custo em caso de edição
    if (props.match.params.id) {
      CentroCustoCrud.getById(
        "CentroCusto/GetById",
        props.match.params.id,
        true
      ).then((response) => {
        let resolveData = {
          Nome: response.data.nome,
          Codigo: response.data.codigo,
          CompetenciaId: response.data.competenciaId,
          Id: response.data.id,
          Situacao: response.data.situacao,
          UnidadeOrcamentariaId: response.data.unidadeOrcamentariaId,
        };
        handleGetUnidadesOrcamentaria(resolveData.CompetenciaId);

        setCentroCusto(resolveData);
      });
    }
  }, []);

  const handleSubmit = function(v) {
    (!!v.Id
      ? editar(v)
      : incluir({
          Nome: v.Nome,
          Codigo: v.Codigo,
          CompetenciaId: v.CompetenciaId,
          Situacao: v.Situacao,
          UnidadeOrcamentariaId: v.UnidadeOrcamentariaId,
        })
    )
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/centro-custo");
      })
      .catch((e) => {});
  };

  const editar = async (v) => CentroCustoCrud.put("CentroCusto", v.Id, v);

  const incluir = async (v) => CentroCustoCrud.post("CentroCusto", v);

  return (
    <>
      <MyNotice title="Cadastro de Centro de Custo (Modelo A)" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={centroCusto}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleChange, handleSubmit }) => (
              <Portlet fluidHeight={true}>
                <PortletHeader title="Informações do cadastro" />
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={3}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              as="select"
                              name="CompetenciaId"
                              value={values.CompetenciaId}
                              onChange={(evt) => {
                                handleChange(evt);
                                handleGetUnidadesOrcamentaria(evt.target.value);
                              }}
                              isInvalid={
                                getIn(errors, "CompetenciaId") &&
                                getIn(touched, "CompetenciaId")
                              }
                              //disable={!!props.match.params.id}
                              disabled={!!values.Id}
                            >
                              {competencias.map((competencia) => (
                                <option
                                  key={competencia.Id}
                                  value={competencia.Id}
                                >
                                  {competencia.Ano + " - " +
                                    getValorTipoCompetencia(competencia.Tipo) + " - " +
                                    getValorSituacaoCompetencia(competencia.Situacao)}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="CompetenciaId" />
                            <Form.Label style={{color:'red'}}><b>Obs:</b> no cadastro serão exibidas apenas as competências com situação 'CADASTRANDO'</Form.Label>
                          </Col>

                          <Col md={3}>
                            <Form.Label>Unidade Orçamentária</Form.Label>
                            <Form.Control
                              as="select"
                              name="UnidadeOrcamentariaId"
                              value={values.UnidadeOrcamentariaId}
                              isInvalid={
                                getIn(errors, "UnidadeOrcamentariaId") &&
                                getIn(touched, "UnidadeOrcamentariaId")
                              }
                              onChange={handleChange}
                            >
                              <option value="">Selecione</option>
                              {unidadesOrcamentarias.map(
                                (unidadeOrcamentaria) => (
                                  <option
                                    key={unidadeOrcamentaria.Id}
                                    value={unidadeOrcamentaria.Id}
                                    onChange={handleChange}
                                  >
                                    {unidadeOrcamentaria.Codigo} -{" "}
                                    {unidadeOrcamentaria.Nome}
                                  </option>
                                )
                              )}
                            </Form.Control>
                            <ErrorMessage name="UnidadeOrcamentariaId" />
                          </Col>

                          <Col md={3}>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                              type="text"
                              name="Codigo"
                              placeholder="Código"
                              value={values.Codigo}
                              isInvalid={
                                getIn(errors, "Codigo") &&
                                getIn(touched, "Codigo")
                              }
                              onChange={handleChange}
                            />
                            <ErrorMessage name="Codigo" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              name="Nome"
                              placeholder="Nome"
                              value={values.Nome}
                              isInvalid={
                                getIn(errors, "Nome") && getIn(touched, "Nome")
                              }
                              onChange={handleChange}
                            />
                            <ErrorMessage name="Nome" />
                          </Col>
                        </Row>
                        {!!values.Id && (
                          <Row>
                            <Col md={3}>
                              <Form.Label>Situação</Form.Label>
                              <Form.Control
                                as="select"
                                name="Situacao"
                                onChange={handleChange}
                                value={values.Situacao}
                                isInvalid={
                                  getIn(errors, "Situacao") &&
                                  getIn(touched, "Situacao")
                                }
                              >
                                {SITUACOES.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                              <ErrorMessage name="Situacao" />
                            </Col>
                          </Row>
                        )}
                           
                      </Form>
                    </div>
                  </div>
                </PortletBody>
                <PortletFooter style={{ textAlign: "right" }}>
                  <Link
                    to="/orcamento/centro-custo"
                    variant="secondary"
                    className="btn btn-secondary"
                  >
                    Voltar
                  </Link>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </PortletFooter>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
