export const SITUACAO_ATIVO = true;
export const SITUACAO_INATIVO = false;

export const TEXTO_SITUACAO_ATIVO = "Ativo";
export const TEXTO_SITUACAO_INATIVO = "Inativo";

export const SITUACOES = [
  { id: SITUACAO_ATIVO, valor: TEXTO_SITUACAO_ATIVO },
  { id: SITUACAO_INATIVO, valor: TEXTO_SITUACAO_INATIVO },
];
