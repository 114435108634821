import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import { Formik, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { TIPOS_COMPETENCIA } from "../../../enums/competenciaEnum";
import { SITUACOES_COMPETENCIA } from "../../../enums/competenciaEnum";
import { SITUACAO_COMPETENCIA_CONSOLIDADO } from "../../../enums/competenciaEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";
import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MyNotice from "../../../partials/layout/MyNotice";
import { Money } from "../../../helpers/helper";


const schema = Yup.object().shape({
  CompetenciaId: Yup.string().required("Campo obrigatório")
});


export default function FormCompetenciaDefault(props) {

  const [competencias, setCompetencias] = useState([]);
  const [competenciaDefault, setcompetenciaDefault] = useState([]);

  useEffect(() => {
    //-- Busca as competencias
    BaseCrud.get(
      `competencia?$orderBy=Ano desc`,
      true
    ).then((response) => {
      setCompetencias(response.data.value);
      setcompetenciaDefault(response.data.value.filter((x) =>x.Vigente == true)[0])
    });

  }, []);



const handleSubmit = function(v) {   
 
  selecionar({
    id: competenciaDefault,
  }).then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/competencia");
      })
      .catch((e) => e);
  };

  const selecionar = async (v) => BaseCrud.post("Competencia/SetDefault/" + v.id);


  return (
    <>
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={competenciaDefault}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleSubmit }) => (

              <Portlet fluidHeight={true}>
                <PortletHeader
                  title= 'Selecione a competência de trabalho'
                />
                <Form>
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                      <Card border="primary">
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <Form.Label>Competência Atual</Form.Label>

                                  <Form.Control
                                    as="select"
                                    name="CompetenciaId"
                                    value={competenciaDefault.Id}
                                    isInvalid={
                                      getIn(errors, "CompetenciaId") &&
                                      getIn(touched, "CompetenciaId")
                                    }
                                    onChange={(evt) => {
                                      setcompetenciaDefault(evt.target.value)                        
                                    }}
                                  >
                                    <option key={0} value="">
                                      Selecione
                                    </option>
                                    {competencias.map((competencia) => (
                                      <option
                                        key={competencia.Id}
                                        value={competencia.Id}
                                      >
                                        {competencia.Ano} - {" "}
                                        {getValorTipoCompetencia(competencia.Tipo)} - {" "}
                                        {getValorSituacaoCompetencia(competencia.Situacao)}
                                      </option>
                                    ))}
                                  </Form.Control>                                

                                <ErrorMessage name="CompetenciaId" />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </PortletBody>
                  <PortletFooter style={{ textAlign: "right" }}>
                    <Link
                      to="/orcamento/competencia"
                      variant="secondary"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </PortletFooter>
                </Form>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
