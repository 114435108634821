import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import BaseCrud from "../../../crud/base.crud";

export default function({
  values,
  classificacaoOrcamentaria,
  handleChange,
  handleChangeProjetos,
  setFieldValue,
}) {
  const [niveisValor, setNiveisValor] = useState({});

  const getSameNivel = async (
    competenciaId,
    classificacaoOrcamentariaId,
    nivel,
    nivelPai = null
  ) =>
    BaseCrud.get(
      `EstruturaOrcamentaria?competenciaId=${competenciaId}&ClassificacaoOrcamentariaId=${classificacaoOrcamentariaId}${
        nivel ? "&nivel=" + nivel : ""
      }${nivelPai ? "&nivelPai=" + nivelPai : ""}&$filter=Situacao Eq true`
    );

  const handleGetEstruturaByNivelItemClassificacao = async (
    competenciaId,
    classificacaoOrcamentariaId,
    nivel,
    evt = null
  ) => {
    const totalNiveis =
      classificacaoOrcamentaria.ItensClassificacaoOrcamentaria.length;

    if (parseInt(nivel, 10) > totalNiveis) return;

    let nivelPai = null,
      aceitaValor = null,
      valor = !!evt ? evt.target.value : null;

    if (
      evt &&
      evt.target &&
      evt.target.selectedOptions[0] &&
      evt.target.selectedOptions[0].dataset.nivel
    ) {
      nivelPai = evt.target.selectedOptions[0].dataset.nivel;
      aceitaValor =
        evt.target.selectedOptions[0].dataset.aceitavalor === "true";
    }

    if (!aceitaValor && valor !== "") {
      getSameNivel(competenciaId, classificacaoOrcamentariaId, nivel, nivelPai)
        .then((r) => handleSetNiveisValor(nivel, r.data.value))
        .catch((e) => {});
    } else {
      handleSetNiveisValor(nivel, [], true);
    }
  };

  const handleSetNiveisValor = async (
    nivel,
    valores,
    flagClearNext = false
  ) => {
    const total =
      classificacaoOrcamentaria.ItensClassificacaoOrcamentaria.length;
    let copyState = { ...niveisValor, [nivel]: valores };
    // nivel que está. Até ultimo nivel.
    const nivelClear = parseInt(nivel, 10) + (flagClearNext === true ? 0 : 1);
    for (let i = nivelClear; i <= total; i++)
      copyState = { ...copyState, [i]: [] };

    for (let i = parseInt(nivel, 10); i <= total; i++)
      setFieldValue(`EstruturaOrcamentaria[${i}]`, "");

    setNiveisValor(copyState);
  };

  const handleChangeEstruturaOrcamentaria = async (evt) => {
    handleChange(evt);
    setFieldValue(`Projeto`, "");

    if (
      niveisValor[evt.target.dataset.nivel] &&
      Array.isArray(niveisValor[evt.target.dataset.nivel])
    ) {
      const nivelSelected = niveisValor[evt.target.dataset.nivel].find(
        (e) => e.Id === evt.target.value
      );

      if (
        nivelSelected &&
        Array.isArray(nivelSelected.ProjetosEstruturaOrcamentaria) &&
        nivelSelected.ProjetosEstruturaOrcamentaria.length > 0
      ) {
        const projetos = nivelSelected.ProjetosEstruturaOrcamentaria.map(
          (peo) => peo.Projeto
        );
        handleChangeProjetos(projetos);
      } else handleChangeProjetos([]);
    } else handleChangeProjetos([]);
  };

  useEffect(() => {
    if (
      classificacaoOrcamentaria.ItensClassificacaoOrcamentaria &&
      classificacaoOrcamentaria.ItensClassificacaoOrcamentaria.length > 0 &&
      !!values.CompetenciaId
    ) {
      handleGetEstruturaByNivelItemClassificacao(
        values.CompetenciaId,
        classificacaoOrcamentaria.Id,
        classificacaoOrcamentaria.ItensClassificacaoOrcamentaria[0].Nivel
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    classificacaoOrcamentaria.ItensClassificacaoOrcamentaria,
    values.CompetenciaId,
  ]);

  return (
    <Form>
      <Card border="primary">
        <Card.Header>{classificacaoOrcamentaria.Nome}</Card.Header>
        <Card.Body>
          <Row className="mb-3">
            {classificacaoOrcamentaria.ItensClassificacaoOrcamentaria &&
              classificacaoOrcamentaria.ItensClassificacaoOrcamentaria.map(
                (ico) => (
                  <Col md={4} key={ico.Id}>
                    <Form.Label>{ico.Nome}</Form.Label>
                    <Form.Control
                      as="select"
                      data-nivel={ico.Nivel}
                      value={values.EstruturaOrcamentaria[ico.Nivel]}
                      name={`EstruturaOrcamentaria[${ico.Nivel}]`}
                      onChange={(evt) => {
                        handleChangeEstruturaOrcamentaria(evt);
                        handleGetEstruturaByNivelItemClassificacao(
                          values.CompetenciaId,
                          classificacaoOrcamentaria.Id,
                          parseInt(ico.Nivel, 10) + 1,
                          evt
                        );
                      }}
                    >
                      <option value="" key={`${ico.Id}-${ico.Nivel}`}>
                        Selecione
                      </option>
                      {niveisValor &&
                        niveisValor[ico.Nivel] &&
                        niveisValor[ico.Nivel].map((nivel, idx) => {
                          return (
                            <option
                              key={`${ico.id}-${ico.Nivel}-${idx}`}
                              data-nivel={nivel.Nivel}
                              data-aceitavalor={nivel.AceitaValor}
                              value={nivel.Id}
                            >
                              {nivel.Codigo} - {nivel.Titulo}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                )
              )}
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
}
