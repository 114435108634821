export const TIPO_COMPETENCIA_DOTACAOINICIAL = "1";
export const TIPO_COMPETENCIA_REFORMULACAO = "2";

export const SITUACAO_COMPETENCIA_CADASTRANDO = 1;
export const SITUACAO_COMPETENCIA_LIBERADOLANCAMENTO = 2;
export const SITUACAO_COMPETENCIA_VALIDACAO = 3;
export const SITUACAO_COMPETENCIA_CONSOLIDADO = 4;

export const TEXTO_TIPO_COMPETENCIA_DOTACAOINICIAL = "Dotação inicial";
export const TEXTO_TIPO_COMPETENCIA_REFORMULACAO = "Reformulação";

export const TEXTO_SITUACAO_COMPETENCIA_CADASTRANDO = "Cadastrando";
export const TEXTO_SITUACAO_COMPETENCIA_LIBERADOLANCAMENTO =
  "Liberado Lançamento";
export const TEXTO_SITUACAO_COMPETENCIA_VALIDACAO = "Validação";
export const TEXTO_SITUACAO_COMPETENCIA_CONSOLIDADO = "Consolidado";

export const TIPOS_COMPETENCIA = [
  {
    id: TIPO_COMPETENCIA_DOTACAOINICIAL,
    valor: TEXTO_TIPO_COMPETENCIA_DOTACAOINICIAL,
  },
  {
    id: TIPO_COMPETENCIA_REFORMULACAO,
    valor: TEXTO_TIPO_COMPETENCIA_REFORMULACAO,
  },
];

export const SITUACOES_COMPETENCIA = [
  {
    id: SITUACAO_COMPETENCIA_CADASTRANDO,
    valor: TEXTO_SITUACAO_COMPETENCIA_CADASTRANDO,
  },
  {
    id: SITUACAO_COMPETENCIA_LIBERADOLANCAMENTO,
    valor: TEXTO_SITUACAO_COMPETENCIA_LIBERADOLANCAMENTO,
  },
  {
    id: SITUACAO_COMPETENCIA_VALIDACAO,
    valor: TEXTO_SITUACAO_COMPETENCIA_VALIDACAO,
  },
  {
    id: SITUACAO_COMPETENCIA_CONSOLIDADO,
    valor: TEXTO_SITUACAO_COMPETENCIA_CONSOLIDADO,
  },
];

export const getValorTipoCompetencia = (id) => {
  let tipoCompetencia = TIPOS_COMPETENCIA.filter((f) => f.id == id);

  if (tipoCompetencia && tipoCompetencia.length > 0) {
    return tipoCompetencia[0].valor;
  }

  return "";
};

export const getValorSituacaoCompetencia = (id) => {
  let situacaoCompetencia = SITUACOES_COMPETENCIA.filter((f) => f.id == id);

  if (situacaoCompetencia && situacaoCompetencia.length > 0) {
    return situacaoCompetencia[0].valor;
  }

  return "";
};
