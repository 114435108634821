import React, {useState, useEffect} from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";
import { Row, Col, Badge, Table,Alert, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getValorTipoUnidadeOrcamentaria } from "../../../enums/unidadeOrcamentariaEnum";
import { toInteger, toNumber } from "lodash";
import { Money } from "../../../helpers/helper";
import BaseCrud from "../../../crud/base.crud";

export default function GridUnidadeOrcamentaria({ unidadesOrcamentaria }) {

  const [competenciaAtual, setCompetenciaAtual] = useState([]);
  const [tetoTotalPlanoConta, setTetoTotalPlanoConta] = useState({});
  const [tetoTotalPrograma, setTetoTotalPrograma] = useState({});




  const getCompetenciaAtual = async () =>
    BaseCrud.get(`Competencia?$orderBy=Ano desc&$filter=Vigente Eq true`);

  
  const handleGetCompetenciaAtual = async () =>
    getCompetenciaAtual()
      .then((r) => {
        setCompetenciaAtual(r.data.value[0].Id);
        handleTetoContaUnidadeOrcamentaria(r.data.value[0].Id);
        handleTetoProgramaUnidadeOrcamentaria(r.data.value[0].Id);
      })
      .catch((e) => { });

  useEffect(() => {
    handleGetCompetenciaAtual();
  }, [setCompetenciaAtual]);


  const GetTotalTetoPlanoContaUO = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/total-teto-conta/${id}`
  );

  const handleTetoContaUnidadeOrcamentaria = (id) => {
    GetTotalTetoPlanoContaUO(id).then((response) => {
      setTetoTotalPlanoConta(response.data);
    });
  };


  const GetTotalTetoProgramaUO = async (id) =>
  BaseCrud.get(
    `UnidadeOrcamentaria/total-teto-programa/${id}`
  );

  const handleTetoProgramaUnidadeOrcamentaria = (id) => {
    GetTotalTetoProgramaUO(id).then((response) => {
      setTetoTotalPrograma(response.data);
    });
  };

  function CalcularTotalTeto(teto){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorTotal;    
    }   
    return total;
  }

  function CalcularTotalOrcado(teto, retornoFormatado=false){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorTotalOrcado;    
    }   
    return total;
  }

  return (    
    <Row>
      <Col md={12}>
        <Row>
        <Portlet fluidHeight={true}>
        <PortletBody>

        {!!unidadesOrcamentaria &&
  unidadesOrcamentaria.length > 0 && (

    <Row>
    <Col md={6}>
      { !!tetoTotalPlanoConta &&
                      tetoTotalPlanoConta.length > 0 ? (<>
      <h5 className="text-center">Teto Geral Despesa Corrente - Grupo de Plano de Conta</h5>
            <Table variant="info" size="sm">
                  <thead>
                    <tr>
                      <th>Grupo de Plano de Conta</th>
                      <th>Valor Lançado</th>
                      <th>Valor Limite</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {tetoTotalPlanoConta.map((teto) => (
                      <tr>
                      <td>{teto.planoContaCodigo + ' - ' + teto.planoContaNome}
                      {teto.valorTotalOrcado > teto.valorTotal ? <i className="flaticon-danger kt-font-danger" /> : ""}
                      </td>                      
                      <td><Badge variant= {teto.valorTotalOrcado > teto.valorTotal ? "danger" : "info" } pill>
                      R$    {Money.toBRL(teto.valorTotalOrcado)}
                                                  </Badge>     
                                                  {teto.valorTotalOrcado > 0 && teto.valorTotalOrcado == teto.valorTotal? <i className="flaticon2-check-mark kt-font-success" /> : ""}                                             
                                                  </td>
                      <td><Badge variant="warning" pill>
                                                  R$ {Money.toBRL(teto.valorTotal) }
                                                  </Badge>{teto.valorTotalOrcado > 0 && teto.valorTotalOrcado == teto.valorTotal? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                      </tr>    
                      ))}
                      <tr>
                        <td><b>Totais</b></td>
                        <td colSpan="1">
                          <h6><b>R$ { Money.toBRL(CalcularTotalOrcado(tetoTotalPlanoConta))} </b></h6>
                        </td>
                        <td colSpan="1">
                        <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoTotalPlanoConta))}</b></h6>
                        </td>
                      </tr>       

                  </tbody>
                </Table>
                    </>) : (<>
                              <Alert variant='secondary' >
                                              <b>Atenção!  <span style={{color:"red"}}></span></b>. Nenhum teto encontrado na competência atual.
                                        </Alert>      
                    </>)}
    </Col>
    <Col md={6}>
    {!!tetoTotalPrograma &&
                      tetoTotalPrograma.length > 0  ? (<>

    <h5 className="text-center">Teto Geral Despesa Corrente - Estrutura Orçamentária (Programa)</h5>     


    <Table variant="info" size="sm">
                      <thead>
                        <tr>
                          <th>Estrutura Orçamentária (Programa)</th>
                          <th>Valor Lançado</th>
                          <th>Valor Limite</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {tetoTotalPrograma.map((teto) => (
                          <tr>
                          <td>{teto.estruturaOrcamentariaCodigo + ' - ' + teto.estruturaOrcamentariaNome}
                          {teto.valorTotalOrcado > teto.valorTotal ? <i className="flaticon-danger kt-font-danger" /> : "" }
                          </td>
                          <td><Badge variant={teto.valorTotalOrcado > teto.valorTotal ? "danger" : "info"} pill>
                          R$    {Money.toBRL(teto.valorTotalOrcado)}
                                                      </Badge>{teto.valorTotalOrcado > 0 && teto.valorTotalOrcado == teto.valorTotal? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>
                          <td><Badge variant="warning" pill>
                                                      R$ {Money.toBRL(teto.valorTotal) }
                                                      </Badge>{teto.valorTotalOrcado > 0 && teto.valorTotalOrcado == teto.valorTotal? <i className="flaticon2-check-mark kt-font-success" /> : ""}</td>                                                
                          </tr>    
                          ))}
                          <tr>
                            <td><b>Totais</b></td>
                            <td colSpan="1">
                              <h6><b>R$ {Money.toBRL(CalcularTotalOrcado(tetoTotalPrograma))} </b></h6>
                            </td>
                            <td colSpan="1">
                            <h6><b>R$ {Money.toBRL(CalcularTotalTeto(tetoTotalPrograma))}</b></h6>
                            </td>
                          </tr>       

                      </tbody>
    </Table>
                    </>) : (<>
                              <Alert variant='secondary' >
                                              <b>Atenção!  <span style={{color:"red"}}></span></b>. Nenhum teto encontrado na competência atual.
                                        </Alert>      
                    </>)}      
    </Col>
</Row> 
    
  )}
    
        


        </PortletBody>
              </Portlet>
          </Row>
          </Col>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem das Unidades Orçamentárias" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Ano</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Teto Despesa Corrente</th>
                      <th>Situação</th>
                      <th style={{ width: 10 }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!!unidadesOrcamentaria &&
                      unidadesOrcamentaria.length > 0 &&
                      unidadesOrcamentaria.map((uo) => (
                        <tr key={uo.Id}>
                          <td>{uo.Competencia.Ano}</td>
                          <td>{uo.Codigo}</td>
                          <td>{uo.Nome}</td>
                          <td>{getValorTipoUnidadeOrcamentaria(uo.Tipo)}</td>
                          <td>
                            
                          <Link
                              to={`/orcamento/unidade-orcamentaria/ajustar-teto/${uo.Id}`}
                              title="Gerenciar Teto"
                            >
                              { 
                              uo.UsarControleTetoEstruturaOrcamentaria || uo.UsarControleTetoPlanoConta ?
                                 
                              "R$ " +
                              Money.toBRL(uo.ValorDespesa)  : <Badge variant="secondary">Não usa</Badge>                 
                           }
                            {uo.HaDivergenciaTetos === true ? <i className="flaticon-danger kt-font-danger" /> : "" }
                            {(uo.UsarControleTetoPlanoConta && tetoTotalPlanoConta && toNumber(CalcularTotalOrcado(tetoTotalPlanoConta)) > toNumber(CalcularTotalTeto(tetoTotalPlanoConta))) || (uo.UsarControleTetoEstruturaOrcamentaria && tetoTotalPrograma && toNumber(CalcularTotalOrcado(tetoTotalPrograma)) > toNumber(CalcularTotalTeto(tetoTotalPrograma))) ? <i className="flaticon-danger kt-font-danger" /> : "" }
                            </Link>
                            </td>
                          <td>
                            {uo.Situacao ? (
                              <Badge variant="success">Ativo</Badge>
                            ) : (
                              <Badge variant="danger">Inativo</Badge>
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/orcamento/unidade-orcamentaria/editar/${uo.Id}`}
                              className="btn btn-clean btn-md btn-icon"
                              title="Editar"
                            >
                              <i className="la la-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
