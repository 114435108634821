export const TIPO_PLANOCONTA_SINTETICA = 1;
export const TIPO_PLANOCONTA_ANALITICA = 2;

export const CATEGORIA_PLANOCONTA_DESPESA = 1;
export const CATEGORIA_PLANOCONTA_RECEITA = 2;

export const TEXTO_TIPO_PLANOCONTA_SINTETICA = "Sintética";
export const TEXTO_TIPO_PLANOCONTA_ANALITICA = "Analítica";

export const TEXTO_CATEGORIA_PLANOCONTA_DESPESA = "Despesa";
export const TEXTO_CATEGORIA_PLANOCONTA_RECEITA = "Receita";

export const TIPOS_PLANOCONTA = [
  { id: TIPO_PLANOCONTA_SINTETICA, valor: TEXTO_TIPO_PLANOCONTA_SINTETICA },
  { id: TIPO_PLANOCONTA_ANALITICA, valor: TEXTO_TIPO_PLANOCONTA_ANALITICA },
];

export const CATEGORIAS_PLANOCONTA = [
  {
    id: CATEGORIA_PLANOCONTA_DESPESA,
    valor: TEXTO_CATEGORIA_PLANOCONTA_DESPESA,
  },
  {
    id: CATEGORIA_PLANOCONTA_RECEITA,
    valor: TEXTO_CATEGORIA_PLANOCONTA_RECEITA,
  },
];

export const getValorCategoriaPlanoConta = (id) => {
  let categoriaPlanoConta = CATEGORIAS_PLANOCONTA.filter((f) => f.id == id);

  if (categoriaPlanoConta && categoriaPlanoConta.length > 0) {
    return categoriaPlanoConta[0].valor;
  }

  return "";
};

export const getValorTipoPlanoConta = (id) => {
  let tipoPlanoConta = TIPOS_PLANOCONTA.filter((f) => f.id == id);

  if (tipoPlanoConta && tipoPlanoConta.length > 0) {
    return tipoPlanoConta[0].valor;
  }

  return "";
};
