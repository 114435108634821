import React, { useState, useEffect } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import MyNotice from "../../../partials/layout/MyNotice";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Formik, ErrorMessage, getIn } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";

//Enum
import { SITUACOES } from "../../../enums/genericEnum";
import { TIPOS_PLANOCONTA } from "../../../enums/planoContaOrcamentarioEnum";
import { CATEGORIAS_PLANOCONTA } from "../../../enums/planoContaOrcamentarioEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

//services
import PlanoContaOrcamentarioCrud from "../../../crud/planoContaOrcamentario.crud";

const schema = Yup.object().shape({
  CompetenciaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a competencia"
    )
    .required("Campo obrigatório"),
  Codigo: Yup.string()
    .matches(/^[0-9.]*$/i, "Código inválido, utilize apenas números e ponto.")
    .required("Campo obrigatório"),
  Nome: Yup.string().required("Campo obrigatório"),
  Tipo: Yup.string().required("Campo obrigatório"),
  Categoria: Yup.string().required("Campo obrigatório"),
  Situacao: Yup.bool()
    .typeError("Selecione a situação.")
    .required("Campo obrigatório"),
});

export default function FormPlanoContaOrcamentario(props) {
  const [planoContaOrcamentario, setPlanoContaOrcamentario] = useState({
    Id: "",
    Nome: "",
    Codigo: "",
    Situacao: true,
    Tipo: "",
    Categoria: "",
    CompetenciaId: "",
    Nivel: "",
  });

  // Estados necessários para preenchimento do formulário
  const [competencias, setCompetencias] = useState([]);

  const [filterCompetencia, setFilterCompetencia] = useState("");

  // Chamadas Iniciais da pagina
  useEffect(() => {
    // Busca as competencias
    PlanoContaOrcamentarioCrud.get(`competencia?$orderBy=Ano desc${!props.match.params.id ? "&$filter=Situacao ne '4'" : ""}`, true).then(
      (response) => {
        setCompetencias(response.data.value);
        planoContaOrcamentario.CompetenciaId = response.data.value[0].Id;
      }
    );

    //Busca o Plano De Conta em caso de edição
    if (props.match.params.id) {
      PlanoContaOrcamentarioCrud.getById(
        "PlanoContaOrcamentario/GetById",
        props.match.params.id,
        true
      ).then((response) => {
        let resolveData = {
          Nome: response.data.nome,
          Codigo: response.data.codigo,
          Situacao: response.data.situacao,
          Tipo: response.data.tipo,
          Categoria: response.data.categoria,
          CompetenciaId: response.data.competenciaId,
          Nivel: response.data.nivel,
          Id: response.data.id,
        };

        setPlanoContaOrcamentario(resolveData);
      });
    }
  }, []);

  const handleSubmit = function (v) {
    (!!v.Id
      ? editar(v)
      : incluir({
        Nome: v.Nome,
        Codigo: v.Codigo,
        Situacao: v.Situacao,
        Tipo: v.Tipo,
        Categoria: v.Categoria,
        CompetenciaId: v.CompetenciaId,
        Nivel: v.Nivel,
      })
    )
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/plano-conta-orcamentario");
      })
      .catch((e) => e);
  };

  const editar = async (v) =>
    PlanoContaOrcamentarioCrud.put("PlanoContaOrcamentario", v.Id, v);

  const incluir = async (v) =>
    PlanoContaOrcamentarioCrud.post("PlanoContaOrcamentario", v);

  return (
    <>
      <MyNotice title="Cadastro do Plano de Contas Orçamentário" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={planoContaOrcamentario}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleChange, handleSubmit }) => (
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={
                    !!values.Id
                      ? "Edição do Plano De Contas Orçamentário"
                      : "Cadastro do Plano De Contas "
                  }
                />
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={4}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              as="select"
                              name="CompetenciaId"
                              value={values.CompetenciaId}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "CompetenciaId") &&
                                getIn(touched, "CompetenciaId")
                              }
                              disabled={!!props.match.params.id}
                            >
                              {competencias.map((competencia) => (
                                <option
                                  key={competencia.Id}
                                  value={competencia.Id}
                                >
                                  {competencia.Ano + " - " +
                                    getValorTipoCompetencia(competencia.Tipo) + " - " +
                                    getValorSituacaoCompetencia(competencia.Situacao)}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="CompetenciaId" />
                            <Form.Label style={{color:'red'}}><b>Obs:</b> no cadastro serão exibidas apenas as competências com situação diferente de 'CONSOLIDADO'</Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Label>Categoria</Form.Label>
                            <Form.Control
                              as="select"
                              name="Categoria"
                              onChange={handleChange}
                              value={values.Categoria}
                              isInvalid={
                                getIn(errors, "Categoria") &&
                                getIn(touched, "Categoria")
                              }
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {CATEGORIAS_PLANOCONTA.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="Categoria" />
                          </Col>
                          <Col md={4}>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                              type="text"
                              name="Codigo"
                              placeholder="Digite o código da conta"
                              value={values.Codigo}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Codigo") &&
                                getIn(touched, "Codigo")
                              }
                            />
                            <ErrorMessage name="Codigo" />
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={4}>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              name="Nome"
                              placeholder="Nome"
                              value={values.Nome}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Nome") && getIn(touched, "Nome")
                              }
                            />
                            <ErrorMessage name="Nome" />
                          </Col>
                          <Col md={4}>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                              as="select"
                              name="Tipo"
                              onChange={handleChange}
                              value={values.Tipo}
                              isInvalid={
                                getIn(errors, "Tipo") && getIn(touched, "Tipo")
                              }
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {TIPOS_PLANOCONTA.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="Tipo" />
                          </Col>
                          {!!values.Id && (
                            <Col md={4}>
                              <Form.Label>Situação</Form.Label>
                              <Form.Control
                                as="select"
                                name="Situacao"
                                onChange={handleChange}
                                value={values.Situacao}
                                isInvalid={
                                  getIn(errors, "Situacao") &&
                                  getIn(touched, "Situacao")
                                }
                              >
                                {SITUACOES.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                              <ErrorMessage name="situacao" />
                            </Col>
                          )}
                        </Row>
                      </Form>
                    </div>
                  </div>
                </PortletBody>
                <PortletFooter style={{ textAlign: "right" }}>
                  <Link
                    to="/orcamento/plano-conta-orcamentario"
                    variant="primary"
                    className="btn btn-secondary"
                  >
                    Voltar
                  </Link>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </PortletFooter>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
