import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexEstruturaOrcamentaria from "./IndexEstruturaOrcamentaria";
import FormEstruturaOrcamentaria from "./FormEstruturaOrcamentaria";

export default function Index() {
  return (
    <Switch>
      <Route
        path="/orcamento/estrutura-orcamentaria"
        exact={true}
        component={IndexEstruturaOrcamentaria}
      />
      <Route
        path="/orcamento/estrutura-orcamentaria/incluir"
        exact={true}
        component={FormEstruturaOrcamentaria}
      />
      <Route
        path="/orcamento/estrutura-orcamentaria/editar/:id"
        exact={true}
        component={FormEstruturaOrcamentaria}
      />
    </Switch>
  );
}
