/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import {
  isNotAuthUrl,
  redirectLogin,
  redirectLogout,
  menuUserProfileEnumInterface,
} from "../../_metronic/utils/utils";
import Authorization from "../pages/auth/Authorization";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const {
    isAuthorized,
    menuConfig,
    userLastLocation,
    userProfile,
  } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized:
        !!auth.user &&
        !!auth.user.id &&
        !!auth.authToken &&
        !!auth.authToken.accessToken,
      userProfile: !!auth.user && auth.user.ultimoPerfil,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      !isAuthorized &&
      !isNotAuthUrl(window.location.href) &&
      !window.location.pathname.startsWith("/logout")
    ) {
      redirectLogin();
    } else if (
      !isAuthorized &&
      window.location.pathname.startsWith("/logout")
    ) {
      redirectLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, window.location.pathname]);

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized ? (
          <Route exact path="/authorization" component={Authorization} />
        ) : (
          <>
            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={LogoutPage} />

            <Layout>
              {menuUserProfileEnumInterface.isAdministrador(userProfile) ? (
                <Redirect exact from="/" to="/orcamento/gerar-orcamento/novo" />
              ) : (
                <Redirect exact from="/" to="/lancamento/novo" />
              )}
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          </>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
