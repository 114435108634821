import React from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { Formik } from "formik";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Money, UserProfile } from "../../../helpers/helper";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import MyNotice from "../../../partials/layout/MyNotice";

export const CardFormLancamentos = ({
  lancamentos,
  callbackAfterSaveLancamento,
}) => {
  const { userProfile } = useSelector(({ auth }) => ({
    userProfile: !!auth.user && auth.user.ultimoPerfil,
  }));
  const isAdministrator = UserProfile.isAdministrador(userProfile);

  const sanitizeForm = (values) =>
    values.map((lancamento) => ({
      id: lancamento.id,
      valor: lancamento.valor ? Money.toDecimal(lancamento.valor) : null,
      justificativa: lancamento?.justificativa || null,
      memoriaCalculo: lancamento?.memoriaCalculo || null,
      observacao: lancamento?.observacao || null,
      planoContaOrcamentario: lancamento.planoContaOrcamentario,
    }));

  const salvarLancamento = (sanitized) => {
    BaseCrud.post("Orcamento/SalvarLancamentos", sanitized).then((r) => {
      toast.success("Lançamento efetuado com sucesso");
      callbackAfterSaveLancamento();
    });
  };

  const validateForm = (lancamentos) => {
    let errors = {};

    lancamentos.map(
      ({ valor, justificativa, memoriaCalculo, observacao }, idx) => {
        if (
          !!valor ||
          !!justificativa ||
          !!memoriaCalculo ||
          (!!isAdministrator && !!observacao)
        ) {
          errors[idx] = {};

          if (!valor) {
            errors[idx].valor = "required";
          }else if (
            !!valor &&
            Money.toDecimal(valor) % parseInt(Money.toDecimal(valor), 10) !== 0
          ) {
            errors[idx].valor = "cents";
          }else if (!!valor && Money.toDecimal(valor) < 0) {
            errors[idx].valor = "negative";
          }

          if (!justificativa) errors[idx].justificativa = "required";
          if (!memoriaCalculo) errors[idx].memoriaCalculo = "required";
        }
      }
    );

    // garbagy collector :p
    if (
      errors &&
      Object.values(errors).filter((x) => Object.keys(x).length > 0).length <= 0
    ) {
      errors = {};
    }

    return errors;
  };

  return !lancamentos || lancamentos.length <= 0 ? (
    <>Nenhum registro disponível para orçamento.</>
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={lancamentos}
      key={`lancamentos-${lancamentos[0].planoContaOrcamentarioId}`}
      validate={validateForm}
      onSubmit={(v) => salvarLancamento(sanitizeForm(v))}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Card key={values[0].planoContaOrcamentario.id}>
          <Card.Header>
            <MyNotice title="Após preencher os campos clique no botão SALVAR no final do formulário!" />
          </Card.Header>
          <Card.Body>
            {values.map((despesa, idx) => (
              <Row key={`lancamento-${idx}`} className="mt-4">
                <Col sm={12} md={3}>
                  <Form.Label className="font-weight-bold">
                    {`${despesa.planoContaOrcamentario.codigo} - ${despesa.planoContaOrcamentario.nome}`} 
                    
                  </Form.Label>
                </Col>
                <Col sm={12} md={3}>
                  <Form.Label>Valor R$</Form.Label>
                  <NumberFormat
                    displayType="input"
                    type="text"
                    name={`[${idx}].valor`}
                    className={`form-control ${errors &&
                      errors[idx] &&
                      errors[idx].valor &&
                      "border-danger"}`}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={despesa.valor}
                    placeholder="R$"
                    onChange={handleChange}
                  />
                  {errors && errors[idx] && errors[idx].valor ? (
                    errors[idx].valor === "cents" ? (
                      <span className="text-danger">
                        * Preencha o valor sem os centavos!
                      </span>
                    ) : errors[idx].valor === "negative" ? (
                      <span className="text-danger">
                        * Valor não pode ser negativo!
                      </span>
                    ) : (
                      <span className="text-danger">* Campo Obrigatório</span>
                    )
                  ) : (
                    <></>
                  )}
                </Col>
                <Col sm={12} md={3}>
                  <Form.Label>Justificativa</Form.Label>
                  <Form.Control
                    type="text"
                    name={`[${idx}].justificativa`}
                    onChange={handleChange}
                    className={`${errors &&
                      errors[idx] &&
                      errors[idx].justificativa &&
                      "border-danger"}`}
                    value={despesa.justificativa}
                  />
                  {errors && errors[idx] && errors[idx].justificativa && (
                    <span className="text-danger">* Campo Obrigatório</span>
                  )}
                </Col>
                <Col sm={12} md={3}>
                  <Form.Label>Memória de Calculo</Form.Label>
                  <Form.Control
                    type="text"
                    name={`[${idx}].memoriaCalculo`}
                    className={`${errors &&
                      errors[idx] &&
                      errors[idx].memoriaCalculo &&
                      "border-danger"}`}
                    onChange={handleChange}
                    value={despesa.memoriaCalculo}
                  />
                  {errors && errors[idx] && errors[idx].memoriaCalculo && (
                    <span className="text-danger">* Campo Obrigatório</span>
                  )}
                </Col>
                {isAdministrator && (
                  <Col xs={12}>
                    <Form.Label>Observação</Form.Label>
                    <Form.Control
                      type="text"
                      name={`[${idx}].observacao`}
                      onChange={handleChange}
                      value={despesa.observacao}
                      className={`${errors &&
                        errors[idx] &&
                        errors[idx].observacao &&
                        "border-danger"}`}
                    />
                    {errors && errors[idx] && errors[idx].observacao && (
                      <span className="text-danger">* Campo Obrigatório</span>
                    )}
                  </Col>
                )}
              </Row>
            ))}
          </Card.Body>
          <Card.Footer>
            <Button onClick={handleSubmit} variant="primary">
              Salvar
            </Button>
          </Card.Footer>
        </Card>
      )}
    </Formik>
  );
};
