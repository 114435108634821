import React, { useState } from "react";
import { Card, Accordion, Nav } from "react-bootstrap";
import { CardFormLancamentos } from "./CardFormLancamentos";
import { CardFormLancamentosReformulacao } from "./CardFormLancamentosReformulacao";
import BaseCrud from "../../../crud/base.crud";
import { CATEGORIA_PLANOCONTA_DESPESA } from "../../../enums/planoContaOrcamentarioEnum";
import { TIPO_COMPETENCIA_REFORMULACAO } from "../../../enums/competenciaEnum";

export const CardPlanoContaOrcamentario = ({
  planoContasOrcamentario,
  estruturaOrcamentariaId,
  centroCustoId,
  projetoId,
  competencia,
  callbackAfterSaveLancamento,
}) => {
  const [lancamentos, setLancamentos] = useState({});

  const handleGetLancamentosByPlanoConta = (
    planoContaOrcamentario,
    estruturaOrcamentariaId,
    centroCustoId,
    projetoId
  ) => {
    if (!lancamentos[planoContaOrcamentario.Id]) {
      BaseCrud.get(
        `Orcamento/Lancamentos?centroCustoId=${centroCustoId}&nivelPaiPlanoContaOrcamentario=${planoContaOrcamentario.Nivel}&estruturaOrcamentariaId=${estruturaOrcamentariaId}&projetoId=${projetoId}`
      ).then((r) => {
        setLancamentos({
          ...lancamentos,
          [planoContaOrcamentario.Id]: r.data,
        });
      });
    }
  };

  return (
    <>
      {!!planoContasOrcamentario && planoContasOrcamentario.length > 0 && (
        <Accordion>
          {planoContasOrcamentario.map((planoContaOrcamentario, idx) => (
            <Card key={idx}>
              <Card.Header>
                <Accordion.Toggle
                  as={Nav.Link}
                  variant="link"
                  eventKey={`title-plano-conta-${idx}`}
                  onClick={() =>
                    handleGetLancamentosByPlanoConta(
                      planoContaOrcamentario,
                      estruturaOrcamentariaId,
                      centroCustoId,
                      projetoId
                    )
                  }
                >
                  <i
                    className="la la-check-square"
                    style={{ color: "green", fontSize: 14, padding: 6 }}
                  />
                  {`${planoContaOrcamentario.Codigo} - ${planoContaOrcamentario.Nome}`}{" "}
                  &mdash;
                  <span
                    className={`font-weight-bold ${
                      parseInt(planoContaOrcamentario.Categoria, 10) ===
                      CATEGORIA_PLANOCONTA_DESPESA
                        ? "text-danger"
                        : "text-primary"
                    }`}
                  >
                    {parseInt(planoContaOrcamentario.Categoria, 10) ===
                    CATEGORIA_PLANOCONTA_DESPESA
                      ? "Despesa"
                      : "Receita"}
                  </span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`title-plano-conta-${idx}`}>
                <Card.Body>
                  {competencia.Tipo === TIPO_COMPETENCIA_REFORMULACAO ? (
                    <CardFormLancamentosReformulacao
                      lancamentos={lancamentos[planoContaOrcamentario.Id]}
                      callbackAfterSaveLancamento={callbackAfterSaveLancamento}
                    />  
                  ) : (
                    <CardFormLancamentos
                      lancamentos={lancamentos[planoContaOrcamentario.Id]}
                      callbackAfterSaveLancamento={callbackAfterSaveLancamento}
                    />
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      )}
    </>
  );
};
