import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

//Enum
import { SITUACOES } from "../../../enums/genericEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

import { Formik } from "formik";
import MyNotice from "../../../partials/layout/MyNotice";

//services
import BaseCrud from "../../../crud/base.crud";

export default function Filter({
  handleFilter,
  competenciaId,
}) {
  // Estados necessários para preenchimento do formulário
  const [competencias, setCompetencias] = useState([]);
  const [competenciaDefault, setcompetenciaDefault] = useState([]);

  // Chamadas Iniciais da pagina
  useEffect(() => {
    // Busca as competencias
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {
      setCompetencias(response.data.value)
      setcompetenciaDefault(response.data.value.filter((x) =>x.Vigente == true)[0])
    }
      
    );
  }, []);

  return (
    <>
      <MyNotice title="Cadastro da Estrutura Orçamentária" />
      <Row>
        <Col md={12}>
          <Portlet fluidHeight={true}>
            <PortletHeader
              title="Filtros"
              toolbar={
                <PortletHeaderToolbar>
                  <Link
                    to="/orcamento/estrutura-orcamentaria/incluir"
                    variant="primary"
                    className="btn btn-primary"
                  >
                    Novo
                  </Link>
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <div className="kt-widget12">
                <div className="kt-widget12__content">
                  <Formik
                    enableReinitialize={true}
                    onSubmit={handleFilter}
                    initialValues={{ CompetenciaId: competenciaId }}
                  >
                    {({
                      errors,
                      values,
                      touched,
                      handleChange,
                      handleSubmit,
                      resetForm,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={3}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              as="select"
                              name="CompetenciaId"
                              type="text"
                              value ={competenciaDefault.Id}
                              placeholder="Pesquise pela competência"
                              onChange={(evt) => {
                                setcompetenciaDefault(evt.target.value)  
                                handleChange(evt);                              
                              }}
                            >
                              {competencias.length > 0 &&
                                competencias.map((competencia) => (
                                  <option
                                    key={competencia.Id}
                                    value={competencia.Id}
                                  >
                                    {competencia.Ano + " - " +
                                      getValorTipoCompetencia(competencia.Tipo) + " - " +
                                      getValorSituacaoCompetencia(competencia.Situacao)}
                                  </option>
                                ))}
                            </Form.Control>
                          </Col>
                          <Col md={3}>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                              name="Codigo"
                              type="text"
                              values={values.Codigo}
                              placeholder="Pesquise pelo código da Estrutura Orçamentária"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Titulo</Form.Label>
                            <Form.Control
                              name="Titulo"
                              type="text"
                              values={values.Nome}
                              placeholder="Pesquise pelo título da Estrutura Orçamentária"
                              onChange={handleChange}
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Situação</Form.Label>
                            <Form.Control
                              as="select"
                              name="Situacao"
                              type="text"
                              values={values.Situacao}
                              placeholder="Situação"
                              onChange={handleChange}
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {SITUACOES.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} style={{ paddingTop: 20 }}>
                            <div>
                              <Button
                                onClick={(ev) => {
                                  resetForm();
                                }}
                                type="reset"
                                variant="secondary"
                              >
                                Limpar
                              </Button>
                              <Button
                                type="submit"
                                variant="primary"
                                className="ml-2"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  handleSubmit(values);
                                }}
                              >
                                Pesquisar
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </PortletBody>
          </Portlet>
        </Col>
      </Row>
    </>
  );
}
