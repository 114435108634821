import React from "react";
import { toast } from "react-toastify";

//-- components
import Filter from "./FilterEstruturaOrcamentaria";
import Grid from "./GridEstruturaOrcamentaria";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

//services

import BaseCrud from "../../../crud/base.crud";
import EstruturaOrcamentariaCrud from "../../../crud/estruturaOrcamentaria.crud";


export default class IndexEstruturaOrcamentaria extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      estruturasOrcamentarias: [],
      //Filtro Paginação
      filter: "",
      page: 0,
      totalItems: 0,
      competenciaId: null,
      classificacaoOrcamentariaId: null
    };

    this.getEstruturaOrcamentaria = this.getEstruturaOrcamentaria.bind(
      this
    );

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {      
      this.setState({ competenciaId: response.data.value.filter((x) => x.Vigente == true)[0].Id }, () => {
      
        BaseCrud.get("ClassificacaoOrcamentaria").then((response) => {
          this.setState({
            classificacaoOrcamentariaId: response.data.Id
          }, () => {

            this.getEstruturaOrcamentaria();
          });
        });
      });
    });
  }

  handleFilter(v) {
    let filter = "&$filter=";

    //Filtros necessários para a página
    filter += !!v.Titulo
      ? `contains(tolower(Titulo), tolower('${v.Titulo}')) and `
      : "";
    filter += !!v.Codigo ? `Codigo eq '${v.Codigo}' and ` : "";
    filter += !!v.Situacao ? `Situacao eq ${v.Situacao} and ` : "";

    if (filter != "&$filter=" && v.CompetenciaId) {
      filter = filter.slice(0, -4) + `&CompetenciaId=${v.CompetenciaId}`;
    } else {
      filter = `&CompetenciaId=${v.CompetenciaId}`;
    }

    this.setState({ filter: filter });
    this.getEstruturaOrcamentaria();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `$top=${PER_PAGE}&$skip=${skip}&${
      !!this.state.filter
        ? this.state.filter
        : `&CompetenciaId=${this.state.competenciaId}`
      }&$orderBy=Nivel`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getEstruturaOrcamentaria();
    });
  }

  getEstruturaOrcamentaria() {
    if (this.state.competenciaId) {
      EstruturaOrcamentariaCrud.get(
        `EstruturaOrcamentaria?$count=true&classificacaoOrcamentariaId=${this.state.classificacaoOrcamentariaId}&${this.getEndpointComplement()}` //MOCK
      )
        .then((response) => {
          this.setState({
            estruturasOrcamentarias: response.data.value,
            totalItems: response.data["@odata.count"],
          });
        })
        .catch((e) => { });
    }
  }

  render() {
    return (
      <>
        <Filter
          handleFilter={this.handleFilter}
          competenciaId={this.state.competenciaId}
          filter={this.state.filter}
        />
        <Grid
          estruturasOrcamentarias={this.state.estruturasOrcamentarias}
        />
        <DefaultPagination
          items={this.state.estruturasOrcamentarias}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
