import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Row, Col, Form, Card, Alert } from "react-bootstrap";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";
import CentroCustoCrud from "../../../crud/centroCusto.crud";
import ProjetoCrud from "../../../crud/projeto.crud";
import { toInteger, toNumber } from "lodash";
import { eo } from "date-fns/locale";

export const CardIdentificacaoOrcamentaria = function({
  isAdministrator,
  tetoPlanoConta,
  totalPlanoConta,
  totalOrcadoPlanoConta,  
  tetoPrograma,
  totalPrograma,
  totalOrcadoPrograma,
  uo,
  competencias,
  competencia,
  setCompetencia,
  centroCustoId,
  setCentroCustoId,
  projetoId,
  setProjetoId,
  callbackChangeUnidade,
}) {
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [mensagemErro, setMensagemErro] = useState(null);

  const handleGetCentrosCusto = (competenciaId) => {
    setCentrosCusto([]);
    setCentroCustoId("");
    setMensagemErro(null);

    if (!competenciaId) return;

    CentroCustoCrud.GetCentrosCustoDoFuncionarioLogado(competenciaId).then(
      (response) => {
        setMensagemErro(
          response.data ? null : "Nenhum centro de custo encontrado."
        );
        setCentrosCusto(response.data || []);

        if (response.data.length === 1) {
          setCentroCustoId(response.data[0].id);
        }
      }
    );
  };

  function CalcularTotalTeto(teto){
    var total = 0;
    for (let index = 0; index < teto.length; index++) {
     total += teto[index].valorDespesa;    
    }   
    return total;
  }


  const getProjetos = (centroCustoId) => {
    setProjetoId("");
    setProjetos([]);

    if (!centroCustoId) return;

    ProjetoCrud.GetByCentroCusto(centroCustoId).then((response) => {
      setProjetos(response.data);
    });
  };

  const handleChangeCentroCusto = (centroCustoId) => {
    var cc = centrosCusto.find(obj => obj.id === centroCustoId);
    
    console.log('centro de custo selecionado:');
    console.log(cc);
    callbackChangeUnidade(
      !!cc &&
        cc.unidadeOrcamentariaId
    );
    
    setCentroCustoId(centroCustoId || "");
  };

  useEffect(() => {
    getProjetos(centroCustoId);
    callbackChangeUnidade(
      !!centrosCusto &&
        centrosCusto.length > 0 &&
        centrosCusto.find((c) => c.id === centroCustoId) &&
        centrosCusto.find((c) => c.id === centroCustoId).unidadeOrcamentariaId
    );
  }, [centroCustoId]);

  useEffect(() => {
    handleGetCentrosCusto(competencia.Id);
  }, [competencia]);

  

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        centrosCusto: centrosCusto,
        projetos: projetos,
        competencia: competencia,
        tetoPlanoConta:   tetoPlanoConta,
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Card border="primary">
          <Card.Header>Identificação do Orçamento</Card.Header>
          <Card.Body>
            <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
              <Form.Label column sm="2">
                Competência
              </Form.Label>
              <Col sm="10">
                {!isAdministrator ? (
                  <Form.Control
                    plaintext
                    readOnly
                    value={
                      !!values.competencia.Id
                        ? values.competencia.Ano + " - " +
                          getValorTipoCompetencia(values.competencia.Tipo) + " - " +
                          getValorSituacaoCompetencia(values.competencia.Situacao)
                        : "--"
                    }
                  />
                ) : (
                  <Form.Control
                    as="select"
                    name="competencia"
                    onChange={(evt) => {
                      handleChange(evt);
                      setCompetencia(
                        competencias.find((c) => c.Id === evt.target.value) ||
                          {}
                      );
                    }}
                    value={values.competencia.Id}
                  >
                    <option value="">Selecione</option>
                    {competencias.map((competencia) => (
                      <option key={competencia.Id} value={competencia.Id}>
                        {competencia.Ano + " - " +
                          getValorTipoCompetencia(competencia.Tipo) + " - " +
                          getValorSituacaoCompetencia(competencia.Situacao)}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </Col>
            </Form.Group>
            {values.centrosCusto && values.centrosCusto.length > 0 && (
               
              <Form.Group as={Row} style={{ marginBottom: "1rem" }}>
                <Form.Label column md="2">
                  Centro de Custo (Modelo A)
                </Form.Label>
                <Col sm="10">
                  {values.centrosCusto.length > 1 ? (
                    <Form.Control
                      as="select"
                      name="CentroCusto"
                      onChange={(evt) => {
                        handleChange(evt);
                        handleChangeCentroCusto(evt.target.value);
                      }}
                      value={centroCustoId}
                    >
                      <option value="">Selecione</option>
                      {values.centrosCusto.map((centroCusto) => (
                        <option key={centroCusto.id} value={centroCusto.id}>
                          {centroCusto.codigo + " - " + centroCusto.nome}
                        </option>
                      ))}
                    </Form.Control>
                  ) : (
                    <Form.Control
                      plaintext
                      readOnly
                      value={
                        values.centrosCusto[0].codigo +
                        " - " +
                        values.centrosCusto[0].nome
                      }
                    />
                  )}
                </Col>
              </Form.Group>
            )}
            {                      
             
            
            values.projetos.length > 0 && (     
            <Form.Group as={Row} style={{ marginBottom: "1rem" }}>
                <Form.Label column md="2">
                  Projeto
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    as="select"
                    name="Projeto"
                    disabled={ (uo && uo.UsarControleTetoPlanoConta && uo.UsarControleTetoEstruturaOrcamentaria && totalPlanoConta != totalPrograma) }
                    onChange={(evt) => {
                      handleChange(evt);
                      setProjetoId(evt.target.value);
                    }}
                    value={projetoId}
                  >
                    <option value="">Selecione</option>
                    {values.projetos.map((projeto) => (
                      <option key={projeto.id} value={projeto.id}>
                        {projeto.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              
            )
            
                     
            }

<Col>
{(uo && uo.UsarControleTetoPlanoConta && uo.UsarControleTetoEstruturaOrcamentaria && totalPlanoConta != totalPrograma) ? 
 (                <Alert variant='warning'>
 <b>Atenção!  <span style={{color:"red"}}></span></b>. Há uma divergência nos valores dos tetos orçamentários e o lançamento só ficará disponível após a equipe de orçamento analisar.
</Alert>   ) : ""

}

</Col>

            {mensagemErro &&  (
              <Row style={{ marginTop: 20 }}>
                <Col md={12}>{mensagemErro}</Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};
