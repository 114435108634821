import React from "react";
import { Route, Switch } from "react-router-dom";

import Cadastro from "./Cadastro";

export default function IndexLancamento() {
  return (
    <Switch>
      <Route
        path="/lancamento/novo"
        exact={true}
        component={Cadastro}
      />
    </Switch>
  );
}
