import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexPlanoContaOrcamentario from "./IndexPlanoContaOrcamentario";
import FormPlanoContaOrcamentario from "./FormPlanoContaOrcamentario";

export default function Index() {
  return (
    <Switch>
      <Route
        path="/orcamento/plano-conta-orcamentario"
        exact={true}
        component={IndexPlanoContaOrcamentario}
      />
      <Route
        path="/orcamento/plano-conta-orcamentario/incluir"
        exact={true}
        component={FormPlanoContaOrcamentario}
      />
      <Route
        path="/orcamento/plano-conta-orcamentario/editar/:id"
        exact={true}
        component={FormPlanoContaOrcamentario}
      />
    </Switch>
  );
}
