import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import { Formik, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

//Enum
import { SITUACOES } from "../../../enums/genericEnum";
import { TIPOS_UNIDADES_ORCAMENTARIA } from "../../../enums/unidadeOrcamentariaEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

import { Link } from "react-router-dom";

import MyNotice from "../../../partials/layout/MyNotice";
import { Money } from "../../../helpers/helper";

const schema = Yup.object().shape({
  CompetenciaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a competencia"
    )
    .required("Campo obrigatório"),
  Codigo: Yup.string()
    .matches(/^[0-9.]*$/i, "Código inválido, utilize apenas números e ponto.")
    .required("Campo obrigatório"),
  Nome: Yup.string().required("Campo obrigatório"),
  Sigla: Yup.string().required("Campo obrigatório"),
  Tipo: Yup.number()
    .typeError("Selecione o tipo.")
    .required("Campo obrigatório"),
});

export default function FormUnidadeOrcamentaria(props) {

  const [unidadeOrcamentaria, setUnidadeOrcamentaria] = useState({
    CompetenciaId: "",
    Codigo: "",
    Nome: "",
    Sigla: "",
    Tipo: "",
    ValorDespesa: "",
    ValorReceita: "",
    Funpri: "",
  });
  const [competencias, setCompetencias] = useState([]);

  const [filterCompetencia, setFilterCompetencia] = useState("");

  useEffect(() => {
    handleGetCompetencias();
  }, [filterCompetencia]);

  useEffect(() => {
    if (props.match.params.id) {
      handleGetCompetencias();
      handleGetUnidadeOrcamentaria(props.match.params.id);
    } else {
      setFilterCompetencia("&$filter=Situacao ne '4'");
    }
  }, []);

  const handleGetCompetencias = () =>
    getCompetencias().then((r) => {
      setCompetencias(r.data.value);
      unidadeOrcamentaria.CompetenciaId = r.data.value[0].Id;
    });

  const handleGetUnidadeOrcamentaria = (id) => {
    getUnidadeOrcamentaria(id)
      .then((uo) => {
        uo.data.ValorDespesa = Money.toPtBr(uo.data.ValorDespesa);
        uo.data.ValorReceita = Money.toPtBr(uo.data.ValorReceita);
        uo.data.Funpri = Money.toPtBr(uo.data.Funpri);

        setUnidadeOrcamentaria(uo.data);
      })
      .catch((e) => e);
  };

  const handleSubmit = function (v) {
    sanitizeForm(v);

    (!!v.Id ? editar(v) : incluir(v))
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/unidade-orcamentaria");
      })
      .catch((e) => e);
  };

  const getCompetencias = async () =>
    BaseCrud.get("competencia?$orderBy=Ano desc" + filterCompetencia);

  const getUnidadeOrcamentaria = async (id) =>
    BaseCrud.getById("UnidadeOrcamentaria", id);

  const editar = async (v) => BaseCrud.put("UnidadeOrcamentaria", v.Id, v);

  const incluir = async (v) => BaseCrud.post("UnidadeOrcamentaria", v);

  const sanitizeForm = (v) => {
    v.ValorDespesa = !!v.ValorDespesa ? Money.toDecimal(v.ValorDespesa) : 0;
    v.ValorReceita = !!v.ValorReceita ? Money.toDecimal(v.ValorReceita) : 0;
    v.Funpri = !!v.Funpri ? Money.toDecimal(v.Funpri) : 0;
  };

  return (
    <>
      <MyNotice title="Cadastro de Unidade Orçamentária" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={unidadeOrcamentaria}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleChange, handleSubmit }) => 

            (
              <Portlet fluidHeight={true}>
                <PortletHeader title="Dados da Unidade Orçamentária" />
                <Form>
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              as="select"
                              name="CompetenciaId"
                              value={values.CompetenciaId}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "CompetenciaId") &&
                                getIn(touched, "CompetenciaId")
                              }
                              disabled={!!props.match.params.id}
                            >
                              {competencias.map((competencia) => (
                                <option
                                  key={competencia.Id}
                                  value={competencia.Id}
                                >
                                  {competencia.Ano + " - " +
                                    getValorTipoCompetencia(competencia.Tipo) + " - " +
                                    getValorSituacaoCompetencia(competencia.Situacao)}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="CompetenciaId" />
                            <Form.Label style={{color:'red'}}><b>Obs:</b> no cadastro serão exibidas apenas as competências com situação 'CADASTRANDO'</Form.Label>
                          </Col>
                          <Col md={3}>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                              type="text"
                              name="Codigo"
                              placeholder="Código"
                              value={values.Codigo}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Codigo") &&
                                getIn(touched, "Codigo")
                              }
                            />
                            <ErrorMessage name="Codigo" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              name="Nome"
                              placeholder="Nome"
                              value={values.Nome}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Nome") && getIn(touched, "Nome")
                              }
                            />
                            <ErrorMessage name="Nome" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Sigla</Form.Label>
                            <Form.Control
                              type="text"
                              name="Sigla"
                              placeholder="Sigla"
                              value={values.Sigla}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Sigla") &&
                                getIn(touched, "Sigla")
                              }
                            />
                            <ErrorMessage name="Sigla" />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                              as="select"
                              name="Tipo"
                              onChange={handleChange}
                              value={values.Tipo}
                              isInvalid={
                                getIn(errors, "Tipo") && getIn(touched, "Tipo")
                              }
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {TIPOS_UNIDADES_ORCAMENTARIA.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="Tipo" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Valor Despesa</Form.Label>
                            <NumberFormat
                              displayType={"input"}
                              type="text"
                              name="ValorDespesa"
                              className="form-control"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={values.ValorDespesa}
                              placeholder="R$"
                              onChange={handleChange}
                            />
                            <ErrorMessage name="ValorDespesa" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Valor Receita</Form.Label>
                            <NumberFormat
                              displayType={"input"}
                              type="text"
                              name="ValorReceita"
                              className="form-control"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={values.ValorReceita}
                              placeholder="R$"
                              onChange={handleChange}
                            />
                            <ErrorMessage name="ValorReceita" />
                          </Col>

                          {/* <Col md={3}>
                            <Form.Label>FUNPRI</Form.Label>
                            <NumberFormat
                              displayType={"input"}
                              type="text"
                              name="Funpri"
                              className="form-control"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={values.Funpri}
                              placeholder="R$"
                              onChange={handleChange}
                            />
                            <ErrorMessage name="Funpri" />
                          </Col> */}
                        </Row>
                        {!!values.Id && (
                          <Row className="mt-3">
                            <Col md={3}>
                              <Form.Label>Situação</Form.Label>
                              <Form.Control
                                as="select"
                                name="Situacao"
                                onChange={handleChange}
                                value={values.Situacao}
                              >
                                <option key={0} value="">
                                  Selecione
                                </option>
                                {SITUACOES.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                              <ErrorMessage name="Situacao" />
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </PortletBody>
                  <PortletFooter className="text-right">
                    <Link
                      to="/orcamento/unidade-orcamentaria"
                      variant="secondary"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </PortletFooter>
                </Form>
              </Portlet>
            )
            
            }
          </Formik>
        </Col>
      </Row>
    </>
  );
}
