import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class EstruturaOrcamentariaCrud extends BaseCrud {
  static async get(endpoint, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/${endpoint}`, { spinner });
  }
}

export default EstruturaOrcamentariaCrud;
