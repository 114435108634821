import React, { useState, useEffect } from "react";
import BaseCrud from "../../../crud/base.crud";

import { Row, Col, Alert, Form, Card } from "react-bootstrap";

import { ErrorMessage } from "formik";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import CheckboxTree from "react-checkbox-tree";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";
import { Checkbox } from "@material-ui/core";

export default function ({ values, handleChange, setFieldValue }) {
  const [competencias, setCompetencias] = useState([]);
  const [unidadesOrcamentaria, setUnidadesOrcamentaria] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selecionarTodos, setSelecionarTodos] = useState(false);

  const getCompetencias = async () =>
    BaseCrud.get(`Competencia?$orderBy=Ano desc&$filter=Situacao ne '4'`);

  const getUnidadeOrcamentaria = async (competenciaId) =>
    BaseCrud.get(
      `UnidadeOrcamentaria?$filter=CompetenciaId Eq ${competenciaId}`
    );

  const getCentrosCusto = async (competenciaId, unidadeOrcamentariaId) =>
    BaseCrud.get(
      `CentroCusto?competenciaId=${competenciaId}&$filter=UnidadeOrcamentariaId Eq ${unidadeOrcamentariaId}`
    );

  const handleGetCompetencias = async () =>
    getCompetencias()
      .then((r) => {
        setCompetencias(r.data.value);
        if (!values.CompetenciaId) {
          setFieldValue("CompetenciaId", r.data.value[0].Id);
        }
      })
      .catch((e) => { });

  const handleGetUnidadesOrcamentaria = async (competenciaId) => {
    if (!competenciaId) {
      setUnidadesOrcamentaria([]);
      setCentrosCusto([]);
    } else {
      getUnidadeOrcamentaria(competenciaId)
        .then((uo) => {
          setUnidadesOrcamentaria(uo.data.value);
          if (!!values.UnidadeOrcamentariaId) {
            handleGetCentrosCusto(values.UnidadeOrcamentariaId);
          }
        })
        .catch((e) => { });
    }
  };

  const handleGetCentrosCusto = async (unidadeOrcamentariaId) => {
    setSelecionarTodos(false);
    if (!unidadeOrcamentariaId || !values.CompetenciaId) {
      setCentrosCusto([]);
    } else {
      getCentrosCusto(values.CompetenciaId, unidadeOrcamentariaId).then((uo) =>
        setCentrosCusto(uo.data.value)
      );
    }
  };

  const handleChangeCentrosCusto = (v, object) => {
    //
    if (object.checked === true) {
      setFieldValue("CentroCustoId", [...values.CentroCustoId, object.value]);
    } else {
      setFieldValue(
        "CentroCustoId",
        values.CentroCustoId.filter((cc) => cc !== object.value)
      );
    }
  };

  const handleSelecionarTodos = (checked, actualValues) => {
    let centrosCustoId = [];

    const auxCc = centrosCusto.map((cc) => cc.Id);
    centrosCustoId =
      checked === true
        ? [...values.CentroCustoId, ...auxCc]
        : values.CentroCustoId.filter((id) => !auxCc.includes(id));
    setSelecionarTodos(!!checked);
    setFieldValue("CentroCustoId", centrosCustoId);
  };

  useEffect(() => {
    if (!!values.setDefaultCompetencia) handleGetCompetencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.setDefaultCompetencia]);

  useEffect(() => {
    if (!!values.CompetenciaId) {
      handleGetUnidadesOrcamentaria(values.CompetenciaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.CompetenciaId]);

  return (
    <Card border="primary">
      <Card.Header>Centro de Custo do Funcionário</Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <Form.Label>Competência</Form.Label>
            <Form.Control
              as="select"
              disabled={true}
              name="CompetenciaId"
              value={values.CompetenciaId}
            >
              {competencias &&
                competencias.length > 0 &&
                competencias.map((competencia) => (
                  <option key={competencia.Id} value={competencia.Id}>
                    {competencia.Ano + " - " +
                      getValorTipoCompetencia(competencia.Tipo) + " - " +
                      getValorSituacaoCompetencia(competencia.Situacao)}
                  </option>
                ))}
            </Form.Control>
          </Col>
          <Col md={6}>
            <Form.Label>Unidade Orçamentária</Form.Label>
            <Form.Control
              as="select"
              name="UnidadeOrcamentariaId"
              value={values.UnidadeOrcamentariaId}
              onChange={(evt) => {
                handleChange(evt);
                handleGetCentrosCusto(evt.target.value);
              }}
            >
              <option value="">Selecione</option>
              {unidadesOrcamentaria.length > 0 &&
                unidadesOrcamentaria.map((unidadeOrcamentaria) => (
                  <option
                    key={unidadeOrcamentaria.Id}
                    value={unidadeOrcamentaria.Id}
                  >
                    {unidadeOrcamentaria.Codigo} - {unidadeOrcamentaria.Nome}
                  </option>
                ))}
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col md={12}>
            {(centrosCusto && centrosCusto.length > 0 && (
              <>
                <Form.Label>Selecione o Centro de Custo</Form.Label>
                <Row>
                  <Col>
                    <Form.Label className="font-weight-bold">
                      <Checkbox
                        name="selecionarTodos"
                        checked={!!selecionarTodos}
                        onChange={(evt) =>
                          handleSelecionarTodos(!!evt.target.checked)
                        }
                      />{" "}
                      Selecionar Todos
                    </Form.Label>
                  </Col>
                </Row>
                <CheckboxTree
                  style={{ marginTop: 15, marginBottom: 15 }}
                  nodes={centrosCusto.map((k) => ({
                    value: k.Id,
                    label: `${k.Codigo} - ${k.Nome}`,
                  }))}
                  checked={values.CentroCustoId}
                  expanded={expanded}
                  onCheck={handleChangeCentrosCusto}
                  onExpand={(expanded) => setExpanded(expanded)}
                  iconsClass="fa5"
                  icons={{
                    check: <span className="rct-icon rct-icon-check" />,
                    uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    halfCheck: (
                      <span className="rct-icon rct-icon-half-check" />
                    ),
                    expandClose: (
                      <span className="rct-icon rct-icon-expand-close" />
                    ),
                    expandOpen: (
                      <span className="rct-icon rct-icon-expand-open" />
                    ),
                    expandAll: (
                      <span className="rct-icon rct-icon-expand-all" />
                    ),
                    collapseAll: (
                      <span className="rct-icon rct-icon-collapse-all" />
                    ),
                    parentClose: null,
                    parentOpen: null,
                    leaf: null,
                  }}
                />
              </>
            )) || (
                <Alert variant="secondary">
                  Nenhum Centro de Custo encontrado, verifique a Unidade
                  Orçamentária.
                </Alert>
              )}
            <ErrorMessage
              className="text-danger"
              component={Form.Label}
              style={{ marginTop: 10 }}
              name="CentroCustoId"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
