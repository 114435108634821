import React from "react";

import Notice from "../content/Notice";

const MyNotice = ({ title, icon, font }) => {
  return (
    <>
      <Notice icon={`la ${icon ? icon : "la-info-circle"} ${font ? font : "kt-font-primary"}`}>
        <h5 style={{ marginTop: 5 }}>{title}</h5>
      </Notice>
    </>
  );
};

export default MyNotice;
