import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

//services
import BaseCrud from "../../../crud/base.crud";

//enums
import { SITUACOES } from "../../../enums/genericEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

export default function FilterUnidadeOrcamentaria({ handleFilter }) {
  // Estados necessários para preenchimento do formulário
  const [competencias, setCompetencias] = useState([]);
  const [competenciaDefault, setcompetenciaDefault] = useState([]);

  // Chamadas Iniciais da pagina
  useEffect(() => {
    // Busca as competencias
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {
      setCompetencias(response.data.value)
      setcompetenciaDefault(response.data.value.filter((x) =>x.Vigente == true)[0])
    }
      
    );
  }, []);

  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader
            title="Filtros"
            toolbar={
              <PortletHeaderToolbar>
                <Link
                  to="/orcamento/unidade-orcamentaria/incluir"
                  variant="primary"
                  className="btn btn-primary"
                >
                  Novo
                </Link>
              </PortletHeaderToolbar>
            }
          />
          <PortletBody>
            <Formik
              enableReinitialize={true}
              onSubmit={handleFilter}
              initialValues={{ Nome: "", CompetenciaId: "" }}
            >
              {({ errors, values, touched, handleChange, handleSubmit }) => (
                <div className="kt-widget12">
                  <div className="kt-widget12__content">
                    <Form>
                      <Row>
                        <Col md={3}>
                          <Form.Label>Competência</Form.Label>
                          <Form.Control
                            as="select"
                            name="CompetenciaId"
                            type="text"
                            value ={competenciaDefault.Id}
                            placeholder="Pesquise pela competência"
                            onChange={(evt) => {
                              setcompetenciaDefault(evt.target.value)  
                              handleChange(evt);                              
                            }}
                          >
                            {competencias.length > 0 &&
                              competencias.map((competencia) => (
                                <option key={competencia.Id} value={competencia.Id}>
                                  {competencia.Ano + " - " +
                                    getValorTipoCompetencia(competencia.Tipo) + " - " +
                                    getValorSituacaoCompetencia(competencia.Situacao)}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                        <Col md={3}>
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            name="Nome"
                            type="text"
                            values={values.Nome}
                            placeholder="Pesquise pelo nome"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Label>Código</Form.Label>
                          <Form.Control
                            name="Codigo"
                            type="text"
                            values={values.Codigo}
                            placeholder="Código"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Label>Situação</Form.Label>
                          <Form.Control
                            as="select"
                            name="Situacao"
                            type="text"
                            values={values.Situacao}
                            placeholder="Situação"
                            onChange={handleChange}
                          >
                            <option key={0} value="">
                              Selecione
                            </option>
                            {SITUACOES.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.valor}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} className="pt-5">
                          <div>
                            <Button type="reset" variant="secondary">
                              Limpar
                            </Button>
                            <Button
                              type="submit"
                              variant="primary"
                              className="ml-2"
                              onClick={(ev) => {
                                ev.preventDefault();
                                handleSubmit(values);
                              }}
                            >
                              Pesquisar
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
