import React, { useState, useEffect } from "react";
import { Button, Modal, Accordion, Nav, Card, Form, Row, Col, AccordionToggle, AccordionCollapse } from "react-bootstrap";
import CheckboxTree from "react-checkbox-tree";

//-- services
import OrcamentoCrud from "../../../crud/orcamento.crud";

//-- helpers
import { Money } from "../../../helpers/helper";

//-- Utils
import {
  flatToHierarchy,
  resolveFlatToHierarchy,
} from "../../../../_metronic/utils/utils";

const ModalEstrutura = ({ projetos, centroCustoId, unidadeOrcamentariaDescription, centroCustoDescription, competenciaId }) => {
  const [show, setShow] = useState(false);
  const [lancamentos, setLancamentos] = useState([]);

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [nodes, setNodes] = useState([{
    value: 'mars',
    label: 'Mars',
    children: [
      { value: 'phobos', label: 'Phobos' },
      { value: 'deimos', label: 'Deimos' },
    ],
  }]);

  useEffect(() => {
    if (projetos.length) {
      projetos.map((projeto) => {
        setLancamentos([]);
        getLancamentos(projeto.id);
      });
    }
  }, [projetos]);

  const getLancamentos = (projetoId) => {
    OrcamentoCrud.getCardReferencial(projetoId, centroCustoId, competenciaId).then(
      response => {
        let data = response.data;
        data.map((lancamento) => {
          lancamento.orcamentos.map((orcamento) => {
            orcamento.valor = orcamento.valor && Money.toPtBr(orcamento.valor)
          });
        });


        let newLancamentos = lancamentos;
        newLancamentos[projetoId] = data;
        setLancamentos(newLancamentos);
      }
    );
  }

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Clique aqui para visualizar
      </Button>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {unidadeOrcamentariaDescription && unidadeOrcamentariaDescription}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} style={{ marginBottom: '15px' }}>
              <strong>Centro de custo: {centroCustoDescription && centroCustoDescription}</strong>
            </Col>
          </Row>
          {projetos.map((projeto) => (
            <Row key={projeto.id}>
              <Col md={12}>
                <Form.Label>{projeto.nome}</Form.Label><br></br>
              </Col>
              <Col md={12}>
                {lancamentos[projeto.id] && (lancamentos[projeto.id].map((lancamento, index) => (
                  <Accordion key={index}>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Nav.Link}
                          variant="link"
                          eventKey={
                            lancamento.estruturaOrcamentariaId
                          }
                        >
                          {lancamento.arvoreEstruturaOrcamentaria.map(
                            (filho, index) => {
                              if (
                                index <
                                lancamento
                                  .arvoreEstruturaOrcamentaria
                                  .length -
                                1
                              ) {
                                return (
                                  filho.titulo + " / "
                                );
                              } else {
                                return filho.titulo;
                              }
                            }
                          )}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse
                        eventKey={
                          lancamento.estruturaOrcamentariaId
                        }>
                        <Card.Body>

                          <Accordion>
                            <Card>
                              {(lancamento.orcamentos.filter(f => f.planoContaOrcamentario.categoria == 2).length > 0) && (
                                <>
                                  <Card.Header>
                                    <AccordionToggle
                                      as={Nav.Link}
                                      variant="link"
                                      eventKey={
                                        0
                                      }>
                                      Receitas
                                    </AccordionToggle>
                                  </Card.Header>
                                  <AccordionCollapse eventKey={0}>
                                    <Card.Body>
                                      {lancamento.orcamentos.map(
                                        (
                                          orcamento,
                                          indexOrcamento
                                        ) => ((orcamento
                                          .planoContaOrcamentario
                                          .categoria == 2) &&
                                          <Row
                                            style={{
                                              marginBottom: 10
                                            }}
                                            key={orcamento.id}
                                          >
                                            <Col md={12}>
                                              <Form.Label>
                                                {orcamento
                                                  .planoContaOrcamentario
                                                  .categoria == 1 ? (
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        color: "red"
                                                      }}
                                                    >
                                                      Despesa
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        color: "green"
                                                      }}
                                                    >
                                                      Receita
                                                    </span>
                                                  )}
                                              </Form.Label>
                                              <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                  orcamento
                                                    .planoContaOrcamentario
                                                    .codigo +
                                                  " - " +
                                                  orcamento
                                                    .planoContaOrcamentario
                                                    .nome
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          )
                                      )}
                                    </Card.Body>
                                  </AccordionCollapse>
                                </>
                              )}
                              {(lancamento.orcamentos.filter(f => f.planoContaOrcamentario.categoria == 1).length > 0) && (
                                <>
                                  <Card.Header>
                                    <AccordionToggle
                                      as={Nav.Link}
                                      variant="link"
                                      eventKey={
                                        1
                                      }>
                                      Despesas
                                    </AccordionToggle>
                                  </Card.Header>
                                  <AccordionCollapse eventKey={1}>
                                    <Card.Body>
                                      {lancamento.orcamentos.map(
                                        (
                                          orcamento,
                                          indexOrcamento
                                        ) => ((orcamento
                                          .planoContaOrcamentario
                                          .categoria == 1) &&
                                          <Row
                                            style={{
                                              marginBottom: 10
                                            }}
                                            key={orcamento.id}
                                          >
                                            <Col md={12}>
                                              <Form.Label>
                                                {orcamento
                                                  .planoContaOrcamentario
                                                  .categoria == 1 ? (
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        color: "red"
                                                      }}
                                                    >
                                                      Despesa
                                                    </span>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        fontSize: 10,
                                                        color: "green"
                                                      }}
                                                    >
                                                      Receita
                                                    </span>
                                                  )}
                                              </Form.Label>
                                              <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                  orcamento
                                                    .planoContaOrcamentario
                                                    .codigo +
                                                  " - " +
                                                  orcamento
                                                    .planoContaOrcamentario
                                                    .nome
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          )
                                      )}
                                    </Card.Body>
                                  </AccordionCollapse>
                                </>
                              )}
                            </Card>
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )))}
              </Col>
            </Row>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalEstrutura;
