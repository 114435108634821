import React from "react";
import { Pagination } from "react-bootstrap";

export const PER_PAGE = 10;

class DefaultPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentWillMount() {
    //Verifica se possui itens para iniciar paginacao
    if (this.props.items && this.props.items.length) {
      this.setPage(0, this.props.initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Verifica se houve alteracao nos itens e inicia a paginacao
    if (this.props.totalItems !== prevProps.totalItems) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage(page) {
    let { pageSize } = this.props;
    let pager = this.state.pager;

    if (page < 0 || page > pager.totalPages) {
      return;
    }

    // get new pager object for specified page
    pager = this.getPager(this.props.totalItems, page, pageSize);

    // update state
    this.setState({ pager: pager });

    // call change page function in parent component
    this.props.onChangePage(page);
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;
    pageSize = pageSize || 10;

    // Total de páginas
    let totalPages = Math.ceil(totalItems / pageSize);

    let startPage, endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // Faz a rolagem das páginas de forma automática (Contendo sempre 10 paginas)
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    let pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  render() {
    var pager = this.state.pager;

    if (!pager.pages || pager.pages.length <= 1) {
      return null; // Não mostrar paginacão se houver apenas uma página
    }

    return (
      <Pagination>
        <Pagination.First
          onClick={() => this.setPage(1)}
          disabled={pager.currentPage === 1 ? true : false}
        />
        <Pagination.Prev
          onClick={() => this.setPage(pager.currentPage - 1)}
          disabled={pager.currentPage === 1 ? true : false}
        />

        {pager.pages.map((page, index) => (
          <Pagination.Item
            key={index}
            active={pager.currentPage === page ? true : false}
            onClick={() => this.setPage(page)}
          >
            {page}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() => this.setPage(pager.currentPage + 1)}
          disabled={pager.currentPage === pager.totalPages ? true : false}
        />
        <Pagination.Last
          onClick={() => this.setPage(pager.totalPages)}
          disabled={pager.currentPage === pager.totalPages ? true : false}
        />
      </Pagination>
    );
  }
}

export default DefaultPagination;
