import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { menuUserProfileEnumInterface } from "../../utils/utils";

export default class MenuSubmenu extends React.Component {
  render() {
    const { item, currentUrl, layoutConfig, userProfile } = this.props;

    return (
      <ul className="kt-menu__subnav" style={{ width: "100%" }}>
        {item.submenu.map((child, index) => (
          <React.Fragment key={index}>
            {(!child.allowedProfiles ||
              menuUserProfileEnumInterface.isAllowed(
                userProfile,
                child.allowedProfiles
              )) &&
              child.section && (
                <MenuSection
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

            {(!child.allowedProfiles ||
              menuUserProfileEnumInterface.isAllowed(
                userProfile,
                child.allowedProfiles
              )) &&
              child.separator && (
                <MenuItemSeparator
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                />
              )}

            {(!child.allowedProfiles ||
              menuUserProfileEnumInterface.isAllowed(
                userProfile,
                child.allowedProfiles
              )) &&
              child.title && (
                <MenuItem
                  item={child}
                  parentItem={item}
                  currentUrl={currentUrl}
                  layoutConfig={layoutConfig}
                />
              )}
          </React.Fragment>
        ))}
      </ul>
    );
  }
}
