import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { Money } from "../../helpers/helper";

const DashboardDespesas = ({ unidadeOrcamentaria, totalOrcadoDespesasCorrentes, totalOrcadoOutrasDespesas}) => {
  const despesaPrevista =
    !!unidadeOrcamentaria?.valorDespesa && unidadeOrcamentaria.valorDespesa;
  const despesaOrcada =
    !!unidadeOrcamentaria?.valorDespesaOrcado &&
    unidadeOrcamentaria.valorDespesaOrcado;
  const saldo = !!despesaPrevista && despesaPrevista - (despesaOrcada || 0);
  const percentual =
    !!despesaPrevista &&
    !!despesaOrcada &&
    (despesaOrcada / despesaPrevista) * 100;
  const nome =
    (unidadeOrcamentaria?.nome &&
      unidadeOrcamentaria.codigo + " - " + unidadeOrcamentaria.nome) ||
    "--";

    const percentualDespesasCorrentes = !!despesaPrevista &&
    !!totalOrcadoDespesasCorrentes &&
    (totalOrcadoDespesasCorrentes / despesaPrevista) * 100;

    const percentualOutrasDespesas = !!despesaPrevista &&
    !!totalOrcadoOutrasDespesas &&
    (totalOrcadoOutrasDespesas / despesaPrevista) * 100;

  return (
    <>
      <Portlet fluidHeight={true}>
        <PortletHeader title={"Previsão Despesas Unidade: " + nome} />
        <PortletBody>
          <div className="kt-widget12">
            <div className="kt-widget12__content">
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">
                    Valor previsto para Unidade
                  </span>
                  <span className="kt-widget12__value kt-font-brand">
                    {!!despesaPrevista
                      ? "R$ " +
                       Money.toBRL(despesaPrevista)
                      : "--"}
                  </span>
                </div>
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">SALDO</span>
                  <span className="kt-widget12__value kt-font-success">
                    {saldo
                      ? "R$ " +
                      Money.toBRL(saldo)
                      : "--"}
                  </span>
                </div>
              </div>
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">Total já Orçado</span>
                  <span className="kt-widget12__value kt-font-danger">
                    {despesaOrcada
                      ? "R$ " +
                      Money.toBRL(despesaOrcada)
                      : "--"}
                  </span>
                </div>
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">% Previsto x Orçado</span>
                  <div className="kt-widget12__progress">
                    <div className="progress kt-progress--sm">
                      <div
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuenow={100}
                        aria-valuemax={100}
                        style={{
                          width: `${percentual ? percentual : 0}%`,
                        }}
                        className="progress-bar bg-success"
                      />
                    </div>
                    <span className="kt-widget12__stat">
                      {percentual
                        ?  Money.toBRL(percentual) + "%"
                        : "--"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">Total já Orçado - Despesas Correntes</span>
                  <span className="kt-widget12__value kt-font-danger">
                    {console.log('totalOrcadoDespesasCorrentes')}
                    {console.log(totalOrcadoDespesasCorrentes)}
                    {!!totalOrcadoDespesasCorrentes
                      ? "R$ " +
                      Money.toBRL(totalOrcadoDespesasCorrentes)
                      : "--"}
                  </span>
                </div>
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">% Previsto x Orçado - Despesas Correntes</span>
                  <div className="kt-widget12__progress">
                    <div className="progress kt-progress--sm">
                      <div
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuenow={100}
                        aria-valuemax={100}
                        style={{
                          width: `${percentualDespesasCorrentes ? percentualDespesasCorrentes : 0}%`,
                        }}
                        className="progress-bar bg-success"
                      />
                    </div>
                    <span className="kt-widget12__stat">
                      {percentual
                        ?  Money.toBRL(percentualDespesasCorrentes) + "%"
                        : "--"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="kt-widget12__item">
                <div className="kt-widget12__info">
                  <span className="kt-widget12__desc">Total já Orçado - Despesas de Capital</span>
                  <span className="kt-widget12__value kt-font-danger">
                    {totalOrcadoOutrasDespesas
                      ? "R$ " +
                      Money.toBRL(totalOrcadoOutrasDespesas)
                      : "--"}
                  </span>
                </div>

              </div>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </>
  );
};

export default DashboardDespesas;
