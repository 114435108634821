import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexUnidadeOrcamentaria from "./IndexUnidadeOrcamentaria";
import FormUnidadeOrcamentaria from "./FormUnidadeOrcamentaria";
import FormTetoUnidadeOrcamentaria from "./FormTetoUnidadeOrcamentaria";

export default function Index() {
  return (
    <Switch>
      <Route
        path="/orcamento/unidade-orcamentaria"
        exact={true}
        component={IndexUnidadeOrcamentaria}
      />
      <Route
        path="/orcamento/unidade-orcamentaria/incluir"
        exact={true}
        component={FormUnidadeOrcamentaria}
      />
      <Route
        path="/orcamento/unidade-orcamentaria/editar/:id"
        exact={true}
        component={FormUnidadeOrcamentaria}
      />
            <Route
        path="/orcamento/unidade-orcamentaria/ajustar-teto/:id"
        exact={true}
        component={FormTetoUnidadeOrcamentaria}
      />


    </Switch>
  );
}
