import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";

const FilterTop = function({ empresa }) {
  return (
    <>
      <div className="kt-header__topbar-item">
        <div className="kt-header__topbar-wrapper">
          <div
            style={{ alignSelf: "center", paddingRight: "7px", marginTop: 20 }}
          >
            <Row>
              <Col>
                <Alert variant="primary">{empresa}</Alert>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => {
  const {
    auth: {
      empresa: { Nome },
    },
  } = store;

  return {
    empresa: Nome,
  };
};

export default connect(mapStateToProps)(FilterTop);
