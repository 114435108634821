import React, { useState } from "react";
import { Card, Accordion, Nav } from "react-bootstrap";
import { CardPlanoContaOrcamentario } from "./CardPlanoContaOrcamentario";
import BaseCrud from "../../../crud/base.crud";

export const FormLancamentos = ({
  titlesEstruturasOrcamentaria,
  centroCustoId,
  projetoId,
  competencia,
  callbackAfterSaveLancamento,
}) => 
{
  const [paisPlanoContaOrcamentario, setPaisPlanoContaOrcamentario] = useState(
    {}
  );

  const handleGetPlanoContaByEstruturaOrcamentaria = (
    estruturaOrcamentariaId,
    centroCustoId,
    projetoId
  ) => {
    //trecho comentado porque percebemos que o sistema não estava fazendo a busca ao mudar o centro de custo
    //if (!paisPlanoContaOrcamentario[estruturaOrcamentariaId+projetoId]) {
      BaseCrud.get(
        `EstruturaOrcamentaria/${estruturaOrcamentariaId}/pais-dos-planos-conta-orcamentario?centroCustoId=${centroCustoId}&projetoId=${projetoId}`
      ).then((r) => {
        setPaisPlanoContaOrcamentario({
          ...paisPlanoContaOrcamentario,
          [estruturaOrcamentariaId+projetoId]: r.data.value,
        });
      });
    //}
  };

  return (
    <>
      {!!titlesEstruturasOrcamentaria &&
        titlesEstruturasOrcamentaria.length > 0 && (
          <Card border="primary">
            <Card.Header>
              Referencial dos Programas Sociais / Plano Orçamentário
            </Card.Header>
            <Card.Body>
              <Accordion>
                {titlesEstruturasOrcamentaria.map((title, idx) => (
                  <Card key={`estrutura-orcamentaria-${idx}`}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Nav.Link}
                        variant="link"
                        eventKey={Object.keys(title)[0]}
                        onClick={() =>
                          handleGetPlanoContaByEstruturaOrcamentaria(
                            Object.keys(title)[0],
                            centroCustoId,
                            projetoId
                          )
                        }
                      >
                        <i
                          className="la la-check-square"
                          style={{
                            color: "green",
                            fontSize: 14,
                            padding: 6,
                          }}
                        />
                        {Object.values(title)[0]}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={Object.keys(title)[0]}>
                      <Card.Body>
                        <CardPlanoContaOrcamentario
                          estruturaOrcamentariaId={Object.keys(title)[0]}
                          centroCustoId={centroCustoId}
                          projetoId={projetoId}
                          competencia={competencia}
                          planoContasOrcamentario={
                            paisPlanoContaOrcamentario[Object.keys(title)[0]+projetoId]
                          }
                          callbackAfterSaveLancamento={
                            callbackAfterSaveLancamento
                          }
                        />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
        )}
    </>
  );
};
