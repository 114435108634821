export const TIPO_UO_ORGAO = 1;
export const TIPO_UO_UNIDADE = 2;
export const TIPO_UO_SECAO = 3;
export const TIPO_UO_INTERIOR = 4;

export const TEXTO_TIPO_UO_ORGAO = "Orgão";
export const TEXTO_TIPO_UO_UNIDADE = "Unidade";
export const TEXTO_TIPO_UO_SECAO = "Seção";
export const TEXTO_TIPO_UO_INTERIOR = "Interior";

export const TIPOS_UNIDADES_ORCAMENTARIA = [
  { id: TIPO_UO_ORGAO, valor: TEXTO_TIPO_UO_ORGAO },
  { id: TIPO_UO_UNIDADE, valor: TEXTO_TIPO_UO_UNIDADE },
  { id: TIPO_UO_SECAO, valor: TEXTO_TIPO_UO_SECAO },
  { id: TIPO_UO_INTERIOR, valor: TEXTO_TIPO_UO_INTERIOR },
];

export const getValorTipoUnidadeOrcamentaria = (id) => {
  let tipoUnidadeOrcamentaria = TIPOS_UNIDADES_ORCAMENTARIA.filter(
    (f) => f.id == id
  );

  if (tipoUnidadeOrcamentaria && tipoUnidadeOrcamentaria.length > 0) {
    return tipoUnidadeOrcamentaria[0].valor;
  }
  return "";
};
