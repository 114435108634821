import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexProjeto from "./IndexProjeto";
import FormProjeto from "./FormProjeto";

export default function Index() {
  return (
    <Switch>
      <Route path="/orcamento/projeto" exact={true} component={IndexProjeto} />
      <Route
        path="/orcamento/projeto/incluir"
        exact={true}
        component={FormProjeto}
      />
      <Route
        path="/orcamento/projeto/editar/:id"
        exact={true}
        component={FormProjeto}
      />
    </Switch>
  );
}
