import { toast } from "react-toastify";
import environment from "../../environments/environments";
import { PERFIL_ADMIN, PERFIL_USUARIO } from "../../app/enums/perfilEnum";
import BaseCrud from "../../app/crud/base.crud";
import { actionTypes, actions } from "../../app/store/ducks/auth.duck";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

const toastError = (data, status, flagToast) => {
  if (
    status === 400 &&
    (flagToast === undefined || flagToast === true) &&
    data.content.length > 0
  ) {
    data.content.forEach((e) => toast.error(e.message.join(" ")));
    return;
  }

  if (status === 403 && (flagToast === undefined || flagToast === true)) {
    toast.error("Você não possui permissão para acessar este recurso.");
    return;
  }

  if (flagToast === undefined || flagToast === true) {
    toast.error(
      "Ocorreu um erro não esperado ao tentar processar sua requisição. Por favor, atualize a página e tente novamente."
    );
  }
};

const splashSreen = (show) => {
  const splashScreen = document.getElementById("splash-screen");

  const isSplashSreenHidden = () => splashScreen.classList.contains("hidden");

  const hideSplashSreen = () => splashScreen.classList.add("hidden");

  const showSplashSreen = () => splashScreen.classList.remove("hidden");

  if (!!show === true && isSplashSreenHidden()) showSplashSreen();
  else if (!isSplashSreenHidden()) hideSplashSreen();
};

export const getUrlLoginFulfilled = () => {
  const { href, origin } = window.location;
  const {
    baseUrlAuth,
    endPointLogin,
    clientId,
    responseType,
    scope,
  } = environment.quadroMedidas;

  return `${baseUrlAuth}${endPointLogin}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${origin}/authorization?location=${origin}`;
};

export const redirectLogin = () => {
  window.location = getUrlLoginFulfilled();
};

export const redirectLogout = () => {
  const { baseUrlAuth, endPointLogout } = environment.quadroMedidas;
  window.location.href = `${baseUrlAuth}${endPointLogout}?postLogout=${window.location.origin}`;
};

export const redirectToChangePassword = () => {
  const { baseUrlAuth, endPointChangePassword } = environment.quadroMedidas;
  window.open(
    `${baseUrlAuth}${endPointChangePassword}?redirect_uri=${window.location.href}`,
    "_blank"
  );
};

export const isNotAuthUrl = (url) => {
  return (
    environment.quadroMedidas.routerNotAuth.filter(
      (endpoint) =>
        url
          .toLowerCase()
          .startsWith(environment.quadroMedidas.baseApi + endpoint) ||
        url.toLowerCase().startsWith(window.location.origin + endpoint)
    ).length > 0
  );
};

export function setupAxios(axios, store) {
  const refreshToken = async () => {
    const {
      auth: {
        authToken: { refreshToken },
      },
    } = store.getState();

    const { endPointRefreshToken } = environment.quadroMedidas;

    const response = await BaseCrud.post(endPointRefreshToken, {
      code: refreshToken,
    });

    if (response.status === 200) {
      store.dispatch(actions.refreshToken(response.data));
    }

    if (response.status === 204) {
      removeStorage("persist:demo3-auth");
      redirectLogout();
    }

    return response;
  };

  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (config && (config.spinner === undefined || config.spinner === true)) {
        splashSreen(true);
      }

      if (authToken && authToken.accessToken) {
        config.headers.Authorization = `Bearer ${authToken.accessToken}`;
      }

      return config;
    },
    (err) => {
      toast.error(
        "Ocorreu um erro inesperado no envio da requisição. Por favor, atualize a página e tente novamente."
      );
      splashSreen(false);
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      splashSreen(false);

      return response;
    },
    async (error) => {
      try {
        splashSreen(false);

        const {
          config,
          response: { status, data },
        } = error;

        if (status === 401) {
          const originalRequest = config;

          let response = await refreshToken();

          if (response.status === 200) {
            const retryOriginalRequest = new Promise((resolve) => {
              resolve(axios(originalRequest));
            });

            return retryOriginalRequest;
          } else {
            redirectLogin();
          }
        }

        if (status === 400 || status > 401) {
          toastError(data, status, config.toast);
        }

        return Promise.reject(error);
      } catch (e) {
        toast.error(
          "Ocorreu um erro inesperado. Verifique se seu perfil de usuário possui permissão para realizar esta operação."
        );
        return Promise.reject(error);
      }
    }
  );
}

export function flatToHierarchy(flat) {
  let roots = [];
  let all = {};

  flat.forEach(function(item) {
    all[item.Nivel] = item;
  });

  Object.keys(all).forEach(function(nivel) {
    const item = all[nivel];
    if (item.NivelPai === null || item.NivelPai === "/") {
      roots.push(item);
    } else if (item.NivelPai in all) {
      let p = all[item.NivelPai];
      if (!("children" in p)) {
        p.children = [];
      }
      p.children.push(item);
    }
  });

  return roots;
}

export function resolveFlatToHierarchy(resolveHirarchy) {
  let res = resolveHirarchy.filter(function f(o) {
    if (o.AceitaValor) {
      o.children = null;
      return true;
    }

    if (o.children) {
      return (o.children = o.children.filter(f)).length;
    }
  });

  return res;
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export const menuUserProfileEnumInterface = (function() {
  const isAllowed = (userProfile, alloweds) =>
    !!alloweds.find((i) => i === userProfile);

  const isAdministrador = (userProfile) =>
    userProfile.toString() === PERFIL_ADMIN.toString();

  const isUsuario = (userProfile) =>
    userProfile.toString() === PERFIL_USUARIO.toString();

  return {
    administrador: [PERFIL_ADMIN.toString()],
    usuario: [PERFIL_USUARIO.toString()],
    all: [PERFIL_ADMIN.toString(), PERFIL_USUARIO.toString()],
    isAllowed: isAllowed,
    isAdministrador: isAdministrador,
    isUsuario: isUsuario,
  };
})();

export function GetMimeTypeByExtension(extension) {
  switch (extension?.toLowerCase()) {
    default:
    case "pdf":
      return "application/pdf";
    case "xls":
      return "application/vnd.ms-excel";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }
}
