import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class OrcamentoCrud extends BaseCrud {
  static async getCardReferencial(idProjeto, idCentroCusto, idCompetencia, spinner = true) {
    return axios.get(
      `${env.quadroMedidas.baseApi}/orcamento/GetCardReferencial/${idProjeto}/${idCentroCusto}/${idCompetencia}`,
      { spinner }
    );
  }

  static async getCardLancamento(
    idUnidadeOrcamentaria,
    categoria,
    spinner = true
  ) {
    return axios.get(
      `${env.quadroMedidas.baseApi}/orcamento/GetCardLancamento/${idUnidadeOrcamentaria}/${categoria}`,
      { spinner }
    );
  }

  static async salvarLancamentos(data, spinner = true) {
    return axios.post(
      `${env.quadroMedidas.baseApi}/orcamento/SalvarLancamentos`,
      data,
      { spinner }
    );
  }

  static async getPlanoContaOrcamentario(
    centroCustoId,
    estruturaOrcamentariaId,
    projetoId,
    spinner = true
  ) {
    return axios.get(
      `${env.quadroMedidas.baseApi}/orcamento/GetPlanoContaOrcamentario/${centroCustoId}/${estruturaOrcamentariaId}/${projetoId}`,
      { spinner }
    );
  }
}

export default OrcamentoCrud;
