import React from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../partials/content/Portlet";
import { Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getValorTipoCompetencia } from "../../../enums/competenciaEnum";
import { getValorSituacaoCompetencia } from "../../../enums/competenciaEnum";
import Moment from "moment";

export default function GridCompetencia({ competencias }) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader 
          title="Listagem das Competências"           
          toolbar={
            <PortletHeaderToolbar>
              <Link
                   to={`/orcamento/competencia/selecionar`}
                   variant="primary"
                   className="btn btn-primary"
              >
                Alterar Competência Atual
              </Link>
            </PortletHeaderToolbar>
          }
          />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Competência</th>
                      <th>Tipo</th>
                      <th>Situacao</th>
                      <th>Data inicial</th>
                      <th>Data Final</th>
                      <th style={{ width: 5 }}>Atual</th>
                      <th style={{ width: 10 }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!!competencias &&
                      competencias.length > 0 &&
                      competencias.map((competencia) => (
                        <tr key={competencia.Id}>
                          <td>{competencia.Ano}</td>
                          <td>{getValorTipoCompetencia(competencia.Tipo)}</td>
                          <td>
                            {getValorSituacaoCompetencia(competencia.Situacao)}
                          </td>
                          <td>
                            {Moment(competencia.DataInicial).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            {Moment(competencia.DataFinal).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            
                            {competencia.Vigente ? (
                              <Link        
                              to={``}                    
                              className="btn btn-clean btn-md btn-icon"
                            >
                              <i className="la la-check-circle"></i>
                            </Link>
                              
                           ):(<></>)}
                          </td>
                          <td>
                            <Link
                              to={`/orcamento/competencia/editar/${competencia.Id}`}
                              className="btn btn-clean btn-md btn-icon"
                              title="Editar"
                            >
                              <i className="la la-edit" />
                            </Link>
                          </td>
                        </tr>
                      ))) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
