import { menuUserProfileEnumInterface } from "../utils/utils";

export default {
  header: {
    self: {},
    items: [
      {
        title: "Gestão Orçamentária",
        root: true,
        alignment: "left",
        page: "",
        translate: "MENU.HOME",
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Configuração",
        desc: "Configurações Gerenciais",
        root: true,
        icon: "la la-gear",
        allowedProfiles: menuUserProfileEnumInterface.administrador,
        submenu: [
          {
            title: "Competência",
            bullet: "dot",
            page: "orcamento/competencia",
            icon: "la la-calendar",
          },
          {
            title: "Unidade Orçamentária",
            bullet: "dot",
            page: "orcamento/unidade-orcamentaria",
            icon: "la la-building",
          },
          {
            title: "Centro de Custo (Modelo A)",
            bullet: "dot",
            page: "orcamento/centro-custo",
            icon: "la la-cubes",
          },
          {
            title: "Estrutura Orçamentária",
            bullet: "dot",
            page: "orcamento/estrutura-orcamentaria",
            icon: "la la-chain",
          },
          {
            title: "Plano de Contas Orçamentário",
            bullet: "dot",
            page: "orcamento/plano-conta-orcamentario",
            icon: "la la-money",
          },
          {
            title: "Projeto",
            bullet: "dot",
            page: "orcamento/projeto",
            icon: "la la-paste",
          },
          {
            title: "Elaboração do Orçamento",
            bullet: "dot",
            page: "orcamento/gerar-orcamento/novo",
            icon: "la la-pie-chart",
          },
          {
            title: "Acompanhar Lançamento",
            bullet: "dot",
            page: "lancamento-administrator",
            icon: "la la-calculator",
          },
          {
            title: "Funcionários",
            bullet: "dot",
            page: "orcamento/funcionario",
            icon: "la la-user-plus",
          },
        ],
      },
      {
        title: "Lançamento",
        desc: "Lançamentos do Orçamento",
        root: true,
        page: "lancamento/novo",
        icon: "la la-calculator",
        allowedProfiles: menuUserProfileEnumInterface.all,
      },
      {
        title: "Relatórios",
        desc: "Relatórios Gerenciais",
        root: true,
        page: "relatorios",
        icon: "la la-bar-chart",
        allowedProfiles: menuUserProfileEnumInterface.all,
      }
    ],
  },
};
