import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexRelatorios from "./IndexRelatorios";
import VisualizarRelatorio from "./VisualizarRelatorio";

export default function RelatoriosPage() {
  return (
    <Switch>
      <Route
        path="/relatorios"
        exact={true}
        component={IndexRelatorios}
      />
      <Route
        path="/relatorios/visualizar/:title/"
        exact={true}
        component={VisualizarRelatorio}
      />
    </Switch>
  );
}
