import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { Formik, ErrorMessage, getIn } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

import MyNotice from "../../../partials/layout/MyNotice";
import BaseCrud from "../../../crud/base.crud";

import { SITUACOES } from "../../../enums/genericEnum";
import { PERFIL } from "../../../enums/perfilEnum";

import CardCentroCusto from "./CardCentroCusto";
import { Link } from "react-router-dom";

const schema = Yup.object().shape({
  Funcionario: Yup.object().shape({
    Cpf: Yup.string()
      .matches(/(\d{3}\.\d{3}\.\d{3}-\d{2})/g, "CPF inválido")
      .required("O CPF é obrigatório"),
    Nome: Yup.string().required("O Nome é obrigatório."),
    Email: Yup.string()
      .email()
      .required("O email é obrigatório"),
  }),
  Perfil: Yup.string()
    .length(1, "O campo Perfil deve ser informado.")
    .required("O campo Perfil é obrigatório."),
  CentroCustoId: Yup.string().required("O Centro de Custo é obrigatório"),
});

export default class FormCadFuncionario extends React.Component {
  constructor(props) {
    super(props);

    // Estado principal do formulario
    this.state = {
      Funcionario: {
        Id: null,
        Nome: "",
        Situacao: "",
        Cpf: "",
        Email: "",
      },
      Perfil: "",
      CentroCustoId: [],
      CompetenciaId: "",
      UnidadeOrcamentariaId: "",
      setDefaultCompetencia: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadFuncionarioId = this.loadFuncionarioId.bind(this);
  }

  async loadFuncionarioId(id) {
    this.handleGetFuncionarioCadastrado(id);
  }

  getCpfMaked = (v) => {
    v = v.toString();
    return `${v.substring(0, 3)}.${v.substring(3, 6)}.${v.substring(
      6,
      9
    )}-${v.substring(9, 11)}`;
  };

  handleGetFuncionarioCadastrado = (id) => {
    this.getFuncionarioCadastrado(id)
      .then((uo) => {
        this.setState({
          Funcionario: {
            Id: uo.data.funcionario.id,
            Nome: uo.data.funcionario.nome,
            Situacao: uo.data.funcionario.situacao,
            Cpf: this.getCpfMaked(uo.data.funcionario.cpf),
            Email: uo.data.funcionario.email,
          },
          Perfil: uo.data.perfil,
          CentroCustoId: uo.data.centroCustoId || [],
          UnidadeOrcamentariaId: uo.data.unidadeOrcamentariaId,
        });

        if (!!uo.data.competenciaId) {
          
          const getCompetencias = async () =>
          BaseCrud.get(`Competencia?$orderBy=Ano desc&$filter=Situacao ne '4'`);

          getCompetencias()
            .then((r) => {
                this.setState({
                  CompetenciaId: r.data.value[0].Id,
                });
            })
            .catch((e) => { });
        }

        this.setState({ setDefaultCompetencia: true });
      })
      .catch((e) => {});
  };

  getFuncionarioCadastrado = async (id) => BaseCrud.getById("Funcionario", id);

  filterFormikValues = (v) => ({
    Funcionario: v.Funcionario,
    Perfil: v.Perfil,
    CentroCustoId: v.CentroCustoId,
    CompetenciaId: v.CompetenciaId,
  });

  inserir = async (v) => {
    BaseCrud.post("Funcionario", v)
      .then((r) => {
        toast.success("Operação realizada com sucesso.");
        this.props.history.push("/orcamento/funcionario");
      })
      .catch((e) => e);
  };

  alterar = async (v) => {
    BaseCrud.put("Funcionario", v.Funcionario.Id, v)
      .then((r) => {
        toast.success("Operação realizada com sucesso.");
      })
      .catch((e) => e);
  };

  handleSubmit(v) {
    const data = this.filterFormikValues(v);

    if (!!v.Funcionario.Id) {
      this.alterar(data);
    } else {
      this.inserir(data);
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.loadFuncionarioId(this.props.match.params.id);
    } else {
      this.setState({ setDefaultCompetencia: true });
    }
  }

  render() {
    return (
      <>
        <MyNotice title="Cadastro de Funcionário" />
        <Formik
          enableReinitialize={true}
          initialValues={this.state}
          validationSchema={schema}
          onSubmit={this.handleSubmit}
        >
          {({
            errors,
            values,
            touched,
            isValid,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <Row>
              <Col md={12}>
                <Portlet fluidHeight={true}>
                  <PortletHeader title="Dados Pessoais" />
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        <Card border="primary">
                          <Card.Header>Informações</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={3}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={values.Funcionario.Nome}
                                  name="Funcionario.Nome"
                                  onChange={handleChange}
                                  isInvalid={
                                    getIn(errors, "Funcionario.Nome") &&
                                    getIn(touched, "Funcionario.Nome")
                                  }
                                  placeholder="Digite o nome do funcionário"
                                />
                                <ErrorMessage name="Funcionario.Nome" />
                              </Col>
                              <Col md={!!values.Funcionario.Id ? 2 : 3}>
                                <Form.Label>CPF</Form.Label>
                                <MaskedInput
                                  type="text"
                                  className="form-control"
                                  disabled={!!this.props.match.params.id}
                                  value={values.Funcionario.Cpf}
                                  name="Funcionario.Cpf"
                                  onChange={handleChange}
                                  mask={[
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    ".",
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "-",
                                    /\d/,
                                    /\d/,
                                  ]}
                                  placeholder="Digite o CPF do funcionário"
                                />
                                <ErrorMessage name="Funcionario.Cpf" />
                              </Col>
                              <Col md={3}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={values.Funcionario.Email}
                                  disabled={!!this.props.match.params.id}
                                  name="Funcionario.Email"
                                  onChange={handleChange}
                                  isInvalid={
                                    getIn(errors, "Funcionario.Email") &&
                                    getIn(touched, "Funcionario.Email")
                                  }
                                  placeholder="Digite o Email do funcionário"
                                />
                                <ErrorMessage name="Funcionario.Email" />
                              </Col>
                              <Col md={!!values.Funcionario.Id ? 2 : 3}>
                                <Form.Label>Pefil</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="Perfil"
                                  onChange={handleChange}
                                  value={values.Perfil}
                                  isInvalid={
                                    getIn(errors, "Perfil") &&
                                    getIn(touched, "Perfil")
                                  }
                                >
                                  <option key={0}>Selecione</option>
                                  {PERFIL.map((v, i) => (
                                    <option key={i} value={v.id}>
                                      {v.valor}
                                    </option>
                                  ))}
                                </Form.Control>
                                <ErrorMessage name="Perfil" />
                              </Col>
                              {!!values.Funcionario.Id && (
                                <Col md={2}>
                                  <Form.Label>Situação</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="Funcionario.Situacao"
                                    onChange={handleChange}
                                    value={values.Funcionario.Situacao}
                                  >
                                    {SITUACOES.map((v, i) => (
                                      <option key={i} value={v.id}>
                                        {v.valor}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  <ErrorMessage name="Funcionario.Situacao" />
                                </Col>
                              )}
                            </Row>
                          </Card.Body>
                        </Card>

                        <div className="kt-separator kt-separator--dashed"></div>
                        <CardCentroCusto
                          values={{
                            CompetenciaId: values.CompetenciaId,
                            UnidadeOrcamentariaId: values.UnidadeOrcamentariaId,
                            CentroCustoId: values.CentroCustoId,
                            setDefaultCompetencia: values.setDefaultCompetencia,
                          }}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </PortletBody>
                  <PortletFooter style={{ textAlign: "right" }}>
                    <Link
                      to="/orcamento/funcionario"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      style={{ marginLeft: 10 }}
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </PortletFooter>
                </Portlet>
              </Col>
            </Row>
          )}
        </Formik>
      </>
    );
  }
}
