import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";

import { Link } from "react-router-dom";

import { Row, Col, Table, Badge } from "react-bootstrap";
import { getValorTipoPlanoConta } from "../../../enums/planoContaOrcamentarioEnum";
import { getValorCategoriaPlanoConta } from "../../../enums/planoContaOrcamentarioEnum";

export default function GridPlanoContaOrcamentario({
  planoContaOrcamentarios,
  handleEditar,
  handleExcluir,
}) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem do Plano de Contas Orçamentário" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive="sm">
                  <thead>
                    <tr>
                      <th>Competência</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Tipo</th>
                      <th>Categoria</th>
                      <th>Situação</th>
                      <th style={{ width: 10 }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(planoContaOrcamentarios &&
                      planoContaOrcamentarios.length > 0 &&
                      planoContaOrcamentarios.map(
                        (planoContaOrcamentario, idx) => (
                          <tr key={`${planoContaOrcamentario.Id}`}>
                            <td>{planoContaOrcamentario.Competencia.Ano}</td>
                            <td>{planoContaOrcamentario.Codigo}</td>
                            <td>{planoContaOrcamentario.Nome}</td>
                            <td>
                              {getValorTipoPlanoConta(
                                planoContaOrcamentario.Tipo
                              )}
                            </td>
                            <td>
                              {getValorCategoriaPlanoConta(
                                planoContaOrcamentario.Categoria
                              )}
                            </td>
                            <td>
                              {planoContaOrcamentario.Situacao ? (
                                <Badge variant="success">Ativo</Badge>
                              ) : (
                                <Badge variant="danger">Inativo</Badge>
                              )}
                            </td>
                            <td className="align-middle">
                              <Link
                                to={`/orcamento/plano-conta-orcamentario/editar/${planoContaOrcamentario.Id}`}
                                className="btn btn-clean btn-md btn-icon"
                                title="Editar"
                              >
                                <i className="la la-edit" />
                              </Link>
                            </td>
                          </tr>
                        )
                      )) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
