import React, { useState, useEffect } from "react";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import { Formik, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";
import { TIPOS_COMPETENCIA } from "../../../enums/competenciaEnum";
import { SITUACOES_COMPETENCIA } from "../../../enums/competenciaEnum";
import { SITUACAO_COMPETENCIA_CONSOLIDADO } from "../../../enums/competenciaEnum";
import { Link } from "react-router-dom";

import NumberFormat from "react-number-format";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MyNotice from "../../../partials/layout/MyNotice";
import { Money } from "../../../helpers/helper";

const schema = Yup.object().shape({
  Ano: Yup.string()
    .matches(/^[0-9]*$/i, "Competência inválida, utilize apenas números.")
    .required("Campo obrigatório"),
  Tipo: Yup.string().required("Campo obrigatório"),
  Situacao: Yup.string().required("Campo obrigatório"),
  DataInicial: Yup.date().required("Campo obrigatório"),
  DataFinal: Yup.date().required("Campo obrigatório"),
});

export default function FormCompetencia(props) {
  const [competencia, setCompetencia] = useState({
    Id: "",
    Ano: "",
    Tipo: "",
    Situacao: "",
    DataInicial: "",
    DataFinal: "",
    ValorMobilizacao: "",
    Vigente: ""
  });
  const [criarTodaEstruturaInicial, setCriarTodaEstruturaInicial] = useState(false);

  useEffect(() => {
    //Busca a competencia em caso de edição

    if (props.match.params.id) {
      BaseCrud.getById("Competencia", props.match.params.id, true).then(
        (response) => {
          let resolveData = {
            Ano: response.data.Ano,
            Tipo: response.data.Tipo,
            Id: response.data.Id,
            Situacao: response.data.Situacao,
            Vigente: response.data.Vigente,
            ValorMobilizacao: response.data.ValorMobilizacao
              ? Money.toPtBr(response.data.ValorMobilizacao)
              : null,
            DataInicial: new Date(response.data.DataInicial),
            DataFinal: new Date(response.data.DataFinal),
          };

          setCompetencia(resolveData);
        }
      );
    }
  }, []);

  const handleSubmit = function(v) {
    sanitizeForm(v);

    if (
      v.Situacao == SITUACAO_COMPETENCIA_CONSOLIDADO &&
      v.ValorMobilizacao === null
    ) {
      toast.warn(
        "Para a situação Consolidado, o valor da Mobilização financeira é obrigatório."
      );
      return false;
    }

    (!!v.Id
      ? editar(v)
      : incluir({
          Ano: v.Ano,
          Tipo: v.Tipo,
          Situacao: v.Situacao,
          DataInicial: v.DataInicial,
          DataFinal: v.DataFinal,
          ValorMobilizacao: v.ValorMobilizacao,
          CriarTodaEstruturaInicial: criarTodaEstruturaInicial
        })
    )
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/competencia");
      })
      .catch((e) => e);
  };



  const onSwitchCriarTodaEstruturaInicial = () => {   
    setCriarTodaEstruturaInicial(!criarTodaEstruturaInicial);
  };
  

  const editar = async (v) => BaseCrud.put("Competencia", v.Id, v);

  const incluir = async (v) => BaseCrud.post("Competencia", v);

  const sanitizeForm = (v) => {
    v.ValorMobilizacao = !!v.ValorMobilizacao
      ? Money.toDecimal(v.ValorMobilizacao)
      : null;
  };

  return (
    <>
      <MyNotice title="Cadastro de Competência" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={competencia}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              values,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={
                    !!values.id
                      ? "Edição de Competência"
                      : "Cadastro de Competência"
                  }
                />
                <Form>
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              type="text"
                              name="Ano"
                              placeholder="Informe a Competência"
                              value={values.Ano}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Ano") && getIn(touched, "Ano")
                              }
                            />
                            <ErrorMessage name="Ano" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                              as="select"
                              name="Tipo"
                              onChange={handleChange}
                              value={values.Tipo}
                              isInvalid={
                                getIn(errors, "Tipo") && getIn(touched, "Tipo")
                              }
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {TIPOS_COMPETENCIA.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="Tipo" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Data Inicial</Form.Label>
                            <DatePicker
                              selected={values.DataInicial}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              autoComplete="off"
                              name="DataInicial"
                              onChange={(date) =>
                                setFieldValue("DataInicial", date)
                              }
                            />
                            <ErrorMessage name="DataInicial" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Data Final</Form.Label>
                            <DatePicker
                              selected={values.DataFinal}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              autoComplete="off"
                              name="DataFinal"
                              onChange={(date) =>
                                setFieldValue("DataFinal", date)
                              }
                            />
                            <ErrorMessage name="DataFinal" />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={3}>
                            <Form.Label>Situação</Form.Label>
                            <Form.Control
                              as="select"
                              name="Situacao"
                              onChange={handleChange}
                              value={values.Situacao}
                            >
                              <option key={0} value="">
                                Selecione
                              </option>
                              {SITUACOES_COMPETENCIA.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="Situacao" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>Mobilização Financeira (+)</Form.Label>
                            <NumberFormat
                              displayType={"input"}
                              type="text"
                              name="ValorMobilizacao"
                              className="form-control"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={values.ValorMobilizacao}
                              placeholder="R$"
                              onChange={handleChange}
                            />
                            <ErrorMessage name="ValorMobilizacao" />
                          </Col>
                          <Col md={3}>
                          <Form.Switch
                            onChange={onSwitchCriarTodaEstruturaInicial}
                            disabled={true}
                            id="switch-grupo-plano-conta"
                            label="Criar toda estrutura da competência INICIAL"             
                          />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </PortletBody>
                  <PortletFooter className="text-right">
                    <Link
                      to="/orcamento/competencia"
                      variant="secondary"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </PortletFooter>
                </Form>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
