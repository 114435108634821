import React from "react";
import { toast } from "react-toastify";

import FilterCompetencia from "./FilterCompetencia";
import GridCompetencia from "./GridCompetencia";
import BaseCrud from "../../../crud/base.crud";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

import MyNotice from "../../../partials/layout/MyNotice";

export default class IndexIndexCompetencia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      competencias: [],
      //Filtro Paginação
      filter: "",
      page: 0,
      totalItems: 0,
    };

    this.getCompetencias = this.getCompetencias.bind(this);

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    this.getCompetencias();
  }

  handleFilter(v) {
    let filter = "";

    //Filtros necessários para a página
    filter += !!v.Ano ? `Ano eq ${v.Ano} and ` : "";
    filter += !!v.Tipo ? `Tipo eq '${v.Tipo}' and ` : "";
    filter += !!v.Situacao ? `Situacao eq '${v.Situacao}' and ` : "";
    filter = filter.slice(0, -4);

    this.setState({ filter: filter });
    this.getCompetencias();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `$top=${PER_PAGE}&$skip=${skip}&${
      !!this.state.filter ? "&$filter=" + this.state.filter : ""
    }&$orderBy=Ano desc`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getCompetencias();
    });
  }

  getCompetencias() {
    BaseCrud.get(`Competencia?$count=true&${this.getEndpointComplement()}`)
      .then((response) => {
        this.setState({
          competencias: response.data.value,
          totalItems: response.data["@odata.count"],
        });
      })
      .catch((e) => {});
  }

  render() {
    return (
      <>
        <MyNotice title="Cadastro de Competência" />

        <FilterCompetencia
          handleFilter={this.handleFilter}
          filter={this.state.filter}
        />
        <GridCompetencia competencias={this.state.competencias} />

        <DefaultPagination
          items={this.state.competencias}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
