import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, getIn } from "formik";
import * as Yup from "yup";

//components
import MyNotice from "../../../partials/layout/MyNotice";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter
} from "../../../partials/content/Portlet";

// Helper
import { GetMimeTypeByExtension } from "../../../../_metronic/utils/utils";

//services
import JasperCrud from "../../../crud/jasper.crud";

function IndexRelatorios(props) {
  const { title } = props.match.params;
  const uri = props.location.search.replace("?uri=", "");

  const [inputsControl, setInputscontrol] = useState([]);
  const [schema, setSchema] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    // Get Input Controls By URI
    getInputControlsByReportUri(uri, "").then(response => {
      setInputscontrol(response.data.inputControl);
    });
  }, []);

  useEffect(() => {
    // Verifica se há inputs para esse relatório
    if (inputsControl.length > 0) {
      let contentSchema = {};
      let values = {};
      // Percorre os inputs a fim de criar a validação dos campos obrigatórios e dados iniciais
      inputsControl.map(input => {
        
        // Cria as variaveis iniciais
        if (input.state.options?.length > 0) {
          values[input.id] = input.state.options?.filter( x => x.selected == true)[0].value;
      }
       else {
        values[input.id]  = input.state.value;
      }

        //  Cria validação caso o campo seja obrigatório
        if (input.mandatory) {
          contentSchema[input.id] = Yup.string().required("Campo obrigatório");
        }
      });

      setSchema(Yup.object().shape(contentSchema));
      setInitialValues(values);
    }
  }, [inputsControl]);

  const handleSubmit = function (v) {
    let formData = {
      Path: uri,
      Parameters: v
    };

    let extension = v.cmbFormato ? v.cmbFormato : null;

    getReport(formData).then(response => {
      generateReport(response.data, extension);
    });
  };

  const handleChangeCompetencia = function (select) {
    if(select.name == 'Competencia') {
      getInputControlsByReportUri(uri, select.value).then(response => {
        setInputscontrol(response.data.inputControl);
      });
    }
  }

  const generateReport = function (base64, extension) {
    // Cria o array de bytes
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    let mimeType = GetMimeTypeByExtension(extension);

    //Cria o blob com o array de bytes
    let blob = new Blob([bytes], { type: mimeType });
    let url = URL.createObjectURL(blob);
    //Abre o arquivo
    window.open(url);
  };

  // services request
  const getInputControlsByReportUri = function (reportUri, competencia) {
    return JasperCrud.getInputControlsByReportUri(reportUri, competencia);
  };

  const getReport = function (data) {
    return JasperCrud.getReport(data);
  };

  const [state, setState] = React.useState({
    fieldValue: ""
  })

  const handleFieldChange = (e) => {
    setState(e.value); 
  };

  return (
    <>
      <MyNotice title={title} />
      {schema && (
        <Row>
          <Col md={12}>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue
              }) => (
                  <Portlet fluidHeight={true}>
                    <PortletHeader title={"Selecione os Campos"} />
                    {inputsControl.length > 0 && (
                      <Form>
                        {inputsControl.filter(x => x.visible === false).map(input => {
                          return (<Form.Control
                            as="hidden"
                            name={input.state.id}
                            onChange={handleChange}
                            value={values[input.id]}
                          />);
                        })}

                        <PortletBody>
                          <div className="kt-widget12">
                            <div className="kt-widget12__content">
                              <Row className="mt-3">
                                {inputsControl.filter(x => x.visible).map(input => {
                                  return (
                                    <Col md={3} key={input.id}>
                                      <Form.Label>{input.label}</Form.Label>
                                      {input.type == "singleSelect" && (
                                        <Form.Control
                                          as="select"
                                          name={input.state.id}
                                          onChange={(e) => {
                                            handleChange(e);
                                            handleChangeCompetencia(e.target);
                                          }}
                                          value={values[input.id]}
                                        >
                                          {input.state.options.map((v, i) => (
                                            <option key={i} value={v.value}>
                                              {v.label}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      )}
                                       {
                                      input.type === "singleValueText" && (
                                        <Form.Control 
                                        name={input.state.id}
                                        type="text"
                                        value={values[input.id]}
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleFieldChange(e.target);
                                        }}
                                           
                                        />
                                      )
                                    }
                                      <ErrorMessage name={input.id} />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>
                          </div>
                        </PortletBody>
                        <PortletFooter className="text-right">
                          <Link
                            to="/relatorios"
                            variant="secondary"
                            className="btn btn-secondary"
                          >
                            Voltar
                        </Link>
                          <Button
                            variant="primary"
                            className="ml-2"
                            onClick={handleSubmit}
                          >
                            Visualizar
                        </Button>
                        </PortletFooter>
                      </Form>
                    )}
                  </Portlet>
                )}
            </Formik>
          </Col>
        </Row>
      )}
    </>
  );
}

export default IndexRelatorios;
