export const TIPO_PROJETO_FINALISTICO = 1;
export const TIPO_PROJETO_SISTEMATICO = 2;

export const TEXTO_TIPO_PROJETO_FINALISTICO = "Área Finalística";
export const TEXTO_TIPO_PROJETO_SISTEMATICO = "Área Administrativa";

export const TIPOS_PROJETO = [
  { id: TIPO_PROJETO_SISTEMATICO, valor: TEXTO_TIPO_PROJETO_FINALISTICO },
  { id: TIPO_PROJETO_FINALISTICO, valor: TEXTO_TIPO_PROJETO_SISTEMATICO },
];

export const getDescriptionTipoProjeto = (id) => {
  let tipoProjeto = TIPOS_PROJETO.filter((f) => f.id == id);

  if (tipoProjeto && tipoProjeto.length > 0) {
    return tipoProjeto[0].valor;
  }

  return "";
};
