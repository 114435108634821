import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";
import { toast } from "react-toastify";
import MyNotice from "../../../partials/layout/MyNotice";
import CardCompetencia from "./CardCompetencia";
import CardEstruturaOrcamentaria from "./CardEstruturaOrcamentaria";
import { Formik } from "formik";
import CardProjeto from "./CardProjeto";
import CardPlanoContaOrcamentario from "./CardPlanoContaOrcamentario";

//--
import BaseCrud from "../../../crud/base.crud";
import OrcamentoCrud from "../../../crud/orcamento.crud";

export default class FormGerarOrcamento extends React.Component {
  constructor(props) {
    super();

    this.state = {
      orcamento: {
        CompetenciaId: "",
        UnidadeOrcamentariaId: "",
        CentroCustoId: "",
        EstruturaOrcamentaria: {},
        Projeto: "",
        PlanoContaOrcamentario: [],
        stringifySetteds: '""',
      },
      classificacaoOrcamentaria: { Nome: "Aguarde, carregando..." },
      projetos: [],
    };

    this.handleChangeProjetos = this.handleChangeProjetos.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadOrcamentoCadastrado = this.loadOrcamentoCadastrado.bind(this);
  }

  getClassificacaoOrcamentaria = async () =>
    BaseCrud.get("ClassificacaoOrcamentaria");

  componentDidMount() {
    this.getClassificacaoOrcamentaria()
      .then((r) => this.setState({ classificacaoOrcamentaria: r.data }))
      .catch((e) => { });
  }

  handleChangeProjetos(v) {
    if (!Array.isArray(v) || v.length <= 0) {
      this.setState({ projetos: [] });
    } else {
      this.setState({
        projetos: v.map(({ Id, Nome }) => ({ value: Id, label: Nome })),
      });
    }
  }

  async loadOrcamentoCadastrado(
    CentroCustoId,
    EstruturaOrcamentaria,
    Projeto,
    setFieldValue
  ) {
    const data = this.filterFormikValues({
      CentroCustoId: CentroCustoId,
      EstruturaOrcamentaria: EstruturaOrcamentaria,
      Projeto: Projeto,
    });

    if (
      !data.CentroCustoId ||
      !data.EstruturaOrcamentariaId ||
      !data.ProjetoId
    ) {
      setFieldValue("PlanoContaOrcamentario", []);
    } else {
      OrcamentoCrud.getPlanoContaOrcamentario(data.CentroCustoId, data.EstruturaOrcamentariaId, data.ProjetoId).then((r) => {
        setFieldValue(
          "PlanoContaOrcamentario",
          r.data.map((e) => e.planoContaOrcamentarioId)
        );
      });
    }
  }

  filterFormikValues = (v) => ({
    CentroCustoId: v.CentroCustoId,
    ProjetoId: v.Projeto.value,
    PlanoContaOrcamentarioId: v.PlanoContaOrcamentario,
    EstruturaOrcamentariaId: Object.values(v.EstruturaOrcamentaria)
      .reverse()
      .filter((e) => !!e)[0],
  });

  handleSubmit(v) {
    const data = this.filterFormikValues(v);

    BaseCrud.post("Orcamento", data)
      .then((r) => {
        toast.success("Operação realizada com sucesso.");
      })
      .catch((e) => e);
  }

  render() {
    return (
      <>
        <MyNotice title="Elaboração do Orçamento" />
        <Row>
          <Col md={12}>
            <Formik
              enableReinitialize={true}
              initialValues={this.state.orcamento}
              onSubmit={this.handleSubmit}
            >
              {({
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => {
                const getStringifySetteds = () => {
                  return JSON.stringify(
                    values.CentroCustoId.toString() +
                    Object.values(values.EstruturaOrcamentaria)
                      .filter((e) => !!e)
                      .toString() +
                    (values.Projeto && values.Projeto.value
                      ? values.Projeto.value.toString()
                      : "")
                  );
                };

                const isNotUndefined = () => {
                  return (
                    values !== undefined &&
                    values.CentroCustoId !== undefined &&
                    values.EstruturaOrcamentaria !== undefined &&
                    values.Projeto !== undefined
                  );
                };

                if (
                  isNotUndefined() &&
                  values.stringifySetteds != getStringifySetteds()
                ) {
                  setFieldValue("stringifySetteds", getStringifySetteds());

                  this.loadOrcamentoCadastrado(
                    values.CentroCustoId,
                    values.EstruturaOrcamentaria,
                    values.Projeto,
                    setFieldValue
                  );
                }

                return (
                  <Portlet fluidHeight={true}>
                    <PortletHeader title="Configurações" />
                    <PortletBody>
                      <div className="kt-widget12">
                        <div className="kt-widget12__content">
                          <CardCompetencia
                            values={{
                              CompetenciaId: values.CompetenciaId,
                              UnidadeOrcamentariaId: values.UnidadeOrcamentariaId,
                              CentroCustoId: values.CentroCustoId,
                            }}
                            handleChange={handleChange}
                          />

                          <div className="kt-separator kt-separator--dashed"></div>

                          <CardEstruturaOrcamentaria
                            classificacaoOrcamentaria={
                              this.state.classificacaoOrcamentaria
                            }
                            values={{
                              CompetenciaId: values.CompetenciaId,
                              EstruturaOrcamentaria: values.EstruturaOrcamentaria,
                            }}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            handleChangeProjetos={this.handleChangeProjetos}
                          />

                          <div className="kt-separator kt-separator--dashed"></div>

                          <CardProjeto
                            projetos={this.state.projetos}
                            values={{ Projeto: values.Projeto }}
                            setFieldValue={setFieldValue}
                          />

                          <div className="kt-separator kt-separator--dashed"></div>

                          <CardPlanoContaOrcamentario
                            values={{
                              CompetenciaId: values.CompetenciaId,
                              PlanoContaOrcamentario: values.PlanoContaOrcamentario,
                            }}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </div>
                    </PortletBody>
                    <PortletFooter className="text-right">
                      <Button variant="primary" onClick={handleSubmit}>
                        Salvar e continuar
                      </Button>
                    </PortletFooter>
                  </Portlet>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </>
    );
  }
}
