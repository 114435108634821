import React from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../../partials/content/Portlet";

import { Link } from "react-router-dom";

import { Row, Col, Table, Badge } from "react-bootstrap";

export default function GridPlanoContaOrcamentario({
  estruturasOrcamentarias,
  handleEditar,
  handleExcluir,
}) {
  return (
    <Row>
      <Col md={12}>
        <Portlet fluidHeight={true}>
          <PortletHeader title="Listagem do Plano de Contas Orçamentário" />
          <PortletBody>
            <div className="kt-widget12">
              <div className="kt-widget12__content">
                <Table striped hover responsive="sm">
                  <thead>
                    <tr>
                      <th>Competência</th>
                      <th>Código</th>
                      <th>Titulo</th>
                      <th>Aceita Valor</th>
                      <th>Situação</th>
                      <th style={{ width: 10 }}>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(estruturasOrcamentarias &&
                      estruturasOrcamentarias.length > 0 &&
                      estruturasOrcamentarias.map(
                        (eo, idx) => (
                          <tr key={`${eo.Id}`}>
                            <td>{eo.Competencia.Ano}</td>
                            <td>{eo.Codigo}</td>
                            <td>{eo.Titulo}</td>
                            <td>
                              {eo.AceitaValor ? (
                                <Badge variant="success">Sim</Badge>
                              ) : (
                                <Badge variant="danger">Não</Badge>
                              )}
                            </td>
                            <td>
                              {eo.Situacao ? (
                                <Badge variant="success">Ativo</Badge>
                              ) : (
                                <Badge variant="danger">Inativo</Badge>
                              )}
                            </td>
                            <td className="align-middle">
                              <Link
                                to={`/orcamento/estrutura-orcamentaria/editar/${eo.Id}`}
                                className="btn btn-clean btn-md btn-icon"
                                title="Editar"
                              >
                                <i className="la la-edit" />
                              </Link>
                            </td>
                          </tr>
                        )
                      )) || (
                      <tr>
                        <td>
                          <p className="text-danger">
                            Nenhum registro encontrado
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </PortletBody>
        </Portlet>
      </Col>
    </Row>
  );
}
