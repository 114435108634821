import React from "react";
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import { redirectLogin, redirectLogout } from "../../../_metronic/utils/utils";
import { toast } from "react-toastify";
import BaseCrud from "../../crud/base.crud";

const Authorization = (props) => {
  const {
    location: { search },
  } = props;
  const { origin } = window.location;
  const paramsBusca = new URLSearchParams(search);
  const redirect = paramsBusca.get("location");
  const code = paramsBusca.get("code");

  (async () => {
    if (code != null && redirect != null) {
      BaseCrud.post("auth", {
        code,
        callback: `${origin}/authorization?location=${redirect}`,
      }).then((r) => {
        if (!!r.data) {
          props.login(r.data);
        } else {
          alert(
            "Não foi possível acessar o sistema, verifique com o administrador se suas permissões de acesso foram definidas corretamente para esta empresa."
          );
          props.logout();
          redirectLogout();
        }
      });
    } else {
      redirectLogin();
    }
  })();

  return <></>;
};

export default connect(null, auth.actions)(Authorization);
