import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik, ErrorMessage, getIn } from "formik";
import CheckboxTree from "react-checkbox-tree";

//enums
import { SITUACOES } from "../../../enums/genericEnum";
import { TIPOS_PROJETO } from "../../../enums/projetoEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

//services
import BaseCrud from "../../../crud/base.crud";

// --
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import {
  flatToHierarchy,
  resolveFlatToHierarchy,
} from "../../../../_metronic/utils/utils";

const schema = Yup.object().shape({
  Nome: Yup.string().required("Campo obrigatório"),
  Tipo: Yup.string().required("Campo obrigatório"),
  Situacao: Yup.bool()
    .typeError("Selecione a situação.")
    .required("Campo obrigatório"),
});

export default function FormProjeto(props) {
  const [competencias, setCompetencias] = useState([]);
  const [classificacaoOrcamentaria, setClassificacaoOrcamentaria] = useState(
    null
  );
  const [estruturaOrcamentaria, setEstruturaOrcamentaria] = useState(null);
  const [competencia, setCompetencia] = useState(null);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [mensagemErro, setMensagemErro] = useState(null);

  const [projeto, setProjeto] = useState({
    Nome: "",
    Situacao: !props.match.params.id ? true : "",
    Tipo: "",
    Orcamentos: [],
    ProjetosEstruturaOrcamentaria: [],
  });

  useEffect(() => {
    //-- Busca as competencias
    BaseCrud.get(
      `competencia?$orderBy=Ano desc${
        !props.match.params.id ? "&$filter=Situacao ne '4'" : ""
      }`,
      true
    ).then((response) => {
      setCompetencias(response.data.value);
    });

    //Busca a classificacao orcamentaria, para saber a empresa deste projeto
    BaseCrud.get("ClassificacaoOrcamentaria").then((response) => {
      setClassificacaoOrcamentaria(response.data);
    });
  }, []);

  useEffect(() => {
    //-- Busca o Projeto em caso de edição
    if (classificacaoOrcamentaria && props.match.params.id) {
      handleGetProjeto(props.match.params.id);
    }
  }, [classificacaoOrcamentaria]);

  useEffect(() => {
    if (
      projeto.ProjetosEstruturaOrcamentaria.length > 0 &&
      props.match.params.id
    ) {
      // Após selecionar o projeto, seta todas as estruturas orcamentarias que já foram escolhidas nesse projeto
      setCheckedNodes(        
        projeto.ProjetosEstruturaOrcamentaria.map(
          (e) => e.EstruturaOrcamentariaId
        )
      );

      // Ja lista a estutura orcamentaria da competencia que este projeto pertence
      getEstruturaOrcamentaria(       
        competencias.filter((x) => x.Vigente == true)[0]?.Id
      );

      // Seta a competencia deste projeto
      setCompetencia(
        competencias.filter((x) => x.Vigente == true)[0]
      );
    }
  }, [projeto]);

  //-- CRUD
  const handleGetProjeto = (id) => {
    getProjeto(id)
      .then(
        (response) => { 
          setProjeto(response.data);}
      )
      .catch((e) => {});
  };

  const getProjeto = async (id) => BaseCrud.getById("Projeto", id);

  const handleSubmit = function(v) {
    (!!v.Id ? editar(v) : incluir(v))
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/projeto");
      })
      .catch((e) => {});
  };

  const editar = async (v) => {
    v.CompetenciaId = competencia.Id;
    return BaseCrud.put("Projeto", v.Id, v);
  } 

  const incluir = async (v) => BaseCrud.post("Projeto", v);
  
  //-- FIM CRUD

  //-- CARD ESTRUTURA ORCARMENTARIA
  const getEstruturaOrcamentaria = async (v) => {
    if (v) {
      BaseCrud.get(
        `EstruturaOrcamentaria?competenciaId=${v}&ClassificacaoOrcamentariaId=${classificacaoOrcamentaria.Id}`
      )
        .then((r) => {
          if (r.data.value.length > 0) {
            setMensagemErro(null);
            let hierarchical = flatToHierarchy(
              r.data.value.map((c) => {
                c.value = c.Id;
                c.label = `${c.Codigo} - ${c.Titulo}`;
                return c;
              })
            );

            let revolveHierarchical = resolveFlatToHierarchy(hierarchical);
            setEstruturaOrcamentaria(revolveHierarchical);
          } else {
            setMensagemErro(
              "Nenhuma estrutura orçamentária, verifique a competência."
            );
            setEstruturaOrcamentaria([]);
          }
        })
        .catch((e) => {});
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={projeto}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={
                    !!values.Id ? "Edição de Projeto" : "Cadastro de Projeto"
                  }
                />
                <Form>
                  <PortletBody>
                    <div className="kt-widget12">
                      <div className="kt-widget12__content">
                        <Card border="primary">
                          <Card.Header>Projeto</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={4}>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="Nome"
                                  placeholder="Digite o nome do projeto"
                                  value={values.Nome}
                                  onChange={handleChange}
                                />
                                <ErrorMessage name="Nome" />
                              </Col>
                              <Col md={4}>
                                <Form.Label>Tipo</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="Tipo"
                                  onChange={handleChange}
                                  value={values.Tipo}
                                >
                                  <option key={0} value="">
                                    Selecione
                                  </option>
                                  {TIPOS_PROJETO.map((v, i) => (
                                    <option key={i} value={v.id}>
                                      {v.valor}
                                    </option>
                                  ))}
                                </Form.Control>
                                <ErrorMessage name="Tipo" />
                              </Col>
                              <Col md={4}>
                                <Form.Label>Situação</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="Situacao"
                                  onChange={handleChange}
                                  value={values.Situacao}
                                  disabled={!values.Id}
                                >
                                  {!values.Id ? (
                                    <option value={true}>Ativo</option>
                                  ) : (
                                    <>
                                      <option key={0} value="">
                                        Selecione
                                      </option>
                                      {SITUACOES.map((v, i) => (
                                        <option key={i} value={v.id}>
                                          {v.valor}
                                        </option>
                                      ))}
                                    </>
                                  )}
                                </Form.Control>
                                <ErrorMessage name="Situacao" />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>

                        <div className="kt-separator kt-separator--dashed"></div>

                        <Card border="primary">
                          <Card.Header>
                            Estrutura Orçamentária do Projeto
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <Form.Label>Competência</Form.Label>
                                {competencia ? (
                                  <Form.Control
                                    plaintext
                                    readOnly
                                    defaultValue={
                                      competencia.Ano + " - " +
                                      getValorTipoCompetencia(competencia.Tipo) + " - " +
                                      getValorSituacaoCompetencia(competencia.Situacao)
                                    }
                                  />
                                ) : (
                                  <Form.Control
                                    as="select"
                                    name="CompetenciaId"
                                    onChange={(v) =>
                                      getEstruturaOrcamentaria(v.target.value)
                                    }
                                  >
                                    <option key={0} value="">
                                      Selecione
                                    </option>
                                    {competencias.map((competencia) => (
                                      <option
                                        key={competencia.Id}
                                        value={competencia.Id}
                                      >
                                        {competencia.Ano} - {" "}
                                        {getValorTipoCompetencia(competencia.Tipo)} - {" "}
                                        {getValorSituacaoCompetencia(competencia.Situacao)}
                                      </option>
                                    ))}
                                  </Form.Control>
                                )}

                                <ErrorMessage name="CompetenciaId" />
                              </Col>
                            </Row>
                            {mensagemErro && (
                              <Row style={{ marginTop: 20 }}>
                                <Col md={12}>{mensagemErro}</Col>
                              </Row>
                            )}
                            {estruturaOrcamentaria &&
                              estruturaOrcamentaria.length > 0 && (
                                <Row style={{ marginTop: 20 }}>
                                  <Col md={12}>
                                    <Form.Label>
                                      Selecione a(s) Estrutura(s) para o Projeto
                                    </Form.Label>
                                    <CheckboxTree
                                      nodes={estruturaOrcamentaria}
                                      checked={checkedNodes}
                                      expanded={expandedNodes}
                                      onCheck={(checked) => {
                                        let resolveChecked = [];
                                        if (checked.length > 0) {
                                          // RESETA AS UNIDADES ORCAMENTARIA DO PROJETO
                                          setFieldValue(
                                            "ProjetosEstruturaOrcamentaria",
                                            []
                                          );

                                          // VINCULA AS UNIDADES ORCAMENTARIAS DO PROJETO
                                          checked.map((eo, index) => {
                                            resolveChecked[index] = {
                                              EstruturaOrcamentariaId: eo,
                                            };
                                          });
                                        }
                                        setCheckedNodes(checked);
                                        setFieldValue(
                                          "ProjetosEstruturaOrcamentaria",
                                          resolveChecked
                                        );
                                      }}
                                      onExpand={(expanded) =>
                                        setExpandedNodes(expanded)
                                      }
                                      //--
                                      iconsClass="fa5"
                                      icons={{
                                        check: (
                                          <span className="rct-icon rct-icon-check" />
                                        ),
                                        uncheck: (
                                          <span className="rct-icon rct-icon-uncheck" />
                                        ),
                                        halfCheck: (
                                          <span className="rct-icon rct-icon-half-check" />
                                        ),
                                        expandClose: (
                                          <span className="rct-icon rct-icon-expand-close" />
                                        ),
                                        expandOpen: (
                                          <span className="rct-icon rct-icon-expand-open" />
                                        ),
                                        expandAll: (
                                          <span className="rct-icon rct-icon-expand-all" />
                                        ),
                                        collapseAll: (
                                          <span className="rct-icon rct-icon-collapse-all" />
                                        ),
                                        parentClose: null,
                                        parentOpen: null,
                                        leaf: null,
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )}
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </PortletBody>
                  <PortletFooter style={{ textAlign: "right" }}>
                    <Link
                      to="/orcamento/projeto"
                      variant="secondary"
                      className="btn btn-secondary"
                    >
                      Voltar
                    </Link>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={handleSubmit}
                    >
                      Salvar
                    </Button>
                  </PortletFooter>
                </Form>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
