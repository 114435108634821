import React from "react";
import { Route, Switch } from "react-router-dom";
import FormGerarOrcamento from "./FormGerarOrcamento";

export default function GerarOrcamentoPage() {
  return (
    <Switch>
      <Route
        path="/orcamento/gerar-orcamento/novo"
        exact={true}
        component={FormGerarOrcamento}
      />
    </Switch>
  );
}
