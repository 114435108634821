import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Card, Accordion, Nav, AccordionCollapse } from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import MyNotice from "../../../partials/layout/MyNotice";

//services
import BaseCrud from "../../../crud/base.crud";
import OrcamentoCrud from "../../../crud/orcamento.crud";
import CentroCustoCrud from "../../../crud/centroCusto.crud";
import ProjetoCrud from "../../../crud/projeto.crud";

//ENUM
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

function Cadastro(props) {
  const [competencias, setCompetencias] = useState();
  const [competenciaId, setCompetenciaId] = useState();
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [unidadesOrcamentarias, setUnidadesOrcamentarias] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [lancamentos, setLancamentos] = useState([]);
  const [projetoId, setProjetoId] = useState(null);
  const [centroCustoId, setCentroCustoId] = useState(null);
  const [mensagemErro, setMensagemErro] = useState(null);

  useEffect(() => {
    getCompetencias();
  }, []);

  useEffect(() => {
    if (projetoId) getLancamentos();
  }, [projetoId]);

  useEffect(() => {
    if (centrosCusto.length == 1) {
      getProjetos(centrosCusto[0].Id);
    }
  }, [centrosCusto]);

  function getCompetencias() {
    setProjetos([]);
    setLancamentos([]);
    setCentrosCusto([]);
    setUnidadesOrcamentarias([]);
    setCompetencias([]);
    BaseCrud.get("Competencia?$count=true&$orderBy=Ano desc", true).then(
      (response) => {
        if (response.data.value && response.data.value.length) {
          setCompetencias(response.data.value);
        }
      }
    );
  }

  function getUnidadesOrcamentarias(v) {
    setProjetos([]);
    setLancamentos([]);
    setCentrosCusto([]);
    setUnidadesOrcamentarias([]);
    if (v) {
      setCompetenciaId(v);
      BaseCrud.get(
        `UnidadeOrcamentaria?$count=true&$filter=CompetenciaId eq ${v}`
      ).then((response) => {
        setUnidadesOrcamentarias(response.data.value);
      });
    }
  }

  function getCentrosCusto(v) {
    setProjetos([]);
    setLancamentos([]);
    setCentrosCusto([]);
    if (v) {
      CentroCustoCrud.get(
        `CentroCusto?competenciaId=${competenciaId}&$count=true&$filter=UnidadeOrcamentariaId eq ${v}`
      ).then((response) => {
        if (response.data.value) {
          setMensagemErro(null);
          setCentrosCusto(response.data.value);
        } else {
          setMensagemErro("Nenhum centro de custo encontrado.");
          setCentrosCusto([]);
        }
      });
    }
  }

  function getProjetos(v) {
    setProjetos([]);
    setLancamentos([]);
    setCentroCustoId(null);
    if (v) {
      setCentroCustoId(v);
      ProjetoCrud.GetByCentroCusto(v).then((response) => {
        setProjetos(response.data);
      });
    }
  }

  function getLancamentos(v) {
    setLancamentos([]);
    OrcamentoCrud.getCardReferencial(projetoId, centroCustoId, competenciaId).then(
      (response) => {
        setLancamentos(response.data);
      }
    );
  }

  return (
    <>
      <MyNotice title="Acompanhamento dos Lançamentos" />

      {competencias && (
        <Row>
          <Col md={12}>
            <Portlet fluidHeight={true}>
              {/* FORM */}
              <Formik
                enableReinitialize={true}
                initialValues={{
                  centrosCusto: centrosCusto,
                  projetos: projetos,
                  lancamentos: lancamentos,
                }}
              >
                {({ values, handleChange }) => (
                  <>
                    <PortletBody>
                      <div className="kt-widget12">
                        <div className="kt-widget12__content">
                          <Card border="primary">
                            <Card.Header>
                              Identificação do Orçamento
                            </Card.Header>
                            <Card.Body>
                              <Form.Group
                                style={{ marginBottom: "1rem" }}
                                as={Row}
                              >
                                <Form.Label column sm="2">
                                  Competência
                                </Form.Label>
                                <Col sm="10">
                                  <Form.Control
                                    as="select"
                                    name="Competencia"
                                    onChange={(v) =>
                                      getUnidadesOrcamentarias(v.target.value)
                                    }
                                  >
                                    <option value="">Selecione</option>
                                    {competencias.map((competencia) => (
                                      <option key={competencia.Id} value={competencia.Id}>
                                        {competencia.Ano + " - " +
                                          getValorTipoCompetencia(competencia.Tipo) + " - " +
                                          getValorSituacaoCompetencia(competencia.Situacao)}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                              </Form.Group>

                              {unidadesOrcamentarias.length > 0 && (
                                <>
                                  <Form.Group
                                    style={{ marginBottom: "1rem" }}
                                    as={Row}
                                  >
                                    <Form.Label column sm="2">
                                      Unidade Orçamentária
                                    </Form.Label>
                                    <Col sm="10">
                                      <Form.Control
                                        as="select"
                                        name="UnidadeOrcamentaria"
                                        onChange={(v) =>
                                          getCentrosCusto(v.target.value)
                                        }
                                      >
                                        <option value="">Selecione</option>
                                        {unidadesOrcamentarias.map((uo) => (
                                          <option key={uo.Id} value={uo.Id}>
                                            {uo.Codigo + " - " + uo.Nome}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Col>
                                  </Form.Group>

                                  {values.centrosCusto.length > 0 && (
                                    <>
                                      <Form.Group
                                        as={Row}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <Form.Label column md="2">
                                          Centro de Custo (Modelo A)
                                        </Form.Label>
                                        <Col sm="10">
                                          {values.centrosCusto.length > 1 ? (
                                            <Form.Control
                                              as="select"
                                              name="CentroCusto"
                                              onChange={(v) =>
                                                getProjetos(v.target.value)
                                              }
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              {values.centrosCusto.map(
                                                (centroCusto) => (
                                                  <option
                                                    key={centroCusto.Id}
                                                    value={centroCusto.Id}
                                                  >
                                                    {centroCusto.Codigo +
                                                      " - " +
                                                      centroCusto.Nome}
                                                  </option>
                                                )
                                              )}
                                            </Form.Control>
                                          ) : (
                                              <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                  values.centrosCusto[0].Codigo +
                                                  " - " +
                                                  values.centrosCusto[0].Nome
                                                }
                                              />
                                            )}
                                        </Col>
                                      </Form.Group>

                                      {values.projetos.length > 0 && (
                                        <Form.Group
                                          as={Row}
                                          style={{ marginBottom: "1rem" }}
                                        >
                                          <Form.Label column md="2">
                                            Projeto
                                          </Form.Label>
                                          <Col sm="10">
                                            <Form.Control
                                              as="select"
                                              name="Projeto"
                                              onChange={(v) =>
                                                setProjetoId(v.target.value)
                                              }
                                            >
                                              <option value="">
                                                Selecione
                                              </option>
                                              {values.projetos.map(
                                                (projeto) => (
                                                  <option
                                                    key={projeto.id}
                                                    value={projeto.id}
                                                  >
                                                    {projeto.nome}
                                                  </option>
                                                )
                                              )}
                                            </Form.Control>
                                          </Col>
                                        </Form.Group>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {/* /// */}

                              {mensagemErro && (
                                <Row style={{ marginTop: 20 }}>
                                  <Col md={12}>{mensagemErro}</Col>
                                </Row>
                              )}
                            </Card.Body>
                          </Card>

                          {lancamentos.length > 0 && (
                            <>
                              <div className="kt-separator kt-separator--dashed"></div>
                              <Card border="primary">
                                <Card.Header>
                                  Referencial dos Programas Sociais / Plano
                                  Orçamentário
                                  </Card.Header>
                                <Card.Body>
                                  <Accordion>
                                    {values.lancamentos.map(
                                      (lancamento, indexLancamento) => (
                                        <Card
                                          key={
                                            lancamento.estruturaOrcamentariaId
                                          }
                                        >

                                          <Card.Header>
                                            <Accordion.Toggle
                                              as={Nav.Link}
                                              variant="link"
                                              eventKey={
                                                lancamento.estruturaOrcamentariaId
                                              }
                                            >
                                              <i
                                                className="la la-check-square"
                                                style={{
                                                  color: "green",
                                                  fontSize: 14,
                                                  padding: 6,
                                                }}
                                              />
                                              {lancamento.arvoreEstruturaOrcamentaria.map(
                                                (filho, index) => {
                                                  if (
                                                    index <
                                                    lancamento
                                                      .arvoreEstruturaOrcamentaria
                                                      .length -
                                                    1
                                                  ) {
                                                    return (
                                                      filho.codigo + " - " + filho.titulo + " / "
                                                    );
                                                  } else {
                                                    return filho.codigo + " - " + filho.titulo;
                                                  }
                                                }
                                              )}
                                            </Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse
                                            eventKey={
                                              lancamento.estruturaOrcamentariaId
                                            }
                                          >
                                            <Card.Body>
                                              <Accordion>
                                                <Card>
                                                  {(lancamento.orcamentos.filter(f => f.planoContaOrcamentario.categoria == 2).length > 0) && (
                                                    <>
                                                      <Card.Header>
                                                        <Accordion.Toggle
                                                          as={Nav.Link}
                                                          variant="link"
                                                          eventKey={
                                                            0
                                                          }>Receitas</Accordion.Toggle>
                                                      </Card.Header>
                                                      <AccordionCollapse eventKey={0}>
                                                        <Card.Body>
                                                          {lancamento.orcamentos.map(
                                                            (
                                                              orcamento,
                                                              indexOrcamento
                                                            ) => ((orcamento
                                                              .planoContaOrcamentario
                                                              .categoria == 2) &&
                                                              <Row
                                                                style={{
                                                                  marginBottom: 10
                                                                }}
                                                                key={orcamento.id}
                                                              >
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={3}
                                                                  xl={3}
                                                                >
                                                                  <Form.Label>
                                                                    {orcamento
                                                                      .planoContaOrcamentario
                                                                      .categoria == 1 ? (
                                                                        <span
                                                                          style={{
                                                                            fontSize: 10,
                                                                            color: "red"
                                                                          }}
                                                                        >
                                                                          Despesa
                                                                        </span>
                                                                      ) : (
                                                                        <span
                                                                          style={{
                                                                            fontSize: 10,
                                                                            color: "green"
                                                                          }}
                                                                        >
                                                                          Receita
                                                                        </span>
                                                                      )}
                                                                  </Form.Label>
                                                                  <Form.Text style={{ fontSize: '100%' }}>
                                                                    {
                                                                      orcamento
                                                                        .planoContaOrcamentario
                                                                        .codigo +
                                                                      " - " +
                                                                      orcamento
                                                                        .planoContaOrcamentario
                                                                        .nome
                                                                    }
                                                                  </Form.Text>
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={2}
                                                                  xl={1}
                                                                >
                                                                  <Form.Label>
                                                                    Valor R${" "}
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.valor ?
                                                                        "R$ " +
                                                                        orcamento.valor.toLocaleString(
                                                                          "pt-br", {
                                                                          minimumFractionDigits: 2,
                                                                        }
                                                                        ) :
                                                                        "--"
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={3}
                                                                  xl={2}
                                                                >
                                                                  <Form.Label>
                                                                    Justificativa
                                                                    </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.justificativa
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={4}
                                                                  xl={3}
                                                                >
                                                                  <Form.Label>
                                                                    Memória de Calculo
                                                                    </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.memoriaCalculo
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={{
                                                                    span: 9,
                                                                    offset: 3
                                                                  }}
                                                                  xl={{
                                                                    span: 3,
                                                                    offset: 0
                                                                  }}
                                                                >
                                                                  <Form.Label>
                                                                    Observação
                                                              </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.observacao
                                                                    }
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              )
                                                          )}
                                                        </Card.Body>
                                                      </AccordionCollapse>
                                                    </>
                                                  )}
                                                  {(lancamento.orcamentos.filter(f => f.planoContaOrcamentario.categoria == 1).length > 0) && (
                                                    <>
                                                      <Card.Header>
                                                        <Accordion.Toggle
                                                          as={Nav.Link}
                                                          variant="link"
                                                          eventKey={
                                                            1
                                                          }>Despesas</Accordion.Toggle>
                                                      </Card.Header>
                                                      <AccordionCollapse eventKey={1}>
                                                        <Card.Body>
                                                          {lancamento.orcamentos.map(
                                                            (
                                                              orcamento,
                                                              indexOrcamento
                                                            ) => ((orcamento
                                                              .planoContaOrcamentario
                                                              .categoria == 1) &&
                                                              <Row
                                                                style={{
                                                                  marginBottom: 10
                                                                }}
                                                                key={orcamento.id}
                                                              >
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={3}
                                                                  xl={3}
                                                                >
                                                                  <Form.Label>
                                                                    {orcamento
                                                                      .planoContaOrcamentario
                                                                      .categoria == 1 ? (
                                                                        <span
                                                                          style={{
                                                                            fontSize: 10,
                                                                            color: "red"
                                                                          }}
                                                                        >
                                                                          Despesa
                                                                        </span>
                                                                      ) : (
                                                                        <span
                                                                          style={{
                                                                            fontSize: 10,
                                                                            color: "green"
                                                                          }}
                                                                        >
                                                                          Receita
                                                                        </span>
                                                                      )}
                                                                  </Form.Label>
                                                                  <Form.Text style={{ fontSize: '100%' }}>
                                                                    {
                                                                      orcamento
                                                                        .planoContaOrcamentario
                                                                        .codigo +
                                                                      " - " +
                                                                      orcamento
                                                                        .planoContaOrcamentario
                                                                        .nome
                                                                    }
                                                                  </Form.Text>
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={2}
                                                                  xl={1}
                                                                >
                                                                  <Form.Label>
                                                                    Valor R${" "}
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.valor ?
                                                                        "R$ " +
                                                                        orcamento.valor.toLocaleString(
                                                                          "pt-br", {
                                                                          minimumFractionDigits: 2,
                                                                        }
                                                                        ) :
                                                                        "--"
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={3}
                                                                  xl={2}
                                                                >
                                                                  <Form.Label>
                                                                    Justificativa
                                                                    </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.justificativa
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={4}
                                                                  xl={3}
                                                                >
                                                                  <Form.Label>
                                                                    Memória de Calculo
                                                                    </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.memoriaCalculo
                                                                    }
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  xs={12}
                                                                  sm={12}
                                                                  md={12}
                                                                  lg={{
                                                                    span: 9,
                                                                    offset: 3
                                                                  }}
                                                                  xl={{
                                                                    span: 3,
                                                                    offset: 0
                                                                  }}
                                                                >
                                                                  <Form.Label>
                                                                    Observação
                                                                        </Form.Label>
                                                                  <Form.Control
                                                                    plaintext
                                                                    readOnly
                                                                    defaultValue={
                                                                      orcamento.observacao
                                                                    }
                                                                  />
                                                                </Col>
                                                              </Row>
                                                              )
                                                          )}
                                                        </Card.Body>
                                                      </AccordionCollapse>
                                                    </>
                                                  )}
                                                </Card>
                                              </Accordion>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      )
                                    )}
                                  </Accordion>
                                </Card.Body>
                              </Card>
                            </>
                          )}
                        </div>
                      </div>
                    </PortletBody>
                  </>
                )}
              </Formik>

              {/** FIM FORM */}
            </Portlet>
          </Col>
        </Row>
      )}
    </>
  );
}

export default Cadastro;
