import React from "react";
import { toast } from "react-toastify";

import BaseCrud from "../../../crud/base.crud";
import Grid from "./GridFuncionario";
import FilterFuncionario from "./FilterFuncionario";

import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

export default class IndexFuncionario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      funcionarios: [],
      filter: "",
      page: 0,
      totalItems: 0,
    };

    this.getFuncionarios = this.getFuncionarios.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  getFuncionarios() {
    BaseCrud.get(`Funcionario?$count=true&${this.getEndpointComplement()}`)
      .then((response) => {
        this.setState({
          funcionarios: response.data.value,
          totalItems: response.data["@odata.count"],
        });
      })
      .catch((e) => e);
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;
    return `$top=${PER_PAGE}&$skip=${skip}
     ${!!this.state.filter ? "&$filter=" + this.state.filter : ""}
      &$orderBy=Nome`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getFuncionarios();
    });
  }

  handleFilter(v) {
    let filters = [];

    if (!!v.Nome) filters.push(`contains(tolower(Nome), tolower('${v.Nome}'))`);
    if (!!v.Cpf)
      filters.push(`Cpf eq '${v.Cpf.replace(/\./g, "").replace("-", "")}'`);
    if (!!v.Email)
      filters.push(`contains(tolower(Email), tolower('${v.Email}'))`);

    const filter = filters.join(" and ");

    this.setState({ filter: filter });
    this.getFuncionarios();
  }

  componentDidMount() {
    this.getFuncionarios();
  }

  render() {
    return (
      <>
        <FilterFuncionario handleFilter={this.handleFilter} />

        <Grid funcionarios={this.state.funcionarios} />

        <DefaultPagination
          items={this.state.funcionarios}
          totalItems={this.state.totalItems}
          initialPage={0}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
