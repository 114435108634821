//
import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

//
import { LayoutSplashScreen } from "../../../_metronic";

//Pages
import Dashboard from "./Dashboard";
import CentroCustoPage from "./centro-custo/CentroCustoPage";
import UnidadeOrcamentariaPage from "./unidade-orcamentaria/UnidadeOrcamentariaPage";
import CompetenciaPage from "./competencia/CompetenciaPage";
import PlanoContaOrcamentarioPage from "./plano-conta-orcamentario/planoContaOrcamentarioPage";
import EstruturaOrcamentariaPage from "./estrutura-orcamentaria/EstruturaOrcamentariaPage";
import ProjetoPage from "./projeto/ProjetoPage";
import GerarOrcamentoPage from "./gerar-orcamento/GerarOrcamentoPage";
import IndexLancamento from "./lancamento/IndexLancamento";
import LancamentoAdministrator from "./lancamento-administrator/IndexLancamento";
import FuncionarioPage from "./funcionario/FuncionarioPage";
import LogoutPage from "../auth/Logout";

import RelatoriosPage from "./relatorios/RelatoriosPage";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/orcamento/centro-custo" component={CentroCustoPage} />
        <Route
          path="/orcamento/unidade-orcamentaria"
          component={UnidadeOrcamentariaPage}
        />
        <Route path="/orcamento/competencia" component={CompetenciaPage} />

        <Route
          path="/orcamento/plano-conta-orcamentario"
          component={PlanoContaOrcamentarioPage}
        />

        <Route
          path="/orcamento/estrutura-orcamentaria"
          component={EstruturaOrcamentariaPage}
        />

        <Route
          path="/orcamento/gerar-orcamento"
          component={GerarOrcamentoPage}
        />

        <Route path="/orcamento/projeto" component={ProjetoPage} />

        <Route
          path="/lancamento-administrator"
          component={LancamentoAdministrator}
        />

        <Route path="/lancamento/novo" component={IndexLancamento} />

        <Route path="/orcamento/funcionario" component={FuncionarioPage} />

        <Route path="/relatorios" component={RelatoriosPage} />

        <Route path="/logout" component={LogoutPage} />
      </Switch>
    </Suspense>
  );
}
