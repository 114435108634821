/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";

class Brand extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-undef
  }

  render() {
    return (
      <div
        className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
        id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <Link to="">
            <img
              alt="{this.props.empresa}"
              src={this.props.logotipo}
              width="100%"
            />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const {
    auth: {
      empresa: { Logotipo, Nome },
    },
  } = store;

  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true,
    }),
    logotipo: Logotipo,
    empresa: Nome,
  };
};

export default connect(mapStateToProps)(Brand);
