export const PERFIL_ADMIN = 1;
//export const PERFIL_GESTOR = 2;
export const PERFIL_USUARIO = 3;

export const TEXTO_PERFIL_ADMIN = "Administrador";
//export const TEXTO_PERFIL_GESTOR = "Gestor";
export const TEXTO_PERFIL_USUARIO = "Usuário";

export const PERFIL = [
  { id: PERFIL_ADMIN, valor: TEXTO_PERFIL_ADMIN },
  //{ id: PERFIL_GESTOR, valor: TEXTO_PERFIL_GESTOR },
  { id: PERFIL_USUARIO, valor: TEXTO_PERFIL_USUARIO },
];

export const getValorPefil = (id) => {
  let perfil = PERFIL.filter((f) => f.id === id);

  if (perfil && perfil.length > 0) {
    return perfil[0].valor;
  }
  return "";
};
