import React from "react";
import { toast } from "react-toastify";

import BaseCrud from "../../../crud/base.crud";
import FilterPlanoContaOrcamentario from "./FilterPlanoContaOrcamentario";
import GridPlanoContaOrcamentario from "./GridPlanoContaOrcamentario";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

export default class IndexPlanoContaOrcamentario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planoContaOrcamentarios: [],
      //Filtro Paginação
      filter: "",
      page: 0,
      totalItems: 0,
      competenciaId: null,
    };

    this.getCPlanoContaOrcamentarios = this.getPlanoContaOrcamentarios.bind(
      this
    );

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {

      
      this.setState({ competenciaId: response.data.value.filter((x) => x.Vigente == true)[0].Id }, () => {
        this.getPlanoContaOrcamentarios();
      });
    });
  }

  handleFilter(v) {
    let filter = "&$filter=";

    //Filtros necessários para a página
    filter += !!v.Nome
      ? `contains(tolower(Nome), tolower('${v.Nome}')) and `
      : "";
    filter += !!v.Codigo ? `Codigo eq '${v.Codigo}' and ` : "";
    filter += !!v.Situacao ? `Situacao eq ${v.Situacao} and ` : "";
    filter += !!v.Categoria ? `Categoria eq '${v.Categoria}' and ` : "";
    filter += !!v.Tipo ? `Tipo eq '${v.Tipo}' and ` : "";

    if (filter != "&$filter=" && v.CompetenciaId) {
      filter = filter.slice(0, -4) + `&CompetenciaId=${v.CompetenciaId}`;
    } else {
      filter = `&CompetenciaId=${v.CompetenciaId}`;
    }

    this.setState({ filter: filter });
    this.getPlanoContaOrcamentarios();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `$top=${PER_PAGE}&$skip=${skip}&${!!this.state.filter
        ? this.state.filter
        : `&CompetenciaId=${this.state.competenciaId}`
      }&$orderBy=Codigo`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getPlanoContaOrcamentarios();
    });
  }

  getPlanoContaOrcamentarios() {
    if (this.state.competenciaId) {
      BaseCrud.get(
        `PlanoContaOrcamentario?$count=true&${this.getEndpointComplement()}`
      )
        .then((response) => {
          this.setState({
            planoContaOrcamentarios: response.data.value,
            totalItems: response.data["@odata.count"],
          });
        })
        .catch((e) => { });
    }
  }

  render() {
    return (
      <>
        <FilterPlanoContaOrcamentario
          handleFilter={this.handleFilter}
          competenciaId={this.state.competenciaId}
          filter={this.state.filter}
        />
        <GridPlanoContaOrcamentario
          planoContaOrcamentarios={this.state.planoContaOrcamentarios}
        />
        <DefaultPagination
          items={this.state.planoContaOrcamentarios}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
