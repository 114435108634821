import env from "../../environments/environments";
import axios from "axios";
import BaseCrud from "./base.crud";

class CentroCustoCrud extends BaseCrud {
  static async getById(endpoint, id, spinner = true) {
    return axios.get(`${env.quadroMedidas.baseApi}/${endpoint}/${id}`, {
      spinner,
    });
  }

  static async GetCentrosCustoDoFuncionarioLogado(
    competenciaId,
    spinner = true
  ) {
    return axios.get(
      `${env.quadroMedidas.baseApi}/CentroCusto/GetCentrosCustoFuncionarioLogado/${competenciaId}`,
      { spinner }
    );
  }
}

export default CentroCustoCrud;
