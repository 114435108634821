import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import BaseCrud from "../../../crud/base.crud";
import { flatToHierarchy } from "../../../../_metronic/utils/utils";
import CheckboxTree from "react-checkbox-tree";
import { TIPO_PLANOCONTA_SINTETICA } from "../../../enums/planoContaOrcamentarioEnum";

export default function({ values, setFieldValue }) {
  const [planosContaOrcamentario, setPlanosContaOrcamentario] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const GetPlanoContaOrcamentario = async (competenciaId) =>
    BaseCrud.get(
      `PlanoContaOrcamentario?competenciaId=${competenciaId}&$filter=Situacao Eq true`
    );

  const handleGetPlanoContaOrcamentario = (competenciaId) => {
    GetPlanoContaOrcamentario(competenciaId).then((r) => {
      let hierarchical = flatToHierarchy(
        r.data.value.map((c) => {
          c.value = c.Id;
          c.label = `${c.Codigo} - ${c.Nome}`;
          return c;
        })
      );

      setPlanosContaOrcamentario(hierarchical);
    });
  };

  useEffect(() => {
    if (!!values.CompetenciaId) {
      handleGetPlanoContaOrcamentario(values.CompetenciaId);
    }
  }, [values.CompetenciaId]);

  return (
    <Card border="primary">
      <Card.Header>Plano de Conta Orçamentário</Card.Header>
      <Card.Body>
        {(planosContaOrcamentario && planosContaOrcamentario.length > 0 && (
          <Card>
            <CheckboxTree
              nodes={planosContaOrcamentario}
              checked={values.PlanoContaOrcamentario}
              expanded={expanded}
              onCheck={(v) => setFieldValue("PlanoContaOrcamentario", v)}
              onExpand={(expanded) => setExpanded(expanded)}
              iconsClass="fa5"
              icons={{
                check: <span className="rct-icon rct-icon-check" />,
                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                expandClose: (
                  <span className="rct-icon rct-icon-expand-close" />
                ),
                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                collapseAll: (
                  <span className="rct-icon rct-icon-collapse-all" />
                ),
                parentClose: null,
                parentOpen: null,
                leaf: null,
              }}
            />
          </Card>
        )) ||
          "Nenhum Plano de Conta Orçamentário, verifique a competência."}
      </Card.Body>
    </Card>
  );
}
