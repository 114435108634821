import React, {useState, useEffect} from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { Formik, getIn} from "formik";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Money, UserProfile } from "../../../helpers/helper";
import { toast } from "react-toastify";
import BaseCrud from "../../../crud/base.crud";
import MyNotice from "../../../partials/layout/MyNotice";

export const CardFormTetoPlanoConta = ({
    teto,
    permitirEdicao,
    atualizarEstado
}) => {
  const { userProfile } = useSelector(({ auth }) => ({
    userProfile: !!auth.user && auth.user.ultimoPerfil,
  }));
  const isAdministrator = UserProfile.isAdministrador(userProfile);

  const sanitizeForm = (values) => ({
      id: teto.id,
      valorDespesa: teto.valorDespesa
    });

    

    const [NovoValorDespesa, setNovoValorDespesa] = useState({
      CompetenciaId: "",
      dataCadastro: "",
      id: "",
      planoContaCodigo: "",
      planoContaNome: "",
      unidadeOrcamentariaId: "",
      valorDespesa: ""
    });


  function salvarTetoPlanoConta(v) {
    var novoTeto = {Id:teto.id, ValorDespesa: Money.toDecimal(v)};
    BaseCrud.post("UnidadeOrcamentaria/SalvarTetoOrcamentarioConta", novoTeto).then((r) => {
      atualizarEstado(toast.success("Novo teto definido com sucesso!!!"));     

    });
  }

  const validateForm = (teto) => {

    let errors = {};

    // garbagy collector :p
    if (
      errors &&
      Object.values(errors).filter((x) => Object.keys(x).length > 0).length <= 0
    ) {
      errors = {};
    }

    return errors;
  };

  return !teto ? (
    <>Nenhum registro disponível para definição de teto.</>
  ) : (
    <Formik
    enableReinitialize={false} 
    initialValues={teto}
    key={`teto-${teto.id}`}
    //validate={validateForm}
    onSubmit={(v) => {salvarTetoPlanoConta(NovoValorDespesa)}}
    >
      {({ values, errors, handleChange, handleSubmit,setFieldValue }) => (

        <Card key={values.id}>
          <Card.Body>
              <Row key={`teto-${values.id}`} className="mt-4">
                <Col sm={12} md={3}>
                  <Form.Label>Teto Despesa R$</Form.Label>
                  <NumberFormat
                    displayType="input"
                    type="text"
                    name="valorDespesa"
                    className={`form-control`}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={values.valorDespesa}
                    placeholder="R$"
                    onChange={(e) => {
                        setFieldValue("valorDespesa", e.target.value);
                        setNovoValorDespesa(e.target.value);
                    }}
                    disabled = {permitirEdicao}

                  />
                </Col>
              </Row>
          </Card.Body>
          <Card.Footer>
            <Button onClick={handleSubmit} variant="primary" disabled={permitirEdicao}>
              Salvar
            </Button>
          </Card.Footer>
        </Card>
      )}
    </Formik>
  );
};
