import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";

//services
import BaseCrud from "../../../crud/base.crud";
import ProjetoCrud from "../../../crud/projeto.crud";

//enums
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";

import ModalEstrutura from "./ModalEstrutura";

export default function ({ values, handleChange }) {
  const [competencias, setCompetencias] = useState([]);
  const [unidadesOrcamentaria, setUnidadesOrcamentaria] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCustoId, setCentroCustoId] = useState(null);
  const [projetos, setProjetos] = useState(null);
  const [
    unidadeOrcamentariaDescription,
    setUnidadeOrcamentariaDescription,
  ] = useState("--");
  const [centroCustoDescription, setCentroCustoDescription] = useState("--");

  const getCompetencias = async () => BaseCrud.get("Competencia?$filter=Situacao ne '4'");

  const getUnidadeOrcamentaria = async (competenciaId) =>
    BaseCrud.get(
      `UnidadeOrcamentaria?$filter=CompetenciaId Eq ${competenciaId} And Situacao Eq true`
    );

  const getCentrosCusto = async (competenciaId, unidadeOrcamentariaId) =>
    BaseCrud.get(
      `CentroCusto?competenciaId=${competenciaId}&$filter=UnidadeOrcamentariaId Eq ${unidadeOrcamentariaId} And Situacao Eq true`
    );

  const handleGetCompetencias = async () =>
    getCompetencias()
      .then((r) => setCompetencias(r.data.value))
      .catch((e) => { });

  const handleGetUnidadesOrcamentaria = async (competenciaId) => {
    if (!competenciaId) {
      setUnidadesOrcamentaria([]);
      setCentrosCusto([]);
      setCentroCustoId(null);
      setProjetos(null);
    } else {
      getUnidadeOrcamentaria(competenciaId)
        .then((uo) => setUnidadesOrcamentaria(uo.data.value))
        .catch((e) => { });
    }
  };

  const handleGetCentrosCusto = async (
    competenciaId,
    unidadeOrcamentariaId
  ) => {
    setUnidadeOrcamentariaDescription("--");
    setCentroCustoId(null);
    setProjetos(null);
    if (!unidadeOrcamentariaId || !competenciaId) {
      setCentrosCusto([]);
    } else {
      let unidadeOrcamentaria = unidadesOrcamentaria.filter(
        (x) => x.Id == unidadeOrcamentariaId
      );
      setUnidadeOrcamentariaDescription(
        unidadeOrcamentaria[0].Codigo + " - " + unidadeOrcamentaria[0].Nome
      );

      getCentrosCusto(competenciaId, unidadeOrcamentariaId)
        .then((uo) => setCentrosCusto(uo.data.value))
        .catch((e) => { });
    }
  };

  const handleGetEstruturaModal = (v) => {
    if (v.target.value) {
      let centroCustoId = v.target.value;
      getProjetos(centroCustoId).then((response) => {
        if (response.data.length > 0) {
          let centroCusto = centrosCusto.filter((x) => x.Id == centroCustoId);
          setCentroCustoDescription(
            centroCusto[0].Codigo + " - " + centroCusto[0].Nome
          );
          setCentroCustoId(v.target.value);
          setProjetos(response.data);
        } else {
          setCentroCustoDescription("--");
          setCentroCustoId(null);
          setProjetos(null);
        }
      });
    } else {
      setCentroCustoDescription("--");
      setCentroCustoId(null);
      setProjetos(null);
    }
  };

  const getProjetos = async (centroCustoId) => {
    return ProjetoCrud.GetByCentroCusto(centroCustoId);
  }

  useEffect(() => {
    handleGetCompetencias();

    if (!!values.CompetenciaId && !!values.UnidadeOrcamentariaId) {
      handleGetCentrosCusto(values.CompetenciaId, values.UnidadeOrcamentariaId);
    }

    if (!!values.CompetenciaId) {
      handleGetUnidadesOrcamentaria(values.CompetenciaId);
    }
  }, []);

  return (
    <Form>
      <Card border="primary">
        <Card.Header>Competência</Card.Header>
        <Card.Body>
          <Row>
            <Col md={3}>
              <Form.Label>Competência</Form.Label>
              <Form.Control
                as="select"
                name="CompetenciaId"
                value={values.CompetenciaId}
                onChange={(evt) => {
                  handleChange(evt);
                  handleGetUnidadesOrcamentaria(evt.target.value);
                }}
              >
                <option value="">Selecione</option>
                {competencias.length > 0 &&
                  competencias.map((competencia) => (
                    <option key={competencia.Id} value={competencia.Id}>
                      {competencia.Ano + " - " +
                        getValorTipoCompetencia(competencia.Tipo) + " - " +
                        getValorSituacaoCompetencia(competencia.Situacao)}
                    </option>
                  ))}
              </Form.Control>
              <Form.Label style={{color:'red'}}><b>Obs:</b> no cadastro serão exibidas apenas as competências com situação diferente de 'CONSOLIDADO'</Form.Label>
            </Col>
            <Col md={3}>
              <Form.Label>Unidade Orçamentária</Form.Label>
              <Form.Control
                as="select"
                name="UnidadeOrcamentariaId"
                value={values.UnidadeOrcamentariaId}
                onChange={(evt) => {
                  handleChange(evt);
                  handleGetCentrosCusto(values.CompetenciaId, evt.target.value);
                }}
              >
                <option value="">Selecione</option>
                {unidadesOrcamentaria.length > 0 &&
                  unidadesOrcamentaria.map((unidadeOrcamentaria) => (
                    <option
                      key={unidadeOrcamentaria.Id}
                      value={unidadeOrcamentaria.Id}
                    >
                      {unidadeOrcamentaria.Codigo} - {unidadeOrcamentaria.Nome}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            <Col md={3}>
              <Form.Label>Centro de Custo (Modelo A)</Form.Label>
              <Form.Control
                as="select"
                name="CentroCustoId"
                value={values.CentroCustoId}
                onChange={(evt) => {
                  handleChange(evt);
                  handleGetEstruturaModal(evt);
                }}
              >
                <option value="">Selecione</option>
                {centrosCusto.length > 0 &&
                  centrosCusto.map((centroCusto) => (
                    <option key={centroCusto.Id} value={centroCusto.Id}>
                      {centroCusto.Codigo} - {centroCusto.Nome}
                    </option>
                  ))}
              </Form.Control>
            </Col>
            {projetos && (
              <Col md={3}>
                <Form.Label>Ver Estrutura</Form.Label>
                <br></br>
                <ModalEstrutura
                  projetos={projetos}
                  centroCustoId={centroCustoId}
                  unidadeOrcamentariaDescription={
                    unidadeOrcamentariaDescription
                  }
                  centroCustoDescription={centroCustoDescription}
                  competenciaId={values.CompetenciaId}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
}
