import React from "react";
import { toast } from "react-toastify";

import FilterUnidadeOrcamentaria from "./FilterUnidadeOrcamentaria";
import GridUnidadeOrcamentaria from "./GridUnidadeOrcamentaria";
import BaseCrud from "../../../crud/base.crud";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

import MyNotice from "../../../partials/layout/MyNotice";

export default class IndexUnidadeOrcamentaria extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unidadesOrcamentaria: [],
      //Filtro Paginação
      filter: "",
      page: 0,
      totalItems: 0,
      competenciaId: null,
    };

    this.getUnidadesOrcamentaria = this.getUnidadesOrcamentaria.bind(this);

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {

      
      this.setState({ competenciaId: response.data.value.filter((x) => x.Vigente == true)[0].Id  }, () => {
      // this.setState({ competenciaId: response.data.value[0].Id }, () => {
        this.getUnidadesOrcamentaria();
      });
    });
  }

  handleFilter(v) {
    let filter = "";
    //Filtros necessários para a página
    filter += !!v.Nome
      ? `contains(tolower(Nome), tolower('${v.Nome}')) and `
      : "";
    // filter += !!v.CompetenciaId
    //   ? `CompetenciaId eq ${v.CompetenciaId} and `
    //   : "";
    filter += !!v.Codigo ? `Codigo eq '${v.Codigo}' and ` : "";
    filter += !!v.Situacao ? `Situacao eq ${v.Situacao} and ` : "";
    filter = filter.slice(0, -4);

    if (v.CompetenciaId) {    
      
      this.setState({ competenciaId: v.CompetenciaId });
    }

    this.setState({ filter: filter });
    this.getUnidadesOrcamentaria();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `${
      !!this.state.filter ? " and " + this.state.filter : ""
    }&$top=${PER_PAGE}&$skip=${skip}`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getUnidadesOrcamentaria();
    });
  }

  getUnidadesOrcamentaria() {

    if (this.state.competenciaId) {
      BaseCrud.get(
        `UnidadeOrcamentaria?$count=true&$filter=CompetenciaId eq ${
          this.state.competenciaId
        }${this.getEndpointComplement()}`
      )
        .then((response) => {
          this.setState({
            unidadesOrcamentaria: response.data.value,
            totalItems: response.data["@odata.count"],
          });
        })
        .catch((e) => {});
    }
  }

  render() {
    return (
      <>
        <MyNotice title="Cadastro de Unidade Orçamentária" />

        <FilterUnidadeOrcamentaria
          handleFilter={this.handleFilter}
          filter={this.state.filter}
        />
        <GridUnidadeOrcamentaria
          unidadesOrcamentaria={this.state.unidadesOrcamentaria}
        />

        <DefaultPagination
          items={this.state.unidadesOrcamentaria}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
