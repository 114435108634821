import React, { useState, useEffect } from "react";

import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletFooter,
} from "../../../partials/content/Portlet";

import MyNotice from "../../../partials/layout/MyNotice";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Formik, ErrorMessage, getIn } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";

//Enum
import { SITUACOES } from "../../../enums/genericEnum";
import { getValorSituacaoCompetencia, getValorTipoCompetencia } from "../../../enums/competenciaEnum";
import { ACEITA_VALORES } from "../../../enums/estruturaOrcamentariaEnum";

//services
import EstruturaOrcamentariaCrud from "../../../crud/estruturaOrcamentaria.crud";
import BaseCrud from "../../../crud/base.crud";

const schema = Yup.object().shape({
  CompetenciaId: Yup.string()
    .matches(
      /^([0-9A-Fa-f]{8}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{4}[-][0-9A-Fa-f]{12})$/i,
      "Selecione a competencia"
    )
    .required("Campo obrigatório"),
  Codigo: Yup.string()
    .matches(/^[0-9.]*$/i, "Código inválido, utilize apenas números e ponto.")
    .required("Campo obrigatório"),
  Titulo: Yup.string().required("Campo obrigatório"),
  Situacao: Yup.bool()
    .typeError("Selecione a situação.")
    .required("Campo obrigatório"),
  AceitaValor: Yup.bool()
    .typeError("Selecione se esta estrutura aceita valores.")
    .required("Campo obrigatório"),
});

export default function FormEstruturaOrcamentaria(props) {
  const [estruturaOrcamentaria, setEstruturaOrcamentaria] = useState({
    Id: "",
    Titulo: "",
    Codigo: "",
    Situacao: true,
    AceitaValor: "",
    CompetenciaId: "",
    Nivel: "",
  });

  // Estados necessários para preenchimento do formulário
  const [competencias, setCompetencias] = useState([]);

  const [filterCompetencia, setFilterCompetencia] = useState("");

  // Chamadas Iniciais da pagina
  useEffect(() => {
    // Busca as competencias
    BaseCrud.get(`competencia?$orderBy=Ano desc${!props.match.params.id ? "&$filter=Situacao ne '4'" : ""}`, true).then(
      (response) => {
        setCompetencias(response.data.value);
        estruturaOrcamentaria.CompetenciaId = response.data.value[0].Id;
      }
    );

    //Busca o Plano De Conta em caso de edição
    if (props.match.params.id) {
      EstruturaOrcamentariaCrud.getById(
        "EstruturaOrcamentaria",
        props.match.params.id,
        true
      ).then((response) => {
        let resolveData = {
          Titulo: response.data.Titulo,
          Codigo: response.data.Codigo,
          Situacao: response.data.Situacao,
          AceitaValor: response.data.AceitaValor,
          CompetenciaId: response.data.CompetenciaId,
          Nivel: response.data.Nivel,
          Id: response.data.Id,
          ClassificacaoOrcamentaria: response.data.ClassificacaoOrcamentaria,
          ClassificacaoOrcamentariaId: response.data.ClassificacaoOrcamentariaId,
        };

        setEstruturaOrcamentaria(resolveData);
      });
    }
  }, []);

  const handleSubmit = function (v) {
    (!!v.Id
      ? editar(v)
      : incluir({
        Titulo: v.Titulo,
        Codigo: v.Codigo,
        Situacao: v.Situacao,
        AceitaValor: v.AceitaValor,
        CompetenciaId: v.CompetenciaId,
        Nivel: v.Nivel,
      })
    )
      .then((r) => {
        toast.success("Operação realizada com sucesso");
        props.history.push("/orcamento/estrutura-orcamentaria");
      })
      .catch((e) => e);
  };

  const editar = async (v) =>
    EstruturaOrcamentariaCrud.put("EstruturaOrcamentaria", v.Id, v);

  const incluir = async (v) =>
    EstruturaOrcamentariaCrud.post("EstruturaOrcamentaria", v);

  return (
    <>
      <MyNotice title="Cadastro da Estrutura Orçamentária" />
      <Row>
        <Col md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={estruturaOrcamentaria}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, handleChange, handleSubmit }) => (
              <Portlet fluidHeight={true}>
                <PortletHeader
                  title={
                    !!values.Id
                      ? "Edição da Estrutura Orçamentária"
                      : "Cadastro da Estrutura Orçamentária"
                  }
                />
                <PortletBody>
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <Form onSubmit={(e) => e.preventDefault()}>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={4}>
                            <Form.Label>Competência</Form.Label>
                            <Form.Control
                              as="select"
                              name="CompetenciaId"
                              value={values.CompetenciaId}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "CompetenciaId") &&
                                getIn(touched, "CompetenciaId")
                              }
                              disabled={!!props.match.params.id}
                            >
                              {competencias.map((competencia) => (
                                <option
                                  key={competencia.Id}
                                  value={competencia.Id}
                                >
                                  {competencia.Ano + " - " +
                                    getValorTipoCompetencia(competencia.Tipo) + " - " +
                                    getValorSituacaoCompetencia(competencia.Situacao)}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="CompetenciaId" />
                            <Form.Label style={{color:'red'}}><b>Obs:</b> no cadastro serão exibidas apenas as competências com situação diferente de 'CONSOLIDADO'</Form.Label>
                          </Col>
                          <Col md={4}>
                            <Form.Label>Código</Form.Label>
                            <Form.Control
                              type="text"
                              name="Codigo"
                              placeholder="Digite o código da conta"
                              value={values.Codigo}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Codigo") &&
                                getIn(touched, "Codigo")
                              }
                            />
                            <ErrorMessage name="Codigo" />
                          </Col>
                          <Col md={4}>
                            <Form.Label>Titulo</Form.Label>
                            <Form.Control
                              type="text"
                              name="Titulo"
                              placeholder="Titulo"
                              value={values.Titulo}
                              onChange={handleChange}
                              isInvalid={
                                getIn(errors, "Titulo") && getIn(touched, "Titulo")
                              }
                            />
                            <ErrorMessage name="Titulo" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <Form.Label>Aceita Valor</Form.Label>
                            <Form.Control
                              as="select"
                              name="AceitaValor"
                              onChange={handleChange}
                              value={values.AceitaValor}
                              isInvalid={
                                getIn(errors, "AceitaValor") &&
                                getIn(touched, "AceitaValor")
                              }
                            >
                              <option value="">Selecione</option>
                              {ACEITA_VALORES.map((v, i) => (
                                <option key={i} value={v.id}>
                                  {v.valor}
                                </option>
                              ))}
                            </Form.Control>
                            <ErrorMessage name="AceitaValor" />
                          </Col>

                          {!!values.Id && (
                            <Col md={4}>
                              <Form.Label>Situação</Form.Label>
                              <Form.Control
                                as="select"
                                name="Situacao"
                                onChange={handleChange}
                                value={values.Situacao}
                                isInvalid={
                                  getIn(errors, "Situacao") &&
                                  getIn(touched, "Situacao")
                                }
                              >
                                {SITUACOES.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.valor}
                                  </option>
                                ))}
                              </Form.Control>
                              <ErrorMessage name="situacao" />
                            </Col>
                          )}
                        </Row>
                      </Form>
                    </div>
                  </div>
                </PortletBody>
                <PortletFooter style={{ textAlign: "right" }}>
                  <Link
                    to="/orcamento/estrutura-orcamentaria"
                    variant="primary"
                    className="btn btn-secondary"
                  >
                    Voltar
                  </Link>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </PortletFooter>
              </Portlet>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
