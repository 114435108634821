import React from "react";
import { toast } from "react-toastify";

import BaseCrud from "../../../crud/base.crud";
import FilterProjeto from "./FilterProjeto";
import GridProjeto from "./GridProjeto";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

export default class IndexProjeto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projetos: [],
      filter: "",
      page: 0,
      totalItems: 0,
    };

    this.getProjetos = this.getProjetos.bind(this);

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    this.getProjetos();
  }

  handleFilter(v) {
    let filter = "";

    //Filtros necessários para a página
    filter += !!v.Nome
      ? `contains(tolower(Nome), tolower('${v.Nome}')) and `
      : "";
    filter += !!v.Situacao ? `Situacao eq ${v.Situacao} and ` : "";
    filter += !!v.Tipo ? `Tipo eq '${v.Tipo}' and ` : "";
    filter = filter.slice(0, -4);

    this.setState({ filter: filter });
    this.getProjetos();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `$top=${PER_PAGE}&$skip=${skip}&${
      !!this.state.filter ? "&$filter=" + this.state.filter : ""
    }&$orderBy=Nome`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getProjetos();
    });
  }

  getProjetos() {
    BaseCrud.get(`Projeto?$count=true&${this.getEndpointComplement()}`, true)
      .then((response) => {
        this.setState({
          projetos: response.data.value,
          totalItems: response.data["@odata.count"],
        });
      })
      .catch((e) => {});
  }

  render() {
    return (
      <>
        <FilterProjeto
          handleFilter={this.handleFilter}
          filter={this.state.filter}
        />
        <GridProjeto projetos={this.state.projetos} />
        <DefaultPagination
          items={this.state.projetos}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
