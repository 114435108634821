import React from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import Select from "react-select";

export default function({ values, projetos, setFieldValue }) {
  return (
    <Card border="primary">
      <Card.Header>Projetos</Card.Header>
      <Card.Body>
        <Row style={{ marginBottom: 10 }}>
          <Col md={12}>
            <Form.Label>Selecione o Projeto</Form.Label>
            <Select
              options={projetos}
              value={values.Projeto}
              isSearchable
              name="Projeto"
              placeholder="Pesquisar..."
              onChange={(v) => setFieldValue("Projeto", v)}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
