import React from "react";
import { toast } from "react-toastify";

import BaseCrud from "../../../crud/base.crud";
import FilterCentroCusto from "./FilterCentroCusto";
import Grid from "./GridCentroCusto";
import DefaultPagination, {
  PER_PAGE,
} from "../../../partials/layout/DefaultPagination";

export default class IndexCentroGrupo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      centroCustos: [],
      //Filtro Paginação
      filter: "",
      page: 0,
      totalItems: 0,
      competenciaId: null,
    };

    this.getCentroCustos = this.getCentroCustos.bind(this);

    //Filtro Paginação
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePaging = this.handlePaging.bind(this);
    this.getEndpointComplement = this.getEndpointComplement.bind(this);
  }

  componentDidMount() {
    BaseCrud.get("competencia?$orderBy=Ano desc").then((response) => {       
      this.setState({ competenciaId: response.data.value.filter((x) => x.Vigente == true)[0].Id }, () => {
        this.getCentroCustos();
      });
    });
  }

  handleFilter(v) {
    let filter = "&$filter=";

    //Filtros necessários para a página
    filter += !!v.Nome
      ? `contains(tolower(Nome), tolower('${v.Nome}')) and `
      : "";
    filter += !!v.UnidadeOrcamentariaId
      ? `UnidadeOrcamentariaId eq ${v.UnidadeOrcamentariaId} and `
      : "";
    filter += !!v.Codigo ? `Codigo eq '${v.Codigo}' and ` : "";
    filter += !!v.Situacao ? `Situacao eq ${v.Situacao} and ` : "";

    if (filter != "&$filter=" && v.CompetenciaId) {
      filter = filter.slice(0, -4) + `&CompetenciaId=${v.CompetenciaId}`;
    } else {
      filter = `&CompetenciaId=${v.CompetenciaId}`;
    }

    this.setState({ filter: filter });
    this.getCentroCustos();
  }

  getEndpointComplement() {
    let skip = this.state.page - 1 < 0 ? 0 : (this.state.page - 1) * PER_PAGE;

    return `$top=${PER_PAGE}&$skip=${skip}${
      !!this.state.filter
        ? this.state.filter
        : `&CompetenciaId=${this.state.competenciaId}`
    }`;
  }

  handlePaging(page) {
    this.setState({ page: page }, () => {
      this.getCentroCustos();
    });
  }

  getCentroCustos() {
    if (this.state.competenciaId) {
      BaseCrud.get(`CentroCusto?$count=true&${this.getEndpointComplement()}`)
        .then((response) => {
          this.setState({
            centroCustos: response.data.value,
            totalItems: response.data["@odata.count"],
          });
        })
        .catch((e) => {});
    }
  }

  render() {
    return (
      <>
        <FilterCentroCusto
          handleFilter={this.handleFilter}
          competenciaId={this.state.competenciaId}
          filter={this.state.filter}
        />

        <Grid centrocustos={this.state.centroCustos} />

        <DefaultPagination
          items={this.state.centroCustos}
          totalItems={this.state.totalItems}
          initialPage={1}
          pageSize={PER_PAGE}
          onChangePage={this.handlePaging}
        />
      </>
    );
  }
}
